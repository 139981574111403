import React from 'react'; 
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { useTour } from './TourContext';

interface SpotlightTourProps {
  steps: {
    target: string;
    content: string;
  }[];
  view: string;
}

const SpotlightTour: React.FC<SpotlightTourProps> = ({ steps, view }) => {
  const { tourActive, setTourActive, currentView, currentSubView } = useTour();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setTourActive(false);
    }
  };

  // Determina si debe mostrar el tour basado en la vista actual y subvista
  const shouldShowTour = tourActive && (
    (currentView === view && !currentSubView) || // Para vista principal
    (currentSubView === view) // Para subvistas
  );

  return (
    <Joyride
      steps={steps}
      run={shouldShowTour}
      callback={handleJoyrideCallback}
      continuous
      showSkipButton
      showProgress
      spotlightClicks
      disableOverlayClose
      locale={{
        back: 'Atrás',
        close: 'Cerrar',
        last: 'Finalizar',
        next: 'Siguiente',
        skip: 'Saltar',
      }}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          overlayColor: 'rgba(0, 0, 0, 0.7)',
          primaryColor: '#007bff',
          textColor: '#333',
          width: 400,
          zIndex: 1000,
        },
        tooltipContainer: {
          textAlign: 'left',
        },
        buttonNext: {
          backgroundColor: '#007bff',
        },
        buttonBack: {
          marginRight: 10,
        },
      }}
    />
  );
};

export default SpotlightTour;