import React, { useEffect, useRef, useState } from 'react'
import { Layout, Table, TableColumnType, InputRef, Space, Button, Input } from 'antd'
import { RootState } from '../../redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store/store'
import { fetchPlantillasMake } from '../../redux/actions/plantillas_make/PlantillasMake'
import {
  SearchOutlined
} from '@ant-design/icons';
import '../../styles/pages/PlantillasMake/PlantillasMake.css'

interface DataType {
  key: any;
  descripcion: string;
  descripcion_en: string;
  dificultad: string;
  instrucciones_uso: string;
  integraciones: string;
  situaciones: string;
  updatedAt: string;
  url: string;
  usos: string;
}

type DataIndex = keyof DataType;

const PlantillasMake = () => {

  const { Header, Footer, Sider, Content } = Layout
  const dispatch: AppDispatch = useDispatch()

  const [page, setPage] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string>('id')
  const [orderColum, setOrderColum] = useState<string>('asc')
  const [filtersTable, setFiltersTable] = useState<any>({})
  const searchInput = useRef<InputRef>(null)

  const { rex_plantillas_make, rex_meta, rex_loading } = useSelector(({ plantillasMake }: RootState) => plantillasMake);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any
  ) => {

    if (sorter.order) {
      const order = sorter.order === 'ascend' ? 'asc' : 'desc';
      const columnMapping: { [key: string]: string } = {
        descripcion: 'descripcion',
        descripcion_en: 'descripcion_en',
        dificultad: 'dificultad',
        instrucciones_uso: 'instrucciones_uso',
        integraciones: 'integraciones',
        situaciones: 'situaciones',
        url: 'url',
        usos: 'usos',
      };
      const sortColumn = columnMapping[sorter.field] || 'id';

      setSortColumn(sortColumn)
      setOrderColum(order)

      dispatch(fetchPlantillasMake(filtersTable, page, sortColumn, order))
    } else {

      setSortColumn('id')
      setOrderColum('asc')
      dispatch(fetchPlantillasMake(filtersTable, page, 'id', 'asc'))
    }
  }

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    titleColumn?: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          className='input-search'
          ref={searchInput}
          placeholder={`Buscar ${titleColumn || dataIndex}`}
          value={filtersTable[dataIndex] || ''}
          onChange={(e) =>
            setFiltersTable({ ...filtersTable, [dataIndex]: e.target.value })
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined onClick={()=>console.log(dataIndex)} style={{ color: filtersTable[dataIndex]?.length > 0 ? '#1677ff' : undefined }} />
    ),
  })

  useEffect(() => {
    if (filtersTable) {
      const timer = setTimeout(() => {
        dispatch(fetchPlantillasMake(filtersTable, page, sortColumn, orderColum))
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [filtersTable]);


  const columns = [
    {
      title: "Descripcion EN",
      key: "descripcion_en",
      dataIndex: "descripcion_en",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('descripcion_en','Descripcion EN'),
    },
    {
      title: "URL",
      key: "url",
      dataIndex: "url",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('url','URL'),
    },
    {
      title: "DESCRIPCION",
      key: "descripcion",
      dataIndex: "descripcion",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('descripcion','Descripcion'),
    },
    {
      title: "DIFICULTAD",
      key: "dificultad",
      dataIndex: "dificultad",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('dificultad','Dificultad'),
      width:170,
      render: (text: string) => (<div style={{textAlign:'center'}}>{text}</div>)
    },
    {
      title: "SITUACIONES",
      key: "situaciones",
      dataIndex: "situaciones",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('situaciones','Situaciones'),
    },
    {
      title: "INTEGRACIONES",
      key: "integraciones",
      dataIndex: "integraciones",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('integraciones', 'Integraciones'),
    },
    {
      title: "INSTRUCCIONES DE USO",
      key: "instrucciones_uso",
      dataIndex: "instrucciones_uso",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('instrucciones_uso', 'Instrucciones de uso'),
    },
    {
      title: "USOS",
      key: "usos",
      dataIndex: "usos",
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
      ...getColumnSearchProps('usos', 'Usos'),
      width:120,
      render: (text: string) => (<div style={{textAlign:'center'}}>{text}</div>)
    },
  ]

  const onPageChange = (data: any) => {
    setPage(data)
    getData(data)
  }

  const getData = (page: number) => {
    dispatch(fetchPlantillasMake(filtersTable, page, sortColumn, orderColum))
  }

  useEffect(() => {
    getData(page)
  }, [])

  return (
    <Layout style={{ height: "100vh" }}>
      <Header>Header</Header>
      <Layout>
        <Sider width="10%">
          Sider
        </Sider>
        <Content>
          <Table
            loading={rex_loading}
            onChange={handleTableChange}
            pagination={{
              current: page,
              pageSize: 10,
              total: rex_meta.total,
              onChange: onPageChange,
              showSizeChanger: false,
              showPrevNextJumpers: false,
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} elementos`,
            }}
            columns={columns}
            dataSource={rex_plantillas_make}
            scroll={{ x: '100%' }}

          >

          </Table>
        </Content>
      </Layout>
      <Footer>Footer</Footer>
    </Layout>
  )
}

export default PlantillasMake