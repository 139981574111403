import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Skeleton, Button, Space, Input, message } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import CustomChatbotCard from '../../../components/pages/ourChatbots/CustomChatbotCard';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { DuplicateChatbotReducer, GetMarketChatbotsReducer } from '../../../redux/actions/chatBots/OurChatbots/OurChatbots';

interface ChatbotData {
  id: number;
  nombre: string;
  descripcion: string;
  logo: string;
  comportamiento?: string;
  bloqueado: boolean;
}

const OurChatBots: React.FC = () => {
  const { Header, Content } = Layout;
  const dispatch: AppDispatch = useDispatch();
  const { rex_market_chatbots, rex_loading, rex_pagination } = useSelector(
    ({ ourChatBots }: RootState) => ourChatBots
  );

  const [chatbotsData, setChatbotsData] = useState<ChatbotData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedChatbot, setSelectedChatbot] = useState<ChatbotData | null>(null);
  const [searchValue, setSearchValue] = useState('');


  useEffect(() => {
    dispatch(GetMarketChatbotsReducer({ page: 1, limit: 10 }));
  }, []);

  useEffect(() => {
    if (rex_market_chatbots) {
      if (currentPage === 1) {
        setChatbotsData(rex_market_chatbots);
      } else {
        setChatbotsData(prevData => {
          const newData = rex_market_chatbots.filter(
            (item: ChatbotData) => !prevData.some(prevItem => prevItem.id === item.id)
          );
          return [...prevData, ...newData];
        });
      }
    }
  }, [rex_market_chatbots]);

  const fetchMoreData = () => {
    if (rex_pagination && currentPage < Math.ceil(rex_pagination.total / rex_pagination.limit)) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      dispatch(GetMarketChatbotsReducer({
        page: nextPage,
        limit: 10,
        nombre: searchValue,
        descripcion: searchValue
      }));
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setChatbotsData([]);
    dispatch(GetMarketChatbotsReducer({
      page: 1,
      limit: 10,
      nombre: searchValue,
      descripcion: searchValue
    }));
  };

  const handleCardClick = (chatbot: ChatbotData) => {
    setSelectedChatbot(chatbot);
    setModalVisible(true);
  };

  const handleDuplicate = async (marketId: number) => {
    try {
      await dispatch(DuplicateChatbotReducer(marketId));
      message.success('Chatbot duplicado exitosamente');
      setCurrentPage(1);
      setChatbotsData([]);
      dispatch(GetMarketChatbotsReducer({
        page: 1,
        limit: 10,
        nombre: searchValue,
        descripcion: searchValue
      }));

    } catch (error) {
      message.error('Error al duplicar el chatbot');
    }
  };

  return (
    <Content style={{ overflowX: 'hidden' }}>
      <div style={{ margin: '0 auto' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px'
        }}>
          <span>Lista de chatbots:</span>
          <Space>
            <Input
              placeholder="Buscar por nombre o descripción"
              value={searchValue}
              onChange={(e) => {
                const value = e.target.value;
                setSearchValue(value);
                if (!value) {
                  setCurrentPage(1);
                  setChatbotsData([]);
                  dispatch(GetMarketChatbotsReducer({ page: 1, limit: 10 }));
                }
              }}
              onPressEnter={handleSearch}
              style={{ width: 200 }}
              allowClear
            />
            <Button
              type="primary"
              onClick={handleSearch}
              style={{
                background: '#1a81d7',
                borderColor: '#1a81d7'
              }}
            >
              Buscar
            </Button>
          </Space>
        </div>
        {rex_loading && chatbotsData.length === 0 ? (
          <Row gutter={[24, 24]} justify="center">
            {Array.from({ length: 8 }).map((_, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Skeleton active style={{ width: '100%' }} />
              </Col>
            ))}
          </Row>
        ) : (
          <InfiniteScroll
            dataLength={chatbotsData.length}
            next={fetchMoreData}
            hasMore={!!rex_pagination && currentPage < Math.ceil(rex_pagination.total / rex_pagination.limit)}
            loader={
              <Row gutter={[24, 24]} justify="center">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <Skeleton active style={{ width: '100%' }} />
                  </Col>
                ))}
              </Row>
            }
          >
            <Row gutter={[24, 24]} justify="center">
              {chatbotsData.map((chatbot, index) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  <CustomChatbotCard
                    nombre={chatbot.nombre}
                    descripcion={chatbot.descripcion}
                    logo={chatbot.logo}
                    comportamiento={chatbot.comportamiento}
                    bloqueado={chatbot.bloqueado}
                    onClick={() => handleCardClick(chatbot)}
                    onDuplicate={(e) => {
                      e.stopPropagation();
                      handleDuplicate(chatbot.id);
                    }}
                  />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        )}
      </div>
    </Content>

  );
};

export default OurChatBots;