import React from 'react';
import { Card, Row, Typography, Space, Divider, Button, Col } from 'antd';
import AvatarRobot from '../../../assets/img/avatars/robot.avif';
import '../../../styles/pages/PlantillasMake/Cards.css';

const { Text, Title } = Typography;

interface ChatbotCardProps {
  nombre: string;
  descripcion: string;
  logo: string;
  comportamiento?: string;
  bloqueado: boolean;
  onClick: () => void;
  onDuplicate: (e: React.MouseEvent) => void;
}

const CustomChatbotCard: React.FC<ChatbotCardProps> = ({
  nombre,
  descripcion,
  logo,
  comportamiento,
  bloqueado,
  onDuplicate,
  onClick
}) => {
  const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  return (
    <Card
      hoverable
      className="custom-card-chatbot"
      style={{
        borderRadius: '12px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '400px'
      }}
      onClick={onClick}
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Row justify="center" style={{ marginBottom: '16px' }}>
          <div
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '2px solid #f0f0f0',
              background: logo === "http://url" ? '#ccc' : '#E6F4FF',
            }}
          >
            <img
              src={logo === "http://url" ? AvatarRobot : logo}
              alt={nombre}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </Row>

        <Space direction="vertical" className="card-content" style={{ flex: 1 }}>
          <Title level={5} className="card-title">
            {nombre}
          </Title>

          <div className="description-container" style={{ flex: 1 }}>
            <Text strong>Descripción:</Text>
            <p className="description-text" style={{
              filter: bloqueado ? 'blur(4px)' : 'none',
              userSelect: bloqueado ? 'none' : 'auto'
            }}>
              {bloqueado ? loremIpsum : descripcion}
            </p>
          </div>
          <div className="button-section">
            <Button
              type="primary"
              className="chat-button"
              disabled={bloqueado}
              style={{
                marginTop: 'auto',
                backgroundColor: bloqueado ? '#BAD7FF' : '#1677ff',
                color: bloqueado ? '#ffffff' : '#ffffff',
                borderColor: bloqueado ? '#BAD7FF' : '#1677ff',
                marginBottom: '24px'
              }}
            >
              💬 Conversar con el chat
            </Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation(); // Evitar que se propague al Card
                onDuplicate(e);
              }}
              style={{
                backgroundColor: '#52c41a',
                borderColor: '#52c41a',
                width: '100%'
              }}
            >
              🔄 Comprar
            </Button>
          </div>
        </Space>
      </div>

      <div className="footer-section">
        <Divider style={{ margin: '12px 0' }} />
        <Row justify="space-between" align="middle" className="status-row">
          <Text style={{ fontWeight: 'bold', color: '#888' }}>
            Estado: {bloqueado ? 'Bloq.' : 'Activo'}
          </Text>
          <Text style={{ fontWeight: 'bold', color: bloqueado ? '#ff4d4f' : '#52c41a' }}>
            {bloqueado ? '🔒 Bloqueado' : '🟢 Disponible'}
          </Text>
        </Row>
      </div>
    </Card>
  );
};

export default CustomChatbotCard;