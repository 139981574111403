import React, { useState } from 'react';
import { Modal, Button, Space, message } from 'antd';
import { SyncOutlined, DeleteOutlined, CopyOutlined, GlobalOutlined, ContainerOutlined, PlusOutlined } from '@ant-design/icons';
import IconClosed from "../../../../assets/IconClosed.svg";

interface SubUrl {
    id: number;
    sub_url: string;
    leyo: boolean;
    contenido: string;
    updated_at: string;
}

interface ModalSubUrlsProps {
    visible: boolean;
    onClose: () => void;
    title?: string;
    subUrls: SubUrl[];
    onUpdateSubUrl?: (subUrl: SubUrl) => void;
    onDeleteSubUrl?: (subUrl: SubUrl) => void;
    onShowContent?: (subUrl: SubUrl) => void;
    onAddSubUrl?: () => void;
    origenLectura?: string; 
}

const ModalSubUrls: React.FC<ModalSubUrlsProps> = ({
    visible,
    onClose,
    title = "Sub URLs",
    subUrls = [],
    onUpdateSubUrl,
    onDeleteSubUrl,
    onShowContent,
    onAddSubUrl,
    origenLectura
}) => {
    const handleCopyUrl = (url: string) => {
        navigator.clipboard.writeText(url).then(() => {
            message.success("URL copiada al portapapeles!");
        }).catch(() => {
            message.error("Error al copiar la URL.");
        });
    };

    const handleOpenUrl = (url: string) => {
        window.open(url, '_blank');
    };

    const formatDate = (date: string) => {
        const d = new Date(date);
        return d.toLocaleDateString('es-PE');
    };

    return (
        <>
            <Modal
                open={visible}
                onCancel={onClose}
                footer={null}
                closable={false}
                width="70%"
                bodyStyle={{ borderRadius: "12px" }}
            >
                <div style={{
                    position: "absolute",
                    top: "34px",
                    right: "34px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center"
                }}>
                    <PlusOutlined
                        onClick={onAddSubUrl}
                        style={{
                            cursor: "pointer",
                            fontSize: "24px",
                            color: "#1890ff"
                        }}
                    />
                    <img
                        src={IconClosed}
                        alt="Cerrar"
                        onClick={onClose}
                        style={{
                            cursor: "pointer",
                            width: "28px",
                            height: "28px",
                        }}
                    />
                </div>
                <div className="mx-[50px] my-[35px]">
                    <h3 style={{ fontSize: '24px', marginBottom: '20px', alignItems: 'center' }}>{title}</h3>
                    <div style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
                        {subUrls.length > 0 ? (
                            subUrls.map((subUrl) => (
                                <Button
                                    key={subUrl.id}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexWrap: 'nowrap',
                                        whiteSpace: 'normal',
                                        lineHeight: '1.5',
                                        padding: '10px',
                                        gap: '8px',
                                        minHeight: 'fit-content',
                                        marginBottom: '24px'
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        flex: 1,
                                        minWidth: 0
                                    }}>
                                        <span style={{
                                            color: subUrl.leyo ? 'green' : 'red',
                                            fontSize: '24px',
                                            flexShrink: 0
                                        }}>●</span>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: 0
                                        }}>
                                            <div style={{
                                                fontSize: '16px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '100%'
                                            }}>
                                                {subUrl.sub_url}
                                            </div>
                                            <div style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                textAlign: 'left'
                                            }}>
                                                {formatDate(subUrl.updated_at)}
                                            </div>
                                        </div>
                                    </div>
                                    <Space style={{ flexShrink: 0 }}>
                                        <SyncOutlined
                                            style={{
                                                color: 'green',
                                                fontSize: '16px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onUpdateSubUrl?.(subUrl);
                                            }}
                                        />
                                        <DeleteOutlined
                                            style={{
                                                color: 'red',
                                                fontSize: '16px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteSubUrl?.(subUrl);
                                            }}
                                        />
                                        <CopyOutlined
                                            style={{ color: 'gray', fontSize: '16px', cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopyUrl(subUrl.sub_url);
                                            }}
                                        />
                                        <GlobalOutlined
                                            style={{
                                                color: '#1890ff',
                                                fontSize: '16px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenUrl(subUrl.sub_url);
                                            }}
                                        />
                                        <ContainerOutlined
                                            style={{
                                                color: '#ffa500',
                                                fontSize: '16px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onShowContent?.(subUrl);
                                            }}
                                        />
                                    </Space>
                                </Button>
                            ))
                        ) : (
                            <div style={{ textAlign: 'center', color: '#666' }}>
                                No hay sub URLs disponibles
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ModalSubUrls;