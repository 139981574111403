import {
  PLANTILLAS_MAKE_REQUEST,
  PLANTILLAS_MAKE_SUCCESS,
  PLANTILLAS_MAKE_FAILURE,
} from "../../../constantes/plantillas_make/PlantillasMake";

interface MetaData {
  limit: number;
  page: number;
  total: number;
  mensaje_dev?: {
    dificultades: string[];
    integraciones: string[];
  };
}

interface PlantillasMakeState {
  rex_plantillas_make: any[];
  rex_loading: boolean;
  rex_error: string | null;
  rex_meta: MetaData;
  rex_dificultades: string[]; // Nueva propiedad para las dificultades
  rex_integraciones: string[]; // Nueva propiedad para las integraciones
}

const initialState: PlantillasMakeState = {
  rex_plantillas_make: [],
  rex_loading: false,
  rex_error: null,
  rex_meta: {
    limit: 0,   // Inicializa con un valor predeterminado
    page: 1,    // Inicializa con un valor predeterminado
    total: 0,   // Inicializa con un valor predeterminado
  },
  rex_dificultades: [], // Inicializamos como un array vacío
  rex_integraciones: [], // Inicializamos como un array vacío
};

const PlantillasMake = (state = initialState, action: any): PlantillasMakeState => {
  switch (action.type) {
    case PLANTILLAS_MAKE_REQUEST:
      return {
        ...state,
        rex_loading: true,
      };
    case PLANTILLAS_MAKE_SUCCESS:
      console.log('Dificultades en el reducer:', action.payload.dificultades); // Verifica las dificultades al llegar al reducer
      console.log('Integraciones en el reducer:', action.payload.integraciones); // Verifica las integraciones

      return {
        ...state,
        rex_loading: false,
        rex_plantillas_make: action.payload.data,
        rex_meta: action.payload.meta,
        rex_dificultades: action.payload.dificultades, // Almacenamos las dificultades en el estado
        rex_integraciones: action.payload.integraciones, // Almacenamos las integraciones en el estado
      };
    case PLANTILLAS_MAKE_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.error,
      };
    default:
      return state;
  }
};

export default PlantillasMake;
