import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  GET_CONVERSATION_TAB_CHAT,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  SET_CHATBOTS_FILTERS,
  SET_CHATBOTS_SORT, 
  GET_NUESTROS_CHATBOTS_REQUEST,
  SET_CHATBOTS_SEARCH,
  GET_NUESTROS_CHATBOTS_SUCCESS,
  GET_NUESTROS_CHATBOTS_FAILURE,
  GET_CONVERSATION_CHAT_SUPPORT,
} from '../../../../constantes/chatBots/chat/Chat';
import fetchWithIP from '../../utils/fetchHeaders';
import { BOT_SELECTED, GET_DATA_CHATSBOTS_HOME, SET_TOKEN_CHAT_BY_USER } from '../../../../constantes/Home/Home';
import { selectChatSeleccionado, selectConversacionSeleccionada, selectTokenChatSeleccionado } from '../../../reducers/selectors/selectors';
import { GetChatSeccionadoHome, SetConversacionSeleccionadaHome } from '../../home/Home';

export const SUBMIT_FORM_DATA_ENTRENAMIENTO = 'SUBMIT_FORM_DATA_ENTRENAMIENTO';

export const submitFormDataEntrenamiento = (formData: any) => ({
  type: SUBMIT_FORM_DATA_ENTRENAMIENTO,
  payload: formData,
});


export const CreateConversationReducer = (
  mensaje: string,
  entrenamiento?:boolean
): ThunkAction<
  Promise<string>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {

  const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
  const idConversation = localStorage.getItem(tokenChatSeleccionado)
  const conversacionSeleccionada = idConversation || selectConversacionSeleccionada(getState());
  let id_conversacion = conversacionSeleccionada;
  if (id_conversacion == '' || id_conversacion == "0") {
    await fetchWithIP('chatbots/' + tokenChatSeleccionado + '/conversaciones', {
      method: 'POST',
    }).then(async res => {
      return res.json()
    })
      .then(data => {
        const rpta = data.data[0]
        //El redux que inicialmente guardaba el id ahora guarda el token de conversacion
        dispatch(SetConversacionSeleccionadaHome(rpta.identificador))
        id_conversacion = rpta.identificador
        localStorage.setItem(tokenChatSeleccionado,id_conversacion)

      }).catch((error) => {
        console.log(error)
      });
  }

  let mensaje_bot = "";
  await fetchWithIP('chatbots/' + tokenChatSeleccionado + '/conversaciones/' + id_conversacion + '/mensajes', {
    method: 'POST',

  },
    {
      contenido: mensaje,
      emisor: "USUARIO",
      entrenamiento
    }
  ).then(async res => {
    return res.json()
  })
    .then(data => {
      mensaje_bot = data;
    }).catch((error) => {
      console.log(error)
    });

  return mensaje_bot;
}

export const ResetConversationSupportReducer = (
): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  await fetchWithIP('chatbots/token123/conversaciones/identificador',
    {
      method: 'POST',

    },
  )
    .then(async res => {
      return res.json()
    })
    .then(data => {
      if(data.data){
        console.log(data)
        localStorage.setItem('conversacion_support', data.data.identificador)
      }
    }).catch((error) => {
      console.log(error)
    });
}

export const CreateConversationReducer2 = (
  mensaje: string
): ThunkAction<
  Promise<string>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {

  const chatSeleccionado = selectChatSeleccionado(getState());
  const conversacionSeleccionada = selectConversacionSeleccionada(getState());
  const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());

  let id_conversacion = conversacionSeleccionada;
  if (id_conversacion == "") {
    //await fetchWithIP('chatbots/' + tokenChatSeleccionado + '/conversaciones', {
    await fetchWithIP('chatbots/token123/conversaciones', {

      method: 'POST',
    }).then(async res => {
      return res.json()
    })
      .then(data => {
        const rpta = data.data[0]

        dispatch(SetConversacionSeleccionadaHome(rpta.id))
        id_conversacion = rpta.id

      }).catch((error) => {
        console.log(error)
      });
  }

  let mensaje_bot = "";
  await fetchWithIP('chatbots/token123/conversaciones/' + id_conversacion + '/mensajes', {
    //await fetchWithIP('chatbots/' + tokenChatSeleccionado + '/conversaciones/' + id_conversacion + '/mensajes', {
    method: 'POST',
  },
    {
      contenido: mensaje,
      emisor: "USUARIO"
    }
  ).then(async res => {
    return res.json()
  })
    .then(data => {
      mensaje_bot = data;
    }).catch((error) => {
      console.log(error)
    });

  return mensaje_bot;
}

export const CreateMessageTrainReducer = (
  sender: string,
  idConversation: number,
  mensaje: string
): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {

  if (sender == "emisor") {
    sender = "USUARIO";
  } else {
    sender = "LLM";
  }

  const body = [
    {
      "emisor": sender,
      "contenido": mensaje
    },
  ]
  await fetchWithIP('entrenamientos/' + idConversation + '/mensajes',
    {
      method: 'POST',

    },
    body
  )
    .then(async res => {
      return res.json()
    })
    .then(data => {

    }).catch((error) => {
      console.log(error)
    });

}

export const GetConversationReducer = (
  identifier: string | null = null,
  save_conversation_redux = true
): ThunkAction<void, RootState, unknown, Action> => async (dispatch, getState) => {
  const conversacionSeleccionada = selectConversacionSeleccionada(getState());
  const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());

  // Usa el identificador pasado o el del estado
  const identificadorAUsar = identifier || conversacionSeleccionada;
  
  if (identificadorAUsar) {
    try {
      const response = await fetchWithIP(
        `chatbots/${tokenChatSeleccionado}/conversaciones/${identificadorAUsar}/mensajes`,
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error(`Error al obtener la conversación: ${response.status}`);
      }

      const data = await response.json();
      
      // Mapea los mensajes manteniendo el identificador de la conversación
      const chat_conversation = data.map((dat: any) => ({
        id: dat.id,
        sender: dat.emisor === "LLM" ? "receptor" : "emisor",
        text: dat.contenido,
        conversacionIdentificador: identificadorAUsar, // Añade el identificador
        date: dat.createdAt || "17/7/2024 12:26:33",
        time: dat.createdAt || "17/7/2024 12:26:33",
      }));

      if (save_conversation_redux) {
        const isSupportChat = identificadorAUsar.includes("support_")
        dispatch({
          type: isSupportChat ? GET_CONVERSATION_CHAT_SUPPORT : GET_CONVERSATION_TAB_CHAT,
          payload: chat_conversation,
        });
      }

      dispatch({
        type: SET_TOKEN_CHAT_BY_USER,
        payload : tokenChatSeleccionado
      })

      return chat_conversation;
    } catch (error) {
      console.error("Error al obtener la conversación:", error);
      throw error;
    }
  }
};

export const ResetConversationReducer = (

): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  dispatch({
    type: GET_CONVERSATION_TAB_CHAT,
    payload: []
  })
}

export const ResetBotSelectedReducer = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {

  const bots: any = getState().home.rex_chatsbots;
  const updatedArray = bots.map((item: any) => ({
    ...item,
    selected: false
  }));

  dispatch(GetChatSeccionadoHome("0"))

  dispatch({
    type: GET_DATA_CHATSBOTS_HOME,
    payload: updatedArray
  });

  dispatch({
    type: BOT_SELECTED,
    payload: null
  })

}


export const UploadTrainingData = (
  formData: FormData
): ThunkAction<Promise<boolean | void>, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {

    const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
    try {
      const id_chatbot = selectChatSeleccionado(getState());
      if (!id_chatbot) {
        console.error("ID de chatbot no encontrado en el estado");
        return;
      }
      const response = await fetchWithIP(`chatbots/${tokenChatSeleccionado}/entrenamientos/archivo`, {

        method: 'POST',

      }, formData);


      if (response.ok) {
        return true;
      } else {
        console.error("Error al subir el archivo");
        return false;
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

// Actualizar mensajes de entrenamiento
export const UploadMessagesTrainReducer = (
  sender: string,
  idConversation: number,
  mensaje: string,
  idMessage: number,
): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {

  if (sender == "emisor") {
    sender = "USUARIO";
  } else {
    sender = "LLM";
  }

  const body = {
    "emisor": sender,
    "contenido": mensaje,
  }

  await fetchWithIP('entrenamientos/' + idConversation + '/mensajes/' + idMessage,
    {
      method: 'PUT',

    },
    body
  )
    .then(async res => {
      return res.json()
    })
    .then(data => {

    }).catch((error) => {
      console.log(error)
    });
    

}

export const DeleteMessageTrainReducer = (
  entrenamientoId: number,
  mensajeId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch({ type: DELETE_MESSAGE_REQUEST }); // Notificar que se inició la petición

  const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());

  try {
    // Realiza la solicitud DELETE
    const response = await fetchWithIP(
      `entrenamientos/${entrenamientoId}/mensajes/${mensajeId}`,
      {
        method: 'DELETE',
      }
    );

    if (!response.ok) {
      throw new Error('Error al eliminar el mensaje');
    }

    // Notificar éxito
    dispatch({
      type: DELETE_MESSAGE_SUCCESS,
      payload: mensajeId,
    });

  } catch (error) {
    console.error('Error al eliminar el mensaje:', error);

    // Notificar error
    dispatch({
      type: DELETE_MESSAGE_FAILURE,
      payload: 'No se pudo eliminar el mensaje',
    });
  }
};

// Modificación para manejar paginación, orden y filtros
export const fetchNuestrosChatbots = (
  page: number = 1,
  pageSize: number = 10,
  sortColumn: string = 'nombre',
  sortOrder: string = 'asc',
  filters?: {
    nombre?: string,
    descripcion?: string,
    creadoEnFrom?: string,
    creadoEnTo?: string
  }
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({ type: GET_NUESTROS_CHATBOTS_REQUEST });

  try {
    const queryParams = new URLSearchParams({
      page: String(page),
      pageSize: pageSize.toString(),
      sortColumn,
      sortOrder,
    });

    if (filters?.nombre) queryParams.append('nombre', filters.nombre);
    if (filters?.descripcion) queryParams.append('descripcion', filters.descripcion);
    if (filters?.creadoEnFrom) queryParams.append('creadoEnFrom', filters.creadoEnFrom);
    if (filters?.creadoEnTo) queryParams.append('creadoEnTo', filters.creadoEnTo);

    const response = await fetchWithIP(`chatbot/nuestros-chatbots?${queryParams.toString()}`, {
      method: 'GET',
    });

    const data = await response.json();
    
    if (data.respuesta) {
      dispatch({
        type: GET_NUESTROS_CHATBOTS_SUCCESS,
        payload: data
      });
    } else {
      throw new Error(data.message);
    }
  } catch (error: any) {
    dispatch({
      type: GET_NUESTROS_CHATBOTS_FAILURE,
      payload: error.message || 'Error fetching chatbots'
    });
  }
};


// Action creators
export const setChatbotsFilters = (filters: { [key: string]: string }) => ({
  type: SET_CHATBOTS_FILTERS,
  payload: filters,
});

export const setChatbotsSort = (sortColumn: string, sortOrder: string) => ({
  type: SET_CHATBOTS_SORT,
  payload: { sortColumn, sortOrder },
});

export const setChatbotsSearch = (searchQuery: string) => ({
  type: SET_CHATBOTS_SEARCH,
  payload: searchQuery,
});


// Action para filtrar chatbots
export const fetchNuestrosChatbotsConFiltro = (filters: any): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch: Dispatch) => {
  dispatch({ type: GET_NUESTROS_CHATBOTS_REQUEST });

  try {
    // Se puede ajustar esta URL de acuerdo con los filtros que deseas enviar a la API
    const response = await fetchWithIP('chatbot/nuestrosChatbots', {
      method: 'GET',
      body: JSON.stringify(filters), // O los parámetros de consulta, si la API los necesita
    });

    const data = await response.json();

    dispatch({
      type: GET_NUESTROS_CHATBOTS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.error('Error fetching nuestros chatbots:', error);
    dispatch({
      type: GET_NUESTROS_CHATBOTS_FAILURE,
      payload: error.message || 'Error al obtener los chatbots',
    });
  }
};

