import React, { useState } from 'react'
import { Button, Col, Divider, Form, Input, Row, App } from 'antd'
import IconGoogle from '../../assets/icons/Google.svg';
import IconArrowL from '../../assets/icons/IconArrowL.svg';
import ImgLogin from '../../assets/img/login/login2.svg';
import './LoginPlantilla.css'
import { userCredential } from '../../auth/types/userTypes';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/store';
import { useNavigate } from 'react-router-dom';
import { LoginAuthReducer } from '../../redux/actions/auth/Auth';

const LoginPlantilla = () => {

  const [usuario, setUsuario] = useState('')
  const [contrasena, setContrasena] = useState('')

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { notification } = App.useApp();


  const handleLogin = async () => {

    const loginCred: userCredential = {
      usuario,
      contrasena,
    };

    const rpta: any = await dispatch(LoginAuthReducer(loginCred));

    if (rpta.respuesta) {
      notification.success({ message: rpta.mensaje });
      navigate('/plantillas-make');
    } else {
      notification.error({ message: "Lo sentimos, el usuario o contraseña son incorrectas" });
    }
  };

  return (
    <Row style={{ height: '100vh' }}>
      <Col 
        xs={0} 
        md={13} 
        className='container-image-login' 
        // style={{ backgroundImage: "url(" + ImgLogin + ")" }}
      >
      </Col>
      <Col xs={24} md={11} style={{ padding: '60px 40px' }}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={12} style={{ display: 'flex' }}>
            {/* <img src={IconArrowL} alt="Arrow Icon" style={{ marginRight: '15px' }} />
            <div style={{ fontWeight: '600', fontSize: '18px', lineHeight: '28px', color: '#5f5f5f' }}>Volver</div> */}
          </Col>
          <Col style={{ textAlign: 'right' }} md={12}>
            <div style={{ fontSize: '18px', lineHeight: '28px', fontWeight: '600', color: '#808080' }}>No tienes cuenta? <span style={{ color: '#006497',fontWeight: '700', }}>Registrate</span></div>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Col xs={22} md={14}>
            <div style={{ fontWeight: '700', fontSize: '24px', lineHeight: '38px', marginBottom: '25px' }}>Inicia sesion en Hyperia</div>
            <div>
              <Form
                layout='vertical'
                name="basic"
              >
                <Form.Item
                  label={<div style={{ fontSize: '16px', lineHeight: '25px', fontWeight: '500' }}>¿Cuál es tu correo electrónico?</div>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'El correo es requerido!',
                    },
                  ]}
                >
                  <Input onChange={(e)=> setUsuario(e.target.value)} className='input-login-plantilla' placeholder="" />
                </Form.Item>
                <Form.Item
                  label={<div style={{ fontSize: '16px', lineHeight: '25px', fontWeight: '500' }}>Por favor introduce tu contraseña</div>}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'La contraseña es obligatorio!',
                    },
                  ]}
                >
                  <Input.Password onChange={(e)=>setContrasena(e.target.value)} className='input-login-plantilla' placeholder="" />
                </Form.Item>
              </Form>

              <div style={{ textAlign: 'right', color: '#1a1a1ab2', fontSize: '14px', lineHeight: '22px', fontWeight: '400' }}>Olvidaste tu contraseña?</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
              <Button onClick={handleLogin} style={{ width: '278px', height: '44px', backgroundColor: '#016AA6', color: '#FFFFFF', fontSize: '16px', lineHeight: '25px', fontWeight: '600', border:'none' }}>Inciar Sesion</Button>
              {/* <Divider> */}
                {/* <h1 style={{ fontSize: '14px', lineHeight: '22px', fontWeight: '500' }}>
                  O inicia sesión con
                </h1> */}
              {/* </Divider> */}
              {/* <div className='button-google'>
                <img src={IconGoogle} style={{ width: '24px' }} alt="Google Icon" />
              </div> */}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LoginPlantilla