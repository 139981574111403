export const FETCH_MARKET_CHATBOTS_REQUEST = 'FETCH_MARKET_CHATBOTS_REQUEST';
export const FETCH_MARKET_CHATBOTS_SUCCESS = 'FETCH_MARKET_CHATBOTS_SUCCESS';
export const FETCH_MARKET_CHATBOTS_FAILURE = 'FETCH_MARKET_CHATBOTS_FAILURE';
export const ADD_CHATBOT_TO_MARKET_REQUEST = 'ADD_CHATBOT_TO_MARKET_REQUEST';
export const ADD_CHATBOT_TO_MARKET_SUCCESS = 'ADD_CHATBOT_TO_MARKET_SUCCESS';
export const ADD_CHATBOT_TO_MARKET_FAILURE = 'ADD_CHATBOT_TO_MARKET_FAILURE';

export const DELETE_FROM_MARKET_REQUEST = 'DELETE_FROM_MARKET_REQUEST';
export const DELETE_FROM_MARKET_SUCCESS = 'DELETE_FROM_MARKET_SUCCESS';
export const DELETE_FROM_MARKET_FAILURE = 'DELETE_FROM_MARKET_FAILURE';

export const UPDATE_BLOCK_STATUS_REQUEST = 'UPDATE_BLOCK_STATUS_REQUEST';
export const UPDATE_BLOCK_STATUS_SUCCESS = 'UPDATE_BLOCK_STATUS_SUCCESS';
export const UPDATE_BLOCK_STATUS_FAILURE = 'UPDATE_BLOCK_STATUS_FAILURE';

export const DUPLICATE_CHATBOT_REQUEST = 'DUPLICATE_CHATBOT_REQUEST';
export const DUPLICATE_CHATBOT_SUCCESS = 'DUPLICATE_CHATBOT_SUCCESS';
export const DUPLICATE_CHATBOT_FAILURE = 'DUPLICATE_CHATBOT_FAILURE';

export interface UpdateBlockStatusRequestAction {
  type: typeof UPDATE_BLOCK_STATUS_REQUEST;
}

export interface UpdateBlockStatusSuccessAction {
  type: typeof UPDATE_BLOCK_STATUS_SUCCESS;
  payload: any;
}

export interface UpdateBlockStatusFailureAction {
  type: typeof UPDATE_BLOCK_STATUS_FAILURE;
  payload: string;
}

export interface DuplicateChatbotRequestAction {
  type: typeof DUPLICATE_CHATBOT_REQUEST;
}

export interface DuplicateChatbotSuccessAction {
  type: typeof DUPLICATE_CHATBOT_SUCCESS;
  payload: any;
}

export interface DuplicateChatbotFailureAction {
  type: typeof DUPLICATE_CHATBOT_FAILURE;
  payload: string;
}

export interface DeleteFromMarketRequestAction {
  type: typeof DELETE_FROM_MARKET_REQUEST;
}

export interface DeleteFromMarketSuccessAction {
  type: typeof DELETE_FROM_MARKET_SUCCESS;
  payload: any;
}

export interface DeleteFromMarketFailureAction {
  type: typeof DELETE_FROM_MARKET_FAILURE;
  payload: string;
}

interface FetchMarketChatbotsRequestAction {
  type: typeof FETCH_MARKET_CHATBOTS_REQUEST;
}

interface FetchMarketChatbotsSuccessAction {
  type: typeof FETCH_MARKET_CHATBOTS_SUCCESS;
  payload: any;
}

interface FetchMarketChatbotsFailureAction {
  type: typeof FETCH_MARKET_CHATBOTS_FAILURE;
  payload: string;
}

interface AddChatbotToMarketRequestAction {
  type: typeof ADD_CHATBOT_TO_MARKET_REQUEST;
}

interface AddChatbotToMarketSuccessAction {
  type: typeof ADD_CHATBOT_TO_MARKET_SUCCESS;
  payload: any;
}

interface AddChatbotToMarketFailureAction {
  type: typeof ADD_CHATBOT_TO_MARKET_FAILURE;
  payload: string;
}

export type OurChatbotsActionTypes = 
  | FetchMarketChatbotsRequestAction
  | FetchMarketChatbotsSuccessAction
  | FetchMarketChatbotsFailureAction
  | AddChatbotToMarketRequestAction
  | AddChatbotToMarketSuccessAction
  | AddChatbotToMarketFailureAction
  | DeleteFromMarketRequestAction
  | DeleteFromMarketSuccessAction
  | DeleteFromMarketFailureAction
  | UpdateBlockStatusRequestAction
  | UpdateBlockStatusSuccessAction
  | UpdateBlockStatusFailureAction
  | DuplicateChatbotRequestAction
  | DuplicateChatbotSuccessAction
  | DuplicateChatbotFailureAction;