import React from 'react';
import { Modal, Button, Space, message } from 'antd';
import { SyncOutlined, DeleteOutlined, CopyOutlined, ContainerOutlined, PlusOutlined } from '@ant-design/icons';
import IconClosed from "../../../../assets/IconClosed.svg";

interface AirtableTable {
    id: number;
    table_id: string;
    table_nombre: string;
    leyo: boolean;
    contenido: string;
    created_at: string;
    updated_at: string;
}

interface ModalAirtableTablesProps {
    visible: boolean;
    onClose: () => void;
    title?: string;
    tables: AirtableTable[];
    onUpdateTable?: (table: AirtableTable) => void;
    onDeleteTable?: (table: AirtableTable) => void;
    onShowContent?: (table: AirtableTable) => void;
    onAddTable?: () => void;
    baseName?: string;
}

const ModalAirtableTables: React.FC<ModalAirtableTablesProps> = ({
    visible,
    onClose,
    title = "Tablas de Airtable",
    tables = [],
    onUpdateTable,
    onDeleteTable,
    onShowContent,
    onAddTable,
    baseName
}) => {
    const handleCopyTableId = (tableId: string) => {
        navigator.clipboard.writeText(tableId).then(() => {
            message.success("ID de tabla copiado al portapapeles!");
        }).catch(() => {
            message.error("Error al copiar el ID de la tabla.");
        });
    };

    const formatDate = (date: string) => {
        const d = new Date(date);
        return d.toLocaleDateString('es-PE');
    };

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            closable={false}
            width="70%"
            bodyStyle={{ borderRadius: "12px" }}
        >
            <div style={{
                position: "absolute",
                top: "34px",
                right: "34px",
                display: "flex",
                gap: "10px",
                alignItems: "center"
            }}>
                <PlusOutlined
                    onClick={onAddTable}
                    style={{
                        cursor: "pointer",
                        fontSize: "24px",
                        color: "#1890ff"
                    }}
                />
                <img
                    src={IconClosed}
                    alt="Cerrar"
                    onClick={onClose}
                    style={{
                        cursor: "pointer",
                        width: "28px",
                        height: "28px",
                    }}
                />
            </div>
            <div className="mx-[50px] my-[35px]">
                <h3 style={{ fontSize: '24px', marginBottom: '20px', alignItems: 'center' }}>
                    {`Tablas de ${baseName || 'la base'}`}
                </h3>
                <div style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
                    {tables.length > 0 ? (
                        tables.map((table) => (
                            <Button
                                key={table.id}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                    whiteSpace: 'normal',
                                    lineHeight: '1.5',
                                    padding: '10px',
                                    gap: '8px',
                                    minHeight: 'fit-content',
                                    marginBottom: '24px'
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    flex: 1,
                                    minWidth: 0
                                }}>
                                    <span style={{
                                        color: table.leyo ? 'green' : 'red',
                                        fontSize: '24px',
                                        flexShrink: 0
                                    }}>●</span>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minWidth: 0
                                    }}>
                                        <div style={{
                                            fontSize: '16px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                            textAlign: 'left'
                                        }}>
                                            {table.table_nombre}
                                        </div>
                                        <div style={{
                                            fontSize: '14px',
                                            color: '#666',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {table.table_id}
                                        </div>
                                        <div style={{
                                            fontSize: '14px',
                                            color: '#666',
                                            textAlign: 'left'
                                        }}>
                                            {formatDate(table.updated_at)}
                                        </div>
                                    </div>
                                </div>
                                <Space style={{ flexShrink: 0 }}>
                                    <SyncOutlined
                                        style={{
                                            color: 'green',
                                            fontSize: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onUpdateTable?.(table);
                                        }}
                                    />
                                    <DeleteOutlined
                                        style={{
                                            color: 'red',
                                            fontSize: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteTable?.(table);
                                        }}
                                    />
                                    <CopyOutlined
                                        style={{ color: 'gray', fontSize: '16px', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyTableId(table.table_id);
                                        }}
                                    />
                                    <ContainerOutlined
                                        style={{
                                            color: '#ffa500',
                                            fontSize: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onShowContent?.(table);
                                        }}
                                    />
                                </Space>
                            </Button>
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', color: '#666' }}>
                            No hay tablas disponibles
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalAirtableTables;