export const GET_CONVERSATION_TAB_CHAT = 'GET_CONVERSATION_TAB_CHAT';
export const GET_CONVERSATION_CHAT_SUPPORT = 'GET_CONVERSATION_CHAT_SUPPORT';
export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';


export const GET_NUESTROS_CHATBOTS_REQUEST = 'GET_NUESTROS_CHATBOTS_REQUEST';
export const GET_NUESTROS_CHATBOTS_SUCCESS = 'GET_NUESTROS_CHATBOTS_SUCCESS';
export const GET_NUESTROS_CHATBOTS_FAILURE = 'GET_NUESTROS_CHATBOTS_FAILURE';

// Action types
export const SET_CHATBOTS_FILTERS = 'SET_CHATBOTS_FILTERS';
export const SET_CHATBOTS_SORT = 'SET_CHATBOTS_SORT';
export const SET_CHATBOTS_SEARCH = 'SET_CHATBOTS_SEARCH';  // For searching
export const SET_TOKEN_CHAT_BY_USER = 'SET_TOKEN_CHAT_BY_USER';


interface DeleteMessageRequestAction {
    type: typeof DELETE_MESSAGE_REQUEST;
  }
  
  interface DeleteMessageSuccessAction {
    type: typeof DELETE_MESSAGE_SUCCESS;
    payload: number; // ID del mensaje eliminado
  }
  
  interface DeleteMessageFailureAction {
    type: typeof DELETE_MESSAGE_FAILURE;
    payload: string;
  }

  interface GetNuestrosChatbotsRequestAction {
    type: typeof GET_NUESTROS_CHATBOTS_REQUEST;
  }
  
  interface GetNuestrosChatbotsSuccessAction {
    type: typeof GET_NUESTROS_CHATBOTS_SUCCESS;
    payload: any[]; // Array de chatbots
  }
  
  interface GetNuestrosChatbotsFailureAction {
    type: typeof GET_NUESTROS_CHATBOTS_FAILURE;
    payload: string;
  }


  export type ChatBotsActionTypes =
  | DeleteMessageRequestAction
  | DeleteMessageSuccessAction
  | DeleteMessageFailureAction
  | GetNuestrosChatbotsRequestAction
  | GetNuestrosChatbotsSuccessAction
  | GetNuestrosChatbotsFailureAction;