export const FETCH_LECTURA_REQUEST = 'FETCH_LECTURA_REQUEST';
export const FETCH_LECTURA_SUCCESS = 'FETCH_LECTURA_SUCCESS';
export const FETCH_LECTURA_FAILURE = 'FETCH_LECTURA_FAILURE';

export const FETCH_GET_LECTURAS_REQUEST = 'FETCH_GET_LECTURAS_REQUEST';
export const FETCH_GET_LECTURAS_SUCCESS = 'FETCH_GET_LECTURAS_SUCCESS';
export const FETCH_GET_LECTURAS_FAILURE = 'FETCH_GET_LECTURAS_FAILURE';

export const FETCH_GET_LECTURAS_FILTER_REQUEST = 'FETCH_GET_LECTURAS_FILTER_REQUEST';
export const FETCH_GET_LECTURAS_FILTER_SUCCESS = 'FETCH_GET_LECTURAS_FILTER_SUCCESS';
export const FETCH_GET_LECTURAS_FILTER_FAILURE = 'FETCH_GET_LECTURAS_FILTER_FAILURE';

export const DELETE_LECTURA_REQUEST = 'DELETE_LECTURA_REQUEST';
export const DELETE_LECTURA_SUCCESS = 'DELETE_LECTURA_SUCCESS';
export const DELETE_LECTURA_FAILURE = 'DELETE_LECTURA_FAILURE';

export const DELETE_SUBURL_REQUEST = 'DELETE_SUBURL_REQUEST';
export const DELETE_SUBURL_SUCCESS = 'DELETE_SUBURL_SUCCESS';
export const DELETE_SUBURL_FAILURE = 'DELETE_SUBURL_FAILURE';

export const UPDATE_LECTURA_REQUEST = 'UPDATE_LECTURA_REQUEST';
export const UPDATE_LECTURA_SUCCESS = 'UPDATE_LECTURA_SUCCESS';
export const UPDATE_LECTURA_FAILURE = 'UPDATE_LECTURA_FAILURE';

export const UPDATE_LECTURA_CONTENT_REQUEST = 'UPDATE_LECTURA_CONTENT_REQUEST';
export const UPDATE_LECTURA_CONTENT_SUCCESS = 'UPDATE_LECTURA_CONTENT_SUCCESS';
export const UPDATE_LECTURA_CONTENT_FAILURE = 'UPDATE_LECTURA_CONTENT_FAILURE';

export const UPDATE_SUB_URL_CONTENT_REQUEST = 'UPDATE_SUB_URL_CONTENT_REQUEST';
export const UPDATE_SUB_URL_CONTENT_SUCCESS = 'UPDATE_SUB_URL_CONTENT_SUCCESS';
export const UPDATE_SUB_URL_CONTENT_FAILURE = 'UPDATE_SUB_URL_CONTENT_FAILURE';

export const UPDATE_LECTURA_MANUAL_CONTENT_REQUEST = 'UPDATE_LECTURA_MANUAL_CONTENT_REQUEST';
export const UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS = 'UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS';
export const UPDATE_LECTURA_MANUAL_CONTENT_FAILURE = 'UPDATE_LECTURA_MANUAL_CONTENT_FAILURE';

export const UPDATE_SUBURL_MANUAL_CONTENT_REQUEST = 'UPDATE_SUBURL_MANUAL_CONTENT_REQUEST';
export const UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS = 'UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS';
export const UPDATE_SUBURL_MANUAL_CONTENT_FAILURE = 'UPDATE_SUBURL_MANUAL_CONTENT_FAILURE';
export const FETCH_AIRTABLE_REQUEST = 'FETCH_AIRTABLE_REQUEST';
export const FETCH_AIRTABLE_SUCCESS = 'FETCH_AIRTABLE_SUCCESS';
export const FETCH_AIRTABLE_FAILURE = 'FETCH_AIRTABLE_FAILURE';

export const FETCH_AIRTABLE_CONFIGS_REQUEST = 'FETCH_AIRTABLE_CONFIGS_REQUEST';
export const FETCH_AIRTABLE_CONFIGS_SUCCESS = 'FETCH_AIRTABLE_CONFIGS_SUCCESS';
export const FETCH_AIRTABLE_CONFIGS_FAILURE = 'FETCH_AIRTABLE_CONFIGS_FAILURE';

export const DELETE_AIRTABLE_BASE_REQUEST = 'DELETE_AIRTABLE_BASE_REQUEST';
export const DELETE_AIRTABLE_BASE_SUCCESS = 'DELETE_AIRTABLE_BASE_SUCCESS';
export const DELETE_AIRTABLE_BASE_FAILURE = 'DELETE_AIRTABLE_BASE_FAILURE';

export const DELETE_AIRTABLE_TABLE_REQUEST = 'DELETE_AIRTABLE_TABLE_REQUEST';
export const DELETE_AIRTABLE_TABLE_SUCCESS = 'DELETE_AIRTABLE_TABLE_SUCCESS';
export const DELETE_AIRTABLE_TABLE_FAILURE = 'DELETE_AIRTABLE_TABLE_FAILURE';

export const UPDATE_AIRTABLE_BASE_REQUEST = 'UPDATE_AIRTABLE_BASE_REQUEST';
export const UPDATE_AIRTABLE_BASE_SUCCESS = 'UPDATE_AIRTABLE_BASE_SUCCESS';
export const UPDATE_AIRTABLE_BASE_FAILURE = 'UPDATE_AIRTABLE_BASE_FAILURE';

export const UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST = 'UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST';
export const UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS = 'UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS';
export const UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE = 'UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE';

export const EDIT_AIRTABLE_TABLE_CONTENT_REQUEST = 'EDIT_AIRTABLE_TABLE_CONTENT_REQUEST';
export const EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS = 'EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS';
export const EDIT_AIRTABLE_TABLE_CONTENT_FAILURE = 'EDIT_AIRTABLE_TABLE_CONTENT_FAILURE';

export const UPDATE_FULL_AIRTABLE_BASE_REQUEST = 'UPDATE_FULL_AIRTABLE_BASE_REQUEST';
export const UPDATE_FULL_AIRTABLE_BASE_SUCCESS = 'UPDATE_FULL_AIRTABLE_BASE_SUCCESS';
export const UPDATE_FULL_AIRTABLE_BASE_FAILURE = 'UPDATE_FULL_AIRTABLE_BASE_FAILURE';

export const UPDATE_USER_AIRTABLES_REQUEST = 'UPDATE_USER_AIRTABLES_REQUEST';
export const UPDATE_USER_AIRTABLES_SUCCESS = 'UPDATE_USER_AIRTABLES_SUCCESS';
export const UPDATE_USER_AIRTABLES_FAILURE = 'UPDATE_USER_AIRTABLES_FAILURE';

export const ADD_AIRTABLE_TABLE_REQUEST = 'ADD_AIRTABLE_TABLE_REQUEST';
export const ADD_AIRTABLE_TABLE_SUCCESS = 'ADD_AIRTABLE_TABLE_SUCCESS';
export const ADD_AIRTABLE_TABLE_FAILURE = 'ADD_AIRTABLE_TABLE_FAILURE';

export const ADD_SUBURL_REQUEST = 'ADD_SUBURL_REQUEST';
export const ADD_SUBURL_SUCCESS = 'ADD_SUBURL_SUCCESS';
export const ADD_SUBURL_FAILURE = 'ADD_SUBURL_FAILURE';


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_AIRTABLE_PAGE = 'SET_CURRENT_AIRTABLE_PAGE';

export interface LecturaWebActions {
  type: typeof FETCH_LECTURA_REQUEST | typeof FETCH_LECTURA_SUCCESS | typeof FETCH_LECTURA_FAILURE |
    typeof FETCH_GET_LECTURAS_REQUEST | typeof FETCH_GET_LECTURAS_SUCCESS | typeof FETCH_GET_LECTURAS_FAILURE |
    typeof FETCH_GET_LECTURAS_FILTER_REQUEST | typeof FETCH_GET_LECTURAS_FILTER_SUCCESS | typeof FETCH_GET_LECTURAS_FILTER_FAILURE |
    typeof DELETE_LECTURA_REQUEST | typeof DELETE_LECTURA_SUCCESS | typeof DELETE_LECTURA_FAILURE |
    typeof UPDATE_LECTURA_REQUEST | typeof UPDATE_LECTURA_SUCCESS | typeof UPDATE_LECTURA_FAILURE |
    typeof SET_CURRENT_PAGE | typeof UPDATE_LECTURA_CONTENT_REQUEST | typeof UPDATE_LECTURA_CONTENT_SUCCESS |
    typeof UPDATE_LECTURA_CONTENT_FAILURE | typeof DELETE_SUBURL_REQUEST | typeof DELETE_SUBURL_SUCCESS |
    typeof DELETE_SUBURL_FAILURE | typeof UPDATE_SUB_URL_CONTENT_REQUEST | typeof UPDATE_SUB_URL_CONTENT_SUCCESS | 
    typeof UPDATE_SUB_URL_CONTENT_FAILURE | typeof UPDATE_LECTURA_MANUAL_CONTENT_REQUEST | 
    typeof UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS | typeof UPDATE_LECTURA_MANUAL_CONTENT_FAILURE |
    typeof UPDATE_SUBURL_MANUAL_CONTENT_REQUEST | typeof UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS |
    typeof UPDATE_SUBURL_MANUAL_CONTENT_FAILURE | typeof FETCH_AIRTABLE_REQUEST | typeof FETCH_AIRTABLE_SUCCESS | 
    typeof FETCH_AIRTABLE_FAILURE | typeof FETCH_AIRTABLE_CONFIGS_REQUEST | typeof FETCH_AIRTABLE_CONFIGS_SUCCESS | 
    typeof FETCH_AIRTABLE_CONFIGS_FAILURE | typeof DELETE_AIRTABLE_BASE_REQUEST | typeof DELETE_AIRTABLE_BASE_SUCCESS | 
    typeof DELETE_AIRTABLE_BASE_FAILURE | typeof DELETE_AIRTABLE_TABLE_REQUEST | typeof DELETE_AIRTABLE_TABLE_SUCCESS | 
    typeof DELETE_AIRTABLE_TABLE_FAILURE | typeof UPDATE_AIRTABLE_BASE_REQUEST | typeof UPDATE_AIRTABLE_BASE_SUCCESS | 
    typeof UPDATE_AIRTABLE_BASE_FAILURE | typeof UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST | typeof UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS | 
    typeof UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE | typeof EDIT_AIRTABLE_TABLE_CONTENT_REQUEST | typeof EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS | 
    typeof EDIT_AIRTABLE_TABLE_CONTENT_FAILURE | typeof UPDATE_FULL_AIRTABLE_BASE_REQUEST | typeof UPDATE_FULL_AIRTABLE_BASE_SUCCESS | 
    typeof UPDATE_FULL_AIRTABLE_BASE_FAILURE | typeof SET_CURRENT_AIRTABLE_PAGE | typeof ADD_SUBURL_REQUEST | typeof ADD_SUBURL_SUCCESS | 
    typeof ADD_SUBURL_FAILURE | typeof UPDATE_USER_AIRTABLES_REQUEST | typeof UPDATE_USER_AIRTABLES_SUCCESS | 
    typeof UPDATE_USER_AIRTABLES_FAILURE | typeof ADD_AIRTABLE_TABLE_REQUEST | typeof ADD_AIRTABLE_TABLE_SUCCESS | 
    typeof ADD_AIRTABLE_TABLE_FAILURE;
  payload?: any;
}