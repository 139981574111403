import { Col, Row, Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import CardBot from '../../components/pages/home/CardBot';
import CardTokens from '../../components/pages/home/CardTokens';
import ChartDonut from '../../components/pages/home/ChartDonut';
import { PlusOutlined, IdcardTwoTone, MessageTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store/store';
import {
  GetaverAgeConversationsMessagesHomeReducer,
  GetCountConversacionesHomeReducer,
  GetCountMessagesHomeReducer,
  GetCountTokensHomeReducer,
  ResetChatSelectionReducer,
  GetUserMessagesInfoReducer
} from '../../redux/actions/home/Home';
import MiPlan from '../../components/plan/MiPlan';
import SpotlightTour from '../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../components/pages/ShadowComponent/TourContext';

const Home: React.FC = () => {
  const [donutChartData, setDonutChartData] = React.useState<{ name: string, value: number }[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { rex_user_auth } = useSelector(({ auth }: any) => auth);
  const { setCurrentView } = useTour();
  
  useEffect(() => {
    setCurrentView('home');
    return () => setCurrentView('');
  }, []);

  useEffect(() => {
    dispatch(GetCountConversacionesHomeReducer());
    dispatch(GetCountMessagesHomeReducer());
    dispatch(GetaverAgeConversationsMessagesHomeReducer());
    dispatch(GetUserMessagesInfoReducer());
    dispatch(GetCountTokensHomeReducer());
    return () => {
      dispatch(ResetChatSelectionReducer());
    };
  }, [location]);

  const {
    rex_count_conversations,
    rex_count_messages,
    rex_average,
    rex_messages,
    rex_tokens,
    rex_chat_selecccionado,
    rex_chatsbots
  } = useSelector(({ home }: any) => home);

  useEffect(() => {
    if (rex_chat_selecccionado && rex_chatsbots?.length > 0) {
      // Encontrar el chatbot seleccionado
      const selectedBot = rex_chatsbots.find((bot: any) => bot.id === rex_chat_selecccionado);
      
      if (selectedBot) {
        // Obtener mensajes del chatbot seleccionado
        const botMessages = rex_count_messages.data?.[0]?.count || 0;
        const totalDisponibles = rex_messages.data?.[0]?.mensajes_disponibles || 100;
        
        // Actualizar el donut chart con los datos específicos del chatbot
        setDonutChartData([
          { name: 'N° Mensajes E/R', value: botMessages },
          { name: 'Mensajes Disponibles', value: totalDisponibles }
        ]);
      }
    } else if (rex_messages?.data?.length) {
      // Si no hay chatbot seleccionado, mostrar datos generales
      const mensajesEnviadosRecibidos = rex_messages.data[0].mensajes_enviados + rex_messages.data[0].mensajes_recibidos;
      const mensajesDisponibles = rex_messages.data[0].mensajes_disponibles;

      setDonutChartData([
        { name: 'N° Mensajes E/R', value: mensajesEnviadosRecibidos },
        { name: 'Mensajes Disponibles', value: mensajesDisponibles }
      ]);
    }
  }, [rex_chat_selecccionado, rex_messages, rex_count_messages, rex_chatsbots]);

  const userStatus = rex_user_auth?.estado_user;
  const isUserInactive = userStatus === 'Inactivo';
  const statusText = isUserInactive ? 'Inactivo' : 'Activo';
  const homeSteps = tourData.Home;

  return (
    <>
      <SpotlightTour steps={homeSteps} view="home" />
      <h2 className='text-hola'>
        Hola {rex_user_auth?.personas?.nombre + " " + rex_user_auth?.personas?.apellido_paterno + " " + rex_user_auth?.personas?.apellido_materno}
      </h2>

      <h3 style={{
        fontSize: '13px',
        color: '#4a4a4a',
        fontWeight: '400',
        letterSpacing: '1px',
        margin: '0',
        lineHeight: '1.5',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
        fontFamily: `'Poppins', sans-serif`,
        marginBottom: '40px'
      }} className='important'>
        <b>IMPORTANTE:</b> Los datos mostrados son del mes actual.
      </h3>

      <Row gutter={[16, 16]}>
        <Col xs={24} lg={24}>
          <Row
            justify="center"
            gutter={[16, 16]}
          >
            <Col
              xxl={4} xl={6} md={12}
              className='graficoDonut'
              style={{
                backgroundColor: '#fff',
                borderRadius: '50px',
                boxShadow: '0 4px 8px rgba(12,12,12, 0.2)'
              }}
            >
              <ChartDonut
                data={donutChartData}
                innerRadius={20}
                outerRadius={80}
                paddingAngle={5}
              />
            </Col>
            <Col
              xxl={15}
              xl={13}
              md={12}
            >
              <Row gutter={[16, 16]}>
                <Col xxl={8} xl={12} md={12} className='cardTokens'>
                  <CardTokens
                    title="Nº CONVERSACIONES"
                    value={rex_count_conversations.data?.[0]?.count}
                    tokens={rex_tokens}
                    icon={<IdcardTwoTone />}
                  />
                </Col>
                <Col xxl={8} xl={12} md={12} className='cardTokens2'>
                  <CardTokens
                    title="Nº MENSAJES ENVIADOS"
                    value={rex_count_messages.data?.[0]?.count}
                    tokens={rex_tokens}
                    icon={<MessageTwoTone />}
                  />
                </Col>
                <Col xxl={8} xl={24} md={12} className='cardTokens3'>
                  <CardTokens
                    title="N° MENSAJES ENVIADOS POR CONVERSACIÓN"
                    value={rex_average.data?.[0]?.average}
                    tokens={rex_tokens}
                  />
                </Col>
              </Row>
            </Col>
            <Col xxl={5} xl={5} md={12}>
              <div className='cardTokens cardPlan'>
                <MiPlan statusText={statusText} />
              </div>
            </Col>
          </Row>
          
          <Divider />

          <Row justify="center" gutter={[16, 16]}>
            <Col xl={24}>
              <Row style={{ marginBottom: '20px' }}>
                <Col xl={12} md={12}>
                  <div style={{ float: 'left' }} className='btn-container'>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => navigate('/crear-chatbot')}
                    >
                      Crear un nuevo chatbot
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} className='cardbots'>
              <CardBot chatWithBot={true} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Home;