import React from 'react';
import { Card, Row, Col, Typography, Space, Divider } from 'antd';
import '../../../styles/pages/PlantillasMake/Cards.css';

const { Text, Title } = Typography;
interface CardProps {
  icons: string[];
  title: string;
  usage: string;
  url: string;
  dificultad: string;
  showMoreDetails?: boolean;
  situaciones?: string;
  integraciones?: string;
  instrucciones?: string;
  onClick?: () => void;
}

const CustomCard: React.FC<CardProps> = ({
  icons,
  title,
  usage,
  url,
  dificultad,
  showMoreDetails = false,
  situaciones,
  integraciones,
  instrucciones,
  onClick
}) => {
  const iconSize = 60;

  const integrationIconsCount = integraciones
    ? integraciones.split(',').length
    : 0;

  const placeholders = Array(integrationIconsCount).fill(
    <div
      style={{
        width: iconSize,
        height: iconSize,
        backgroundColor: '#d9d9d9',
        borderRadius: '50%',
      }}
    />
  );

  const renderedIcons =
    icons.length > 0
      ? icons.slice(0, integrationIconsCount).map((icon, index) => (
        <img
          key={index}
          src={icon}
          alt={`icon-${index}`}
          style={{ width: iconSize, height: iconSize, borderRadius: '50%' }}
        />
      ))
      : placeholders;

      return (
        <Card
          hoverable
          className="custom-card-templates"
          style={{
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            maxWidth: '100%',
            borderRadius: '12px',
          }}
          onClick={onClick}
        >
          <Row justify="center" gutter={8}>
            {renderedIcons.map((icon, index) => (
              <Col key={index}>{icon}</Col>
            ))}
          </Row>
    
          <Space direction="vertical" style={{ margin: '16px 0', width: '100%' }}>
            <Title level={5} style={{ margin: 0, marginBottom: '12px', wordBreak: 'break-word' }}>
              {title}
            </Title>
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <Text strong>Url:</Text>
              <p style={{ wordBreak: 'break-word', overflow: 'hidden' }}>{url}</p>
            </div>
    
            {showMoreDetails && (
              <div style={{ width: '100%', overflow: 'hidden' }}>
                {situaciones && (
                  <div>
                    <Text strong>Situaciones:</Text>
                    <p style={{ wordBreak: 'break-word' }}>{situaciones}</p>
                  </div>
                )}
                {integraciones && (
                  <div>
                    <Text strong>Integraciones:</Text>
                    <p style={{ wordBreak: 'break-word' }}>{integraciones}</p>
                  </div>
                )}
                {instrucciones && (
                  <div>
                    <Text strong>Instrucciones de uso:</Text>
                    <p style={{ wordBreak: 'break-word' }}>{instrucciones}</p>
                  </div>
                )}
              </div>
            )}
          </Space>
    
          <Divider style={{ backgroundColor: '#D9D9D9' }} />
    
          <Row justify="space-between" align="middle" style={{ padding: '0 16px' }}>
            <Text style={{ fontWeight: 'bold', color: '#888' }}>
              Usado {usage} veces
            </Text>
            <Text style={{ fontWeight: 'bold', color: '#888' }}>
              {dificultad}
            </Text>
          </Row>
        </Card>
      );
    };


export default CustomCard;
