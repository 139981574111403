import {
  GET_CONVERSATION_TAB_CHAT,
  GET_CONVERSATION_CHAT_SUPPORT,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  GET_NUESTROS_CHATBOTS_REQUEST,
  GET_NUESTROS_CHATBOTS_SUCCESS,
  GET_NUESTROS_CHATBOTS_FAILURE,
  SET_CHATBOTS_FILTERS,
  SET_CHATBOTS_SORT,
  SET_CHATBOTS_SEARCH,
  SET_TOKEN_CHAT_BY_USER
} from '../../../../constantes/chatBots/chat/Chat';

interface ChatTabState {
  rex_conversation_chat: any[];
  rex_conversation_chat_support: any[];
  rex_loading: boolean; 
  rex_error: string | null; 
  nuestros_chatbots: any[];
  chatbots_loading: boolean;
  chatbots_error: string | null;
  rex_meta: {};
  filters: { [key: string]: string };
  searchQuery: string;
  sortColumn: string;
  sortOrder: string;
  rex_token_chat:string
}

const INIT_STATE: ChatTabState = {
  rex_conversation_chat: [],
  rex_conversation_chat_support: [],
  rex_loading: false,
  rex_error: null,
  nuestros_chatbots: [],
  chatbots_loading: false,
  chatbots_error: null,
  rex_meta: {},
  filters: {},
  searchQuery: '',
  sortColumn: 'id',
  sortOrder: 'asc',
  rex_token_chat :""
};

const Chat = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    // 📚 Conversation handling
    case GET_CONVERSATION_TAB_CHAT:
      return {
        ...state,
        rex_conversation_chat: [...action.payload], // Asegúrate de no mutar el estado.
      };
    case GET_CONVERSATION_CHAT_SUPPORT:
      return {
        ...state,
        rex_conversation_chat_support: [...action.payload], // Asegúrate de no mutar el estado.
      };
    case DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null,
      };

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_conversation_chat: state.rex_conversation_chat.filter(
          (mensaje) => mensaje.id !== action.payload
        ),
      };

    case DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload,
      };

    // ✅ Handle Nuestros Chatbots
    case GET_NUESTROS_CHATBOTS_REQUEST:
      return {
        ...state,
        chatbots_loading: true,
        chatbots_error: null,
      };

    case GET_NUESTROS_CHATBOTS_SUCCESS:
      return {
        ...state,
        chatbots_loading: false,
        nuestros_chatbots: action.payload.data,
        rex_meta: action.payload.meta,
      };

    case GET_NUESTROS_CHATBOTS_FAILURE:
      return {
        ...state,
        chatbots_loading: false,
        chatbots_error: action.payload,
      };

    // ✅ Handle Filters, Search, and Sorting
    case SET_CHATBOTS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case SET_CHATBOTS_SORT:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };

    case SET_CHATBOTS_SEARCH:
      return {
        ...state,
        searchQuery: action.payload,
      };

      case SET_TOKEN_CHAT_BY_USER:
        return {
          ...state,
          rex_token_chat: action.payload,
        };
      

    default:
      return state;
  }
};

export default Chat;
