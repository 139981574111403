import React, { createContext, useContext, useState } from 'react';

interface TourContextType {
  tourActive: boolean;
  setTourActive: (active: boolean) => void;
  currentView: string;
  setCurrentView: (view: string) => void;
  currentSubView: string;
  setCurrentSubView: (subView: string) => void;
  currentStep: number; // Nuevo
  setCurrentStep: (step: number) => void; // Nuevo
  steps: any[]; // Nuevo, o define un tipo más específico si los pasos tienen una estructura conocida
  setSteps: (steps: any[]) => void; // Nuevo
}

const TourContext = createContext<TourContextType | undefined>(undefined);

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tourActive, setTourActive] = useState(false);
  const [currentView, setCurrentView] = useState('');
  const [currentSubView, setCurrentSubView] = useState('');
  const [currentStep, setCurrentStep] = useState(0); // Inicializa currentStep
  const [steps, setSteps] = useState<any[]>([]);

  return (
    <TourContext.Provider value={{ 
      tourActive, 
      setTourActive,
      currentView,
      setCurrentView,
      currentSubView,
      setCurrentSubView,
      currentStep,
      setCurrentStep,
      steps,
      setSteps
    }}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (context === undefined) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};

export default TourContext;