import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from '../pages/chat/Chat';
import AdmChat from '../pages/admin/AdminChat';
import Home from '../pages/home/Home';
import OurChats from '../pages/ourChats/OurChats';
import CardBots from '../pages/nuestros-chatbots/nuestrosChatbots'
import Pricing from '../pages/pricing/Pricing';
import Login from '../pages/login/Login';
import LayoutWithSidebar from '../helpers/LayoutWithSidebar';
import Register from '../pages/register/Register';
import ProtectedRoute from './ProtectedRoute';
import GoogleCallback from './../auth/googleCallback'
import Users from '../pages/admin/users/Users';
import TypeUsersChat from '../pages/admin/typesUsers/TypesUsers';
import PermisosPage from '../components/pages/admin/typeUsers/PermissionsTypeUser';
import Profile from '../pages/user/Profile';
import MySubscription from '../pages/user/MySubscription';
import CreateChatBot from '../pages/create-chatbot/Create-chatbot';
import Planes from '../pages/planes/planes';
import Chatv2 from '../pages/chatv2/Chatv2';
import BigChatComponent from '../components/chat/BigChatComponent';
import PaquetesMensajes from '../pages/paquetes-mensajes/paquetes-mensajes';
import FacebookIntegration from '../pages/chat/tabs/integrations/FacebookIntegration';
import WhatsappIntegration from '../pages/chat/tabs/integrations/WhatsappIntegration';
import Checkout from '../pages/checkout/checkout';
import Embedded from '../pages/embedded/Embedded';
import WebIntegration from '../pages/chat/tabs/integrations/WebIntegration';
import TableOne from '../components/table/TableOne';
import Table from '../pages/prueba/Table';
import Analytics from '../pages/analytics/Analytics';
import ValidateCode from '../pages/validateCode/ValidateCode'
import PlantillasMake from '../pages/plantillas-make/PlantillasMake';
import LoginPlantilla from '../pages/login-plantilla/LoginPlantilla';
import TemplatesCards from '../pages/plantillas-make/templatesCards';
import TabChat from '../pages/chat/tabs/chat/TabChat';
import TabCreateEdit from '../pages/chat/tabs/createEdit/TabCreateEdit';
import TabTrain from '../pages/chat/tabs/train/TabTrain';
import TabConversations from '../pages/chat/tabs/conversations/TabConversations';
import TabDesign from '../pages/chat/tabs/design/TabDesign';
import TabAnalytics from '../pages/chat/tabs/analytics/TabAnalytics';
import TabIntegration from '../pages/chat/tabs/integrations/TabIntegration';
import TabKnowledgeBase from '../pages/chat/tabs/knowledgeBase/TabKnowledgeBase';
import SelectChatBot from '../pages/chat/tabs/selectChatBot/SelectChatBot';
import ApiIntegration from '../pages/chat/tabs/integrations/ApiIntegration';

const AppRoutes: React.FC = () => {

  return (
    // <Router>
    <Routes>
      <Route path="/big-chat-component/:botid/:userid" element={<BigChatComponent embedded={true} />} />

      <Route element={<ProtectedRoute />}>
        <Route element={<LayoutWithSidebar />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/crear-chatbot" element={<CreateChatBot />} />

          <Route path="/chats">
            <Route index element={<Chat />} />
            <Route path=":chatToken" element={<Chat />}>
              <Route index element={<SelectChatBot />} />
              <Route path="chatbots" element={<TabChat />} />
              <Route path="edit" element={<TabCreateEdit />} />
              <Route path="train" element={<TabTrain />} />
              <Route path="conversations" element={<TabConversations />} />
              <Route path="design" element={<TabDesign />} />
              <Route path="analytics" element={<TabAnalytics />} />
              <Route path="integrations" element={<TabIntegration />} />
              <Route path="knowledge-base" element={<TabKnowledgeBase />} />
            </Route>
          </Route>

          <Route path="/analytics" element={<Analytics />} />
          <Route path="/chats/integracion-facebook" element={<FacebookIntegration />} />
          <Route path="/chats/integracion-whatsapp" element={<WhatsappIntegration />} />

          <Route path="/chats/integracion-web" element={<WebIntegration />} />
          <Route path="/chats/integracion-api" element={<ApiIntegration />} />

          <Route path="/chatsv2" element={<Chatv2 />} />
          <Route path="/administrador" element={<AdmChat />} />
          <Route path="/chatbots" element={<OurChats />} />
          <Route path="/nuestros-chats" element={<CardBots />} />
          <Route path="/precios" element={<Pricing />} />
          <Route path="/usuarios" element={<Users />} />
          <Route path="/tipos-usuarios" element={<TypeUsersChat />} />
          <Route path="/planes" element={<Planes />} />
          <Route path="/tipos-usuarios/:tipoUsuarioId/permisos" element={<PermisosPage />} />
          <Route path='/paquetes-mensajes' element={<PaquetesMensajes />} />
          <Route path='/prueba' element={<Table />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<MySubscription />} />
          <Route path="/checkout" element={<Checkout />} />

          {/* <Route path="*" element={<Chat />} /> */}
          <Route path="*" element={<Home />} />

        </Route>
      </Route>

      <Route path="*" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/validateCode" element={<ValidateCode />} />
      <Route path="/auth/google/callback" element={<GoogleCallback />} />
      <Route path="/plantillas-make" element={<PlantillasMake />} />
      <Route path="/login-plantilla" element={<LoginPlantilla />} />
      <Route path="/cards_templates" element={<TemplatesCards />} />
      <Route path="/embedded/chatbot/:token" element={<Embedded />} />
    </Routes>
    // </Router>
  );
};

export default AppRoutes;
