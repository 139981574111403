import React, { useEffect, useState, useRef } from 'react';
import { Table, Avatar, Progress, Tooltip, Button, Input, Space, DatePicker, InputRef, TableColumnType, Modal, message, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RobotOutlined, SettingOutlined, CommentOutlined, SearchOutlined, ShoppingCartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { fetchNuestrosChatbots } from '../../../redux/actions/chatBots/Chat/Chat';
import { SelectBotReducer, UpdateChatSeccionadoHome, SetConversacionSeleccionadaHome, GetChatbotByToken } from '../../../redux/actions/home/Home';
import moment from 'moment';
import { FilterDropdownProps } from 'antd/es/table/interface';
import ChatComponent from '../../../components/chat/ChatComponent';
import AvatarRobot from '../../../assets/img/avatars/robot.avif';
import { useNavigate } from 'react-router-dom';
import { AddChatbotToMarketReducer, GetMarketChatbotsReducer, DeleteFromMarketReducer, UpdateBlockStatusReducer } from '../../../redux/actions/chatBots/OurChatbots/OurChatbots';
interface ChatbotType {
  id: string;
  nombre: string;
  descripcion: string;
  creadoEn: string;
  detallesUsuario: {
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
  };
  logo?: string;
  token: string;
  porcentaje_aceptacion?: number;
  conversacionId?: string
}

interface FormValues {
  fontSize: string;
  fontFamily: string;
  nombreChat: string;
  inputPlaceholder: string;
  logo: File | null;
  icono: File | null;
  iconoRuta: string;
  logoRuta: string;
  colorHeader: string;
  colorTitulo: string;
  colorFondoEmisor: string;
  colorFondoReceptor: string;
  retrasoRespuesta: number;
  colorEmisor: string;
  colorReceptor: string;
  estado: boolean;
  colorEstadoActivo: string;
  colorEstadoInactivo: string;
  estadoHorario: boolean;
}

type DataIndex = keyof ChatbotType;

const TableOurChats: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChatbot, setSelectedChatbot] = useState<any>(null);
  const [isMarketModalVisible, setIsMarketModalVisible] = useState(false);
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [selectedTokenForMarket, setSelectedTokenForMarket] = useState<string>('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedMarketChatbot, setSelectedMarketChatbot] = useState<any>(null);
  const { rex_conversation_chat } = useSelector(({ tabChat }: RootState) => tabChat);
  const { rex_chat_selecccionado, rex_chatsbots } = useSelector(({ home }: RootState) => home);
  const { rex_design_chat, rex_design_status, rex_styles } = useSelector((state: RootState) => state.design);
  const { rex_market_chatbots } = useSelector((state: RootState) => state.ourChatBots);
  const [marketChatbots, setMarketChatbots] = useState<any[]>([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [currentBlockedStatus, setCurrentBlockedStatus] = useState(false);


  const { chatbots_loading, nuestros_chatbots, rex_meta } = useSelector((state: RootState) => ({
    chatbots_loading: state.tabChat?.chatbots_loading || false,
    nuestros_chatbots: state.tabChat?.nuestros_chatbots || [],
    rex_meta: state.tabChat?.rex_meta || {},
  }));

  const [initialValues, setInitialValues] = useState<FormValues>({
    fontSize: '',
    fontFamily: '',
    nombreChat: '',
    inputPlaceholder: '',
    logo: null,
    icono: null,
    iconoRuta: '',
    logoRuta: '',
    colorHeader: '#1677ff',
    colorTitulo: '#1677ff',
    colorFondoEmisor: '#0084ff',
    colorFondoReceptor: '#e5e5ea',
    colorEmisor: '#1677ff',
    colorReceptor: '#1677ff',
    estado: false,
    colorEstadoActivo: '#0BF732',
    colorEstadoInactivo: '#ea3765',
    estadoHorario: false,
    retrasoRespuesta : 50
  });

  useEffect(() => {
    dispatch(fetchNuestrosChatbots());
  }, []);

  useEffect(() => {
    dispatch(GetMarketChatbotsReducer());
  }, []);

  useEffect(() => {
    if (rex_market_chatbots) {
      setMarketChatbots(rex_market_chatbots);
    }
  }, [rex_market_chatbots]);


  const showModal = (chatbot: ChatbotType) => {
    setSelectedChatbot(chatbot);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current, pageSize } = pagination;
    const sortColumn = sorter.field || 'id';
    let sortOrder = 'asc';
    if (sorter.order === 'ascend') {
      sortOrder = 'asc';
    } else if (sorter.order === 'descend') {
      sortOrder = 'desc';
    }

    dispatch(fetchNuestrosChatbots(current, pageSize, sortColumn, sortOrder, filters));
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc?.[part], obj);
  };

  const getColumnSearchProps = (dataIndex: DataIndex | string[]): TableColumnType<ChatbotType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0] as string);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setSearchText(selectedKeys[0] as string);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = Array.isArray(dataIndex)
        ? getNestedValue(record, dataIndex.join('.'))
        : getNestedValue(record, dataIndex as string);
      return recordValue?.toString().toLowerCase().includes(String(value).toLowerCase());
    },
  });

  const getDateRangeSearchProps = (): TableColumnType<ChatbotType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <DatePicker.RangePicker
          onChange={(dates, dateStrings) => {
            setSelectedKeys(dateStrings.length === 2 ? dateStrings : []);
          }}
          format="YYYY-MM-DD HH:mm"
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              const [startDate, endDate] = selectedKeys as string[];
              const newFilters = { ...filters, creadoEnFrom: startDate, creadoEnTo: endDate };
              setFilters(newFilters);
              dispatch(fetchNuestrosChatbots(rex_meta.page, rex_meta.limit, 'nombre', 'desc', newFilters));
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
  });

  const showMarketModal = (token: string) => {
    const marketEntry = isInMarketplace(token);

    if (marketEntry) {
      setSelectedMarketChatbot({
        marketId: marketEntry.marketId,
        chatbotId: marketEntry.chatbotId,
        token: marketEntry.token,
        bloqueado: marketEntry.bloqueado
      });
      setIsDeleteModalVisible(true);
      return;
    }
    setSelectedTokenForMarket(token);
    setIsMarketModalVisible(true);
  };

  const handleMarketModalCancel = () => {
    setIsMarketModalVisible(false);
    setIsBloqueado(false);
    setSelectedTokenForMarket('');
  };

  const handleAddToMarket = async () => {
    try {
      if (!selectedTokenForMarket) {
        message.error('Token no válido');
        return;
      }

      await dispatch(AddChatbotToMarketReducer({
        token: selectedTokenForMarket,
        bloqueado: isBloqueado
      }));

      message.success('Chatbot agregado al marketplace exitosamente');
      setIsMarketModalVisible(false);
      setIsBloqueado(false);
      setSelectedTokenForMarket('');
      dispatch(GetMarketChatbotsReducer());

    } catch (error) {
      message.error('Error al agregar el chatbot al marketplace');
    }
  };

  const isInMarketplace = (token: string) => {
    const marketChatbot = marketChatbots.find(mc => mc.token === token);
    if (marketChatbot) {
      return {
        marketId: marketChatbot.id,
        chatbotId: marketChatbot.chatbotId,
        token: marketChatbot.token,
        bloqueado: marketChatbot.bloqueado
      };
    }
    return null;
  };

  const handleDeleteFromMarket = async () => {
    try {
      if (!selectedMarketChatbot) {
        message.error('No se pudo identificar el registro del marketplace');
        return;
      }
      const marketId = selectedMarketChatbot.marketId;
      await dispatch(DeleteFromMarketReducer(marketId));
      message.success('Chatbot eliminado del marketplace exitosamente');
      setIsDeleteModalVisible(false);
      dispatch(GetMarketChatbotsReducer());
    } catch (error) {
      console.error('Error removing from market:', error);
      message.error('Error al eliminar el chatbot del marketplace');
    }
  };

  const handleUpdateBlockStatus = async (marketId: number, bloqueado: boolean) => {
    try {
      await dispatch(UpdateBlockStatusReducer(marketId, bloqueado));
      message.success('Estado actualizado exitosamente');
      setIsEditModalVisible(false);
      dispatch(GetMarketChatbotsReducer());
    } catch (error) {
      message.error('Error al actualizar el estado');
    }
  };


  const columns = [
    {
      title: 'Item',
      key: 'item',
      render: (_: any, __: any, index: number) => (rex_meta.page - 1) * rex_meta.limit + index + 1,
      width: 80,
    },
    {
      title: <span style={{ fontSize: '11px', fontWeight: "bold" }}>Chatbot</span>,
      key: 'nombre',
      dataIndex: 'nombre',
      sorter: true,
      ...getColumnSearchProps('nombre'),
      render: (_: any, record: ChatbotType) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record.logo && record.logo !== 'http://url' ? record.logo : AvatarRobot}
            style={{ marginRight: 8 }}
          />
          <span style={{ fontSize: '11px' }}>{record.nombre}</span>
        </div>
      ),
    },
    {
      title: <span style={{ fontSize: '11px', fontWeight: "bold" }}>Creador</span>,
      dataIndex: ['detallesUsuario', 'nombre'],
      sorter: true,
      ...getColumnSearchProps(['detallesUsuario', 'nombre']),
      render: (_: any, record: ChatbotType) => (
        <span style={{ fontSize: '10px' }}>
          {`${record.detallesUsuario.nombre} ${record.detallesUsuario.apellido_paterno} ${record.detallesUsuario.apellido_materno}`}
        </span>
      ),
    },
    {
      title: '% de aceptación',
      key: 'porcentaje_aceptacion',
      render: (_: any, record: ChatbotType) => (
        <Progress percent={record.porcentaje_aceptacion || 0} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
      ),
    },
    {
      title: <span style={{ fontSize: '11px', fontWeight: "bold" }}>Descripción</span>,
      dataIndex: 'descripcion',
      sorter: true,
      ellipsis: true,
      ...getColumnSearchProps('descripcion'),
      render: (descripcion: string) => (
        <Tooltip title={descripcion}>
          <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {descripcion}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <span style={{ fontSize: '11px', fontWeight: "bold" }}>Fecha Creación</span>,
      dataIndex: 'creadoEn',
      sorter: true,
      ...getDateRangeSearchProps(),
      render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_: any, record: ChatbotType) => {
        const handleSettingsClick = async () => {
          try {
            if (!rex_chatsbots.some((bot: any) => bot.token === record.token)) {
              await dispatch(GetChatbotByToken(record.token));
            }
            navigate(`/chats/${record.token}/edit`);
          } catch (error) {
            console.error('Error al obtener el chatbot:', error);
          }
        };

        const inMarketplace = isInMarketplace(record.token);

        return (
          <span>
            <Button
              icon={<RobotOutlined />}
              onClick={() => showModal(record)}
            />
            <Button
              icon={<SettingOutlined />}
              onClick={handleSettingsClick}
            />
            <Button icon={<CommentOutlined />} />
            <Button
              icon={<ShoppingCartOutlined />}
              onClick={() => showMarketModal(record.token)}
              style={{
                color: inMarketplace ? 'white' : undefined,
                borderColor: inMarketplace ? '#D9D9D9' : undefined,
                backgroundColor: inMarketplace ? 'red' : undefined
              }}
              title={inMarketplace ? 'Ya está en el marketplace' : 'Agregar al marketplace'}
            />
          </span>
        );
      }
    }
  ];

  const ellipsisRenderer = (content: React.ReactNode) => {
    if (typeof content === 'string') {
      return (
        <Tooltip title={content} placement="topLeft">
          <div className="cell-ellipsis">{content}</div>
        </Tooltip>
      );
    }
    if (content && typeof content === 'object' && 'props' in content) {
      return content;
    }
    return content;
  };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1300);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const enhancedColumns = columns.map((column) => {
    const excludeKeys = ['permisos', 'acciones'];
    if (excludeKeys.includes(String(column.key))) {
      return column;
    }

    return {
      ...column,
      render: (text: any, record: any, index: number) => {
        const rawContent = column.render ? column.render(text, record, index) : text;
        const content: React.ReactNode =
          rawContent && typeof rawContent === 'object' && 'children' in rawContent
            ? rawContent.children
            : rawContent;
        return ellipsisRenderer(content);
      },
      ellipsis: { showTitle: false },
    };
  });

  useEffect(() => {
    if (rex_design_chat) {
      setInitialValues({
        fontSize: rex_design_chat?.tamanoLetra || '',
        fontFamily: rex_design_chat?.fuente || '10',
        nombreChat: rex_design_chat?.nombre || '',
        inputPlaceholder: rex_design_chat?.placeholder || '',
        logo: null,
        icono: null,
        iconoRuta: rex_design_chat?.iconoEnvio || '',
        logoRuta: rex_design_chat?.logo || '',
        colorHeader: rex_design_chat?.colorCabecera || '#1677ff',
        colorTitulo: rex_design_chat?.colorTitulo || '#1677ff',
        colorEmisor: rex_design_chat?.colorTextoEmisor || '#1677ff',
        colorReceptor: rex_design_chat?.colorTextoReceptor || '#1677ff',
        colorFondoEmisor: rex_design_chat?.colorFondoTextoEmisor || '#0084ff',
        colorFondoReceptor: rex_design_chat?.colorFondoTextoReceptor || '#e5e5ea',
        estado: rex_design_chat?.estado || false,
        colorEstadoActivo: rex_design_chat?.colorEstadoActivo || '#0BF732',
        colorEstadoInactivo: rex_design_chat?.colorEstadoInactivo || '#ea3765',
        estadoHorario: rex_design_chat?.estadoHorario || false,
        retrasoRespuesta: rex_design_chat?.retrasoRespuesta || 50,
      });
    }
  }, [rex_design_chat, rex_styles]);

  return (
    <div>
      <Table
        className="custom-table2"
        columns={enhancedColumns}
        dataSource={nuestros_chatbots}
        loading={chatbots_loading}
        pagination={{
          current: rex_meta.page || 1,
          pageSize: rex_meta.limit || 10,
          total: rex_meta.total || 0,
        }}
        onChange={handleTableChange}
        rowKey="id"
        scroll={isSmallScreen ? { x: 'max-content' } : undefined}
      />
      <Modal
        title="InfoBot"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={500}
      >
        {selectedChatbot && (
          <ChatComponent
            idConversation={
              rex_chat_selecccionado
                ? rex_chat_selecccionado.toString() // Convertir a string
                : '0'
            }
            data={rex_conversation_chat}
            editBubble={false}
            nombreChat={selectedChatbot?.nombre || ''}
            resetChat={true}
            fontSize={selectedChatbot?.tamanoLetra || '14px'}
            fontFamily={selectedChatbot?.fuente || 'Arial'}
            inputPlaceholder={selectedChatbot?.placeholder || 'Escribe un mensaje'}
            iconoEnviarChat={selectedChatbot?.iconoEnvio}
            logoChat={selectedChatbot?.logo}
            estadoChat={selectedChatbot?.estado || false}
            retrasoRespuesta={selectedChatbot?.retrasoRespuesta || false}
            coloresStyle={{
              colorCabecera: selectedChatbot?.colorCabecera || '#1677ff',
              colorTextoEmisor: selectedChatbot?.colorTextoEmisor || '#1677ff',
              colorTextoReceptor: selectedChatbot?.colorTextoReceptor || '#1677ff',
              colorFondoTextoEmisor: selectedChatbot?.colorFondoTextoEmisor || '#0084ff',
              colorFondoTextoReceptor: selectedChatbot?.colorFondoTextoReceptor || '#e5e5ea',
              colorTitulo: selectedChatbot?.colorTitulo || '#FFFFFF',
              colorEstadoActivo: selectedChatbot?.colorEstadoActivo || '#0BF732',
              colorEstadoInactivo: selectedChatbot?.colorEstadoInactivo || '#ea3765',
            }}
            estadoHorario={selectedChatbot?.estadoHorario || false}
          />
        )}
      </Modal>
      <Modal
        title="Agregar al Marketplace"
        open={isMarketModalVisible}
        onCancel={handleMarketModalCancel}
        footer={[
          <Button key="cancel" onClick={handleMarketModalCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddToMarket}
          >
            Agregar
          </Button>
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <p>¿Estás seguro de que deseas agregar este chatbot al marketplace?</p>
          <div style={{ marginTop: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Switch
                checked={isBloqueado}
                onChange={(checked) => setIsBloqueado(checked)}
              />
              <span>Agregar como bloqueado</span>
            </div>
            {isBloqueado && (
              <p style={{ marginTop: '8px', color: '#888', fontSize: '12px' }}>
                Si el chatbot está bloqueado, solo se mostrará información limitada en el marketplace.
              </p>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Chatbot en Marketplace"
        open={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        footer={null}
      >
        <div style={{ marginBottom: '20px' }}>
          <p>Este chatbot ya está agregado al marketplace</p>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '32px',
            marginTop: '24px'
          }}>
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '8px' }}>Eliminar</p>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined style={{ fontSize: '24px' }} />}
                onClick={() => {
                  setIsDeleteModalVisible(false);
                  setIsConfirmDeleteModalVisible(true);
                }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '8px' }}>Editar</p>
              <Button
                type="text"
                icon={<EditOutlined style={{ fontSize: '24px', color: '#1677ff' }} />}
                onClick={() => {
                  setIsDeleteModalVisible(false);
                  setCurrentBlockedStatus(selectedMarketChatbot?.bloqueado || false);
                  setIsEditModalVisible(true);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Confirmar eliminación"
        open={isConfirmDeleteModalVisible}
        onCancel={() => setIsConfirmDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsConfirmDeleteModalVisible(false)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={handleDeleteFromMarket}
          >
            Eliminar
          </Button>
        ]}
      >
        <p>¿Estás seguro que desea quitarlo del marketplace?</p>
      </Modal>

      <Modal
        title="Editar estado del chatbot"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (selectedMarketChatbot?.marketId) {
                handleUpdateBlockStatus(
                  selectedMarketChatbot.marketId,
                  currentBlockedStatus
                );
              }
            }}
          >
            Guardar
          </Button>
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <p style={{ marginBottom: '16px' }}>Puede editar el estado del chat:</p>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Switch
              checked={currentBlockedStatus}
              onChange={(checked) => setCurrentBlockedStatus(checked)}
            />
            <span>{currentBlockedStatus ? 'Bloqueado' : 'Desbloqueado'}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TableOurChats;