import {
  FETCH_LECTURA_REQUEST,
  FETCH_LECTURA_SUCCESS,
  FETCH_LECTURA_FAILURE,
  LecturaWebActions,
  FETCH_GET_LECTURAS_REQUEST,
  DELETE_LECTURA_REQUEST,
  UPDATE_LECTURA_REQUEST,
  FETCH_GET_LECTURAS_SUCCESS,
  DELETE_LECTURA_SUCCESS,
  UPDATE_LECTURA_SUCCESS,
  DELETE_LECTURA_FAILURE,
  UPDATE_LECTURA_FAILURE,
  FETCH_GET_LECTURAS_FAILURE,
  SET_CURRENT_PAGE,
  UPDATE_LECTURA_CONTENT_FAILURE,
  UPDATE_LECTURA_CONTENT_SUCCESS,
  UPDATE_LECTURA_CONTENT_REQUEST,
  UPDATE_SUB_URL_CONTENT_REQUEST,
  UPDATE_SUB_URL_CONTENT_SUCCESS,
  UPDATE_SUB_URL_CONTENT_FAILURE,
  DELETE_SUBURL_REQUEST,
  DELETE_SUBURL_SUCCESS,
  DELETE_SUBURL_FAILURE,
  UPDATE_SUBURL_MANUAL_CONTENT_FAILURE,
  UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS,
  UPDATE_LECTURA_MANUAL_CONTENT_REQUEST,
  UPDATE_SUBURL_MANUAL_CONTENT_REQUEST,
  UPDATE_LECTURA_MANUAL_CONTENT_FAILURE,
  UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS,
  FETCH_AIRTABLE_FAILURE,
  FETCH_AIRTABLE_CONFIGS_FAILURE,
  FETCH_AIRTABLE_SUCCESS,
  FETCH_AIRTABLE_CONFIGS_SUCCESS,
  FETCH_AIRTABLE_CONFIGS_REQUEST,
  FETCH_AIRTABLE_REQUEST,
  SET_CURRENT_AIRTABLE_PAGE,
  ADD_SUBURL_FAILURE,
  ADD_SUBURL_SUCCESS,
  ADD_SUBURL_REQUEST,
  UPDATE_USER_AIRTABLES_FAILURE,
  UPDATE_USER_AIRTABLES_SUCCESS,
  UPDATE_USER_AIRTABLES_REQUEST,
  DELETE_AIRTABLE_TABLE_REQUEST,
  DELETE_AIRTABLE_TABLE_SUCCESS,
  DELETE_AIRTABLE_TABLE_FAILURE,
  UPDATE_AIRTABLE_BASE_REQUEST,
  UPDATE_AIRTABLE_BASE_SUCCESS,
  UPDATE_AIRTABLE_BASE_FAILURE,
  UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST,
  UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS,
  UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE,
  EDIT_AIRTABLE_TABLE_CONTENT_REQUEST,
  EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS,
  EDIT_AIRTABLE_TABLE_CONTENT_FAILURE,
  UPDATE_FULL_AIRTABLE_BASE_REQUEST,
  UPDATE_FULL_AIRTABLE_BASE_SUCCESS,
  UPDATE_FULL_AIRTABLE_BASE_FAILURE,
  DELETE_AIRTABLE_BASE_FAILURE,
  DELETE_AIRTABLE_BASE_SUCCESS,
  DELETE_AIRTABLE_BASE_REQUEST,
  ADD_AIRTABLE_TABLE_FAILURE,
  ADD_AIRTABLE_TABLE_SUCCESS,
  ADD_AIRTABLE_TABLE_REQUEST
} from '../../../constantes/lecturas/LecturaWeb';


interface AirtableTable {
  id: number;
  table_id: string;
  table_nombre: string;
  contenido: string;
  leyo: boolean;
  created_at: string;
  updated_at: string;
}

interface AirtableBase {
  id: number;
  base_id: string;
  base_nombre: string;
  usuario_id: number;
  chatbot_id: number;
  created_at: string;
  updated_at: string;
  tables: AirtableTable[];
  tables_count: number; 
}

interface LecturaWebState {
  rex_loading: boolean;
  rex_lectura: any;
  rex_lecturas: any[];
  rex_error: string | null;
  rex_total: number;
  rex_page: number;
  rex_limit: number;
  airtable_bases: AirtableBase[];
  airtable_data: any | null;
  airtable_loading: boolean;
  airtable_total: number;
  airtable_page: number;
  airtable_limit: number;
  airtable_error: string | null;
  user_airtables_loading: boolean;
  user_airtables_error: string | null;
}

const INIT_STATE: LecturaWebState = {
  rex_loading: false,
  rex_lectura: null,
  rex_lecturas: [],
  rex_error: null,
  rex_total: 0,
  rex_page: 0,
  rex_limit: 10,
  airtable_bases: [],
  airtable_data: null,
  airtable_total: 0,
  airtable_page: 1,
  airtable_limit: 10,
  airtable_loading: false,
  airtable_error: null,
  user_airtables_loading: false,
  user_airtables_error: null,
};

export default (state = INIT_STATE, action: LecturaWebActions): LecturaWebState => {
  switch (action.type) {
    case FETCH_LECTURA_REQUEST:
    case FETCH_GET_LECTURAS_REQUEST:
    case DELETE_LECTURA_REQUEST:
    case UPDATE_LECTURA_REQUEST:
      return { ...state, rex_loading: true };

    case FETCH_LECTURA_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lectura: action.payload,
        rex_lecturas: [...state.rex_lecturas, action.payload.data]
      };

    case FETCH_GET_LECTURAS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: action.payload.data.lecturas,
        rex_total: action.payload.data.total,
        rex_page: action.payload.data.page,
        rex_limit: action.payload.data.limit
      };

    case DELETE_LECTURA_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.filter(lectura => lectura.id !== action.payload)
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        rex_page: action.payload
      };
    case UPDATE_LECTURA_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map(lectura => {
          if (lectura.id === action.payload.data.id) {
            return {
              ...lectura,
              ...action.payload.data
            };
          }
          return lectura;
        })
      };

    case UPDATE_LECTURA_CONTENT_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };

    case UPDATE_LECTURA_CONTENT_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map(lectura => {
          if (lectura.id === action.payload.data.id) {
            return {
              ...lectura,
              contenido: action.payload.data.contenido,
              leyo: action.payload.data.leyo,
              updated_at: action.payload.data.updated_at
            };
          }
          return lectura;
        })
      };

    case UPDATE_LECTURA_CONTENT_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };
    case UPDATE_SUB_URL_CONTENT_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };
    case UPDATE_SUB_URL_CONTENT_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map((lectura: any) =>
          lectura.id === action.payload.lecturaId
            ? {
              ...lectura,
              sub_urls: lectura.sub_urls.map((subUrl: any) =>
                subUrl.id === action.payload.subUrlId
                  ? { ...subUrl, ...action.payload.data }
                  : subUrl
              )
            }
            : lectura
        )
      };
    case UPDATE_SUB_URL_CONTENT_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };

    case DELETE_SUBURL_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };
    case DELETE_SUBURL_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map((lectura: any) =>
          lectura.id === action.payload.lecturaId
            ? {
              ...lectura,
              sub_urls: lectura.sub_urls.filter(
                (subUrl: any) => subUrl.id !== action.payload.subUrlId
              )
            }
            : lectura
        )
      };
    case DELETE_SUBURL_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };

    case UPDATE_LECTURA_MANUAL_CONTENT_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };

    case UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map(lectura => {
          if (lectura.id === action.payload.id) {
            return {
              ...lectura,
              contenido: action.payload.data.contenido,
              leyo: action.payload.data.leyo,
              updated_at: action.payload.data.updated_at
            };
          }
          return lectura;
        })
      };

    case UPDATE_LECTURA_MANUAL_CONTENT_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };

    case UPDATE_SUBURL_MANUAL_CONTENT_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };

    case UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map((lectura: any) =>
          lectura.id === action.payload.lecturaId
            ? {
              ...lectura,
              sub_urls: lectura.sub_urls.map((subUrl: any) =>
                subUrl.id === action.payload.subUrlId
                  ? {
                    ...subUrl,
                    contenido: action.payload.data.contenido,
                    leyo: action.payload.data.leyo,
                    updated_at: action.payload.data.updated_at
                  }
                  : subUrl
              )
            }
            : lectura
        )
      };

    case UPDATE_SUBURL_MANUAL_CONTENT_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };

    case ADD_SUBURL_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };

    case ADD_SUBURL_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_lecturas: state.rex_lecturas.map((lectura: any) =>
          lectura.id === action.payload.lecturaId
            ? {
              ...lectura,
              sub_urls: [...lectura.sub_urls, action.payload.subUrl]
            }
            : lectura
        )
      };

    case ADD_SUBURL_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };
      case FETCH_LECTURA_FAILURE:
        case FETCH_GET_LECTURAS_FAILURE:
        case DELETE_LECTURA_FAILURE:
          case UPDATE_LECTURA_FAILURE:
            return { ...state, rex_loading: false, rex_error: action.payload };
      
    
      case SET_CURRENT_AIRTABLE_PAGE:
        return {
          ...state,
          rex_page: action.payload
        };
  
        case FETCH_AIRTABLE_REQUEST:
          case FETCH_AIRTABLE_CONFIGS_REQUEST:
          case DELETE_AIRTABLE_BASE_REQUEST:
          case DELETE_AIRTABLE_TABLE_REQUEST:
          case UPDATE_AIRTABLE_BASE_REQUEST:
          case UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST:
          case EDIT_AIRTABLE_TABLE_CONTENT_REQUEST:
          case UPDATE_FULL_AIRTABLE_BASE_REQUEST:
            return {
              ...state,
              airtable_loading: true,
              airtable_error: null
            };
      
          // Éxito al obtener datos de Airtable
          case FETCH_AIRTABLE_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_data: action.payload.data,
              airtable_error: null
            };
      
          // Éxito al obtener configuraciones
          case FETCH_AIRTABLE_CONFIGS_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_bases: action.payload.data[0].bases,
              airtable_total: action.payload.data[0].total,
              airtable_page: action.payload.data[0].page,
              airtable_limit: action.payload.data[0].limit,
              airtable_error: null
            };
      
          // Éxito al eliminar base
          case DELETE_AIRTABLE_BASE_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_bases: state.airtable_bases.filter(
                base => base.id !== action.payload
              ),
              airtable_error: null
            };
      
          // Éxito al eliminar tabla
          case DELETE_AIRTABLE_TABLE_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_bases: state.airtable_bases.map(base => {
                if (base.id === action.payload.baseId) {
                  return {
                    ...base,
                    tables: base.tables.filter(table => table.id !== action.payload.tableId)
                  };
                }
                return base;
              }),
              airtable_error: null
            };
      
          // Éxito al actualizar base
          case UPDATE_AIRTABLE_BASE_SUCCESS:
          case UPDATE_FULL_AIRTABLE_BASE_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_bases: state.airtable_bases.map(base => {
                if (base.id === action.payload.data.id) {
                  return {
                    ...base,
                    ...action.payload.data
                  };
                }
                return base;
              }),
              airtable_error: null
            };
      
          // Éxito al actualizar contenido de tabla
          case UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS:
          case EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS:
            return {
              ...state,
              airtable_loading: false,
              airtable_bases: state.airtable_bases.map(base => {
                if (base.id === action.payload.baseId) {
                  return {
                    ...base,
                    tables: base.tables.map(table => {
                      if (table.id === action.payload.tableId) {
                        return {
                          ...table,
                          contenido: action.payload.data.contenido,
                          leyo: action.payload.data.leyo,
                          updated_at: action.payload.data.updated_at
                        };
                      }
                      return table;
                    })
                  };
                }
                return base;
              }),
              airtable_error: null
            };
      
          // Casos de error
          case FETCH_AIRTABLE_FAILURE:
          case FETCH_AIRTABLE_CONFIGS_FAILURE:
          case DELETE_AIRTABLE_BASE_FAILURE:
          case DELETE_AIRTABLE_TABLE_FAILURE:
          case UPDATE_AIRTABLE_BASE_FAILURE:
          case UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE:
          case EDIT_AIRTABLE_TABLE_CONTENT_FAILURE:
          case UPDATE_FULL_AIRTABLE_BASE_FAILURE:
            return {
              ...state,
              airtable_loading: false,
              airtable_error: action.payload
            };
      
          case UPDATE_USER_AIRTABLES_REQUEST:
            return {
              ...state,
              user_airtables_loading: true,
              user_airtables_error: null
            };
      
          case UPDATE_USER_AIRTABLES_SUCCESS:
            return {
              ...state,
              user_airtables_loading: false,
              user_airtables_error: null
            };
      
          case UPDATE_USER_AIRTABLES_FAILURE:
            return {
              ...state,
              user_airtables_loading: false,
              user_airtables_error: action.payload
            };

            case ADD_AIRTABLE_TABLE_REQUEST:
              return {
                  ...state,
                  airtable_loading: true,
                  airtable_error: null
              };
          
              case ADD_AIRTABLE_TABLE_SUCCESS:
                return {
                    ...state,
                    airtable_loading: false,
                    airtable_error: null,
                    airtable_bases: state.airtable_bases.map(base => 
                        base.id === action.payload.baseId
                            ? {
                                ...base,
                                tables: [...base.tables, action.payload.data.table],
                                tables_count: base.tables ? base.tables.length + 1 : 1
                            }
                            : base
                    )
                };
          
          case ADD_AIRTABLE_TABLE_FAILURE:
              return {
                  ...state,
                  airtable_loading: false,
                  airtable_error: action.payload
              };
      
          default:
            return state;
        }
      };