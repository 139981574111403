import React from 'react';
import { Button, Space } from 'antd';

interface CustomButtonProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  onClick,
  leftContent,
  rightContent,
  className = ''
}) => {

  return (
    <Button 
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      whiteSpace: 'normal',
      lineHeight: '1.5',
      padding: '10px',
      gap: '8px',
      minHeight: 'fit-content',
      marginBottom: '24px'
  }}
      onClick={onClick}
    >
      {leftContent && <Space>{leftContent}</Space>}
      {children}
      {rightContent && <Space>{rightContent}</Space>}
    </Button>
  );
};

export default CustomButton;