import React, { useEffect, useRef, useState } from 'react';
import { Input, Avatar, Tooltip, Button } from 'antd';
import { CheckCircleTwoTone, SendOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import ChatBubble from './ChatBubble';
import { AppDispatch, RootState } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CreateConversationReducer, CreateMessageTrainReducer, GetConversationReducer, ResetConversationReducer, ResetConversationSupportReducer } from '../../redux/actions/chatBots/Chat/Chat';
import { ReloadOutlined } from '@ant-design/icons';
import { CreateConversationSupportReducer } from '../../redux/actions/chatBots/Chat/ChatSupport';
import { GetConversacionReducer } from '../../redux/actions/chatBots/Entrenar/ChatBots';
import { GetOneDesingChatReducer, GetOneDesingChatSupportReducer } from '../../redux/actions/chatBots/Chat/ChatDesing';
import { fetchChatbotHorarios } from '../../redux/actions/Horario/ChatBotHorario';
import { SetConversacionSeleccionadaHome, UpdateChatSeccionadoHome } from '../../redux/actions/home/Home';
import { useParams } from 'react-router-dom';
import { CreateConversationEmbeddedReducer } from '../../redux/actions/embedded/Embedded';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';
import SpotlightTour from '../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../components/pages/ShadowComponent/TourContext';
import AvatarRobot from '../../assets/img/avatars/robot.avif';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');
interface Message {
  id: number;
  text: string;
  sender: 'emisor' | 'receptor';
}
interface ChatProps {
  idConversation?: string;
  editBubble?: boolean;
  modeBot?: boolean;
  iaActivate?: boolean;
  data?: Array<any>;
  fontSize?: string;
  fontFamily?: string;
  nombreChat?: string;
  inputPlaceholder?: string;
  logoChat?: string;
  iconoEnviarChat?: string;
  logoPreview?: string | null;
  iconoPreview?: string | null;
  resetChat?: boolean;
  supportChat?: boolean;
  embedded?: boolean;
  estadoChat?: boolean;
  coloresStyle?: {
    colorCabecera?: string;
    colorFondoTextoEmisor?: string;
    colorFondoTextoReceptor?: string;
    colorTextoEmisor?: string;
    colorTextoReceptor?: string;
    colorTitulo?: string;
    colorEstadoActivo?: string;
    colorEstadoInactivo?: string;
  }
  typingStatus?: boolean;
  idChatBot?: number;
  tokenChatbot?: string;
  disabledInput?: boolean;
  estadoHorario?: boolean;
  isTrainingView?: boolean;
  retrasoRespuesta?: number;
}

const ChatComponent: React.FC<ChatProps> = ({
  idConversation = '',
  editBubble = true,
  modeBot = false,
  iaActivate = true,
  data,
  fontSize = '16px',
  fontFamily = 'Arial',
  nombreChat = 'Nombre del chat',
  inputPlaceholder = 'Tipear un mensaje',
  logoChat = null,
  iconoEnviarChat = null,
  resetChat = true,
  supportChat = false,
  embedded = false,
  estadoChat,
  logoPreview = "",
  iconoPreview = "",
  coloresStyle = {
    colorCabecera: '#1677ff',
    colorFondoTextoEmisor: '#0084ff',
    colorFondoTextoReceptor: '#e5e5ea',
    colorTextoEmisor: '#FFFFFF',
    colorTextoReceptor: '#000000',
    colorTitulo: '#FFFFFF',
    colorEstadoActivo: '#0BF732',
    colorEstadoInactivo: '#ea3765'
  },
  typingStatus = false,
  idChatBot,
  tokenChatbot = "",
  retrasoRespuesta = 50,
  disabledInput = false,
  estadoHorario,
  isTrainingView = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { token, chatToken } = useParams();
  const [messages, setMessages] = useState<Message[]>([]); // Inicializa con un array vacío.

  const [displayedText, setDisplayedText] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isChatbotActive, setIsChatbotActive] = useState<boolean>(false);
  const { rex_user_auth } = useSelector(({ auth }: any) => auth);
  const { rex_chatsbots, rex_token_chat } = useSelector(({ home }: any) => home);
  const { rex_conversation_chat, rex_conversation_chat_support } = useSelector((state: RootState) => state.tabChat);
  const { rex_chat_selecccionado } = useSelector(({ home }: RootState) => home);
  const selectedChatId = rex_chat_selecccionado
  const selectedChatbot = rex_chatsbots.find((bot: any) => bot.id === selectedChatId);
  const [newMessage, setNewMessage] = useState<string>('');
  const [sender, setSender] = useState<'emisor' | 'receptor'>('emisor');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [loading, setMessagesLoading] = useState<boolean>(false);
  const scrollToBottom = () => {
    const scrollMessage = messagesEndRef.current
    scrollMessage?.scroll({
      top: scrollMessage?.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleDeleteMessage = (idMessage: number) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== idMessage));
  };

  useEffect(() => {
    if (selectedChatbot && rex_user_auth?.timezone) {
      updateChatbotStatus();
    }
  }, [selectedChatbot, rex_user_auth]);

  useEffect(() => {

    if (iaActivate) {
      const idConversation = localStorage.getItem(rex_token_chat || chatToken)
      if (data?.length && idConversation) {
        setMessages(data);
      } else {
        if(!embedded){
          setMessages([]);
        }
      }
    }

  }, [data]);

  useEffect(() => {
    const idConversation = localStorage.getItem(rex_token_chat)
    if (idConversation && !embedded) {
      dispatch(GetConversationReducer(idConversation))
    } else {
      if(embedded && rex_conversation_chat_support.length == 0){
        setMessages([])
      }
    }
    dispatch(fetchChatbotHorarios());
  }, [rex_token_chat]);

  useEffect(() => {
    if (supportChat) {
      const idConversation = localStorage.getItem('conversacion_support')
      if (rex_conversation_chat_support?.length > 0 && iaActivate && idConversation) {
        setMessages(rex_conversation_chat_support);
      }
    } else if (embedded) {      
      setMessages(rex_conversation_chat_support);
    } else {
      const idConversation = localStorage.getItem(rex_token_chat)
      if (rex_conversation_chat?.length > 0 && iaActivate && idConversation) {
        setMessages(rex_conversation_chat);
      }
    }
  }, [rex_conversation_chat, rex_conversation_chat_support]);

  useEffect(() => {
    scrollToBottom();
    if (supportChat) {
      dispatch(GetOneDesingChatSupportReducer());
      const idConversation = localStorage.getItem('conversacion_support')
      dispatch(GetConversationReducer(idConversation))
      setIsChatbotActive(true);
    } else {
      dispatch(GetOneDesingChatReducer());
    }

    if (embedded && rex_user_auth) {
      // updateChatbotStatus()
      // const idConversation = localStorage.getItem('embedded_' + tokenChatbot)
      // if (idConversation) {
      //   dispatch(GetConversationReducer(idConversation))
      // }
    }
  }, []);

  const handleSendMessageTest = (messageSend: string) => {

    if (newMessage.trim()) {

      setNewMessage('');
      setMessagesLoading(true);

      const tempMessageId = Date.now();
      const messageEmit: Message = {
        id: tempMessageId,
        text: messageSend,
        sender: sender,
      };

      if (sender == 'receptor') {
        setIsTyping(true);
        setTimeout(() => {
          let currentText = '';
          let index = 0;

          const typingInterval = setInterval(async () => {
            if (index < newMessage.length) {
              currentText += newMessage.charAt(index);
              setDisplayedText(currentText);
              index++;
            } else {
              clearInterval(typingInterval);
              setMessages((prevMessages) => [...prevMessages, messageEmit]);
              setDisplayedText('');
            }
          }, retrasoRespuesta);

          setIsTyping(false);
        }, 1000);
      }else{
        setMessages((prevMessages) => [...prevMessages, messageEmit]);
      }
      setSender(sender === 'emisor' ? 'receptor' : 'emisor');
      scrollToBottom();
    }
  }

  const handleSendMessage = async (messageSend: string) => {

    if (newMessage.trim()) {
      setNewMessage('');
      setMessagesLoading(true);

      const tempMessageId = Date.now();
      const messageEmit: Message = {
        id: tempMessageId,
        text: messageSend,
        sender: 'emisor',
      };

      setMessages((prevMessages) => [...prevMessages, messageEmit]);
      scrollToBottom();

      try {
        if (!isChatbotActive) {
          const unavailableMessage: Message = {
            id: Date.now(),
            text: "El chatbot no está disponible en este horario.",
            sender: 'receptor',
          };
          setMessages((prevMessages) => [...prevMessages, unavailableMessage]);
          return;
        }

        if (modeBot) {
          await dispatch(CreateMessageTrainReducer('emisor', parseInt(idConversation), messageSend));
        }

        setIsTyping(true);

        let response: any;
        if (embedded && token) {
          response = await dispatch(CreateConversationEmbeddedReducer(messageSend, token));
        } else if (supportChat) {
          response = await dispatch(CreateConversationSupportReducer(messageSend, isTrainingView));
        } else {
          response = await dispatch(CreateConversationReducer(messageSend, isTrainingView))
        }

        const botMessage = response?.contenido || 'Respuesta no disponible';
        setIsTyping(false);

        let currentText = '';
        let index = 0;

        const typingInterval = setInterval(async () => {
          if (index < botMessage.length) {
            currentText += botMessage.charAt(index);
            setDisplayedText(currentText);
            index++;
          } else {
            clearInterval(typingInterval);
            const botResponse: Message = {
              id: Date.now(),
              text: botMessage,
              sender: 'receptor',
            };

            setMessages((prevMessages) => [...prevMessages, botResponse]);
            setDisplayedText('');
            scrollToBottom();

            if (modeBot) {
              // Chain promises instead of using await
              dispatch(CreateMessageTrainReducer('receptor', parseInt(idConversation), botMessage))
                .then(() => {
                  if (isTrainingView) {
                    return dispatch(GetConversacionReducer(parseInt(idConversation)));
                  } else {
                    return dispatch(GetConversationReducer());
                  }
                })
                .then((updatedMessages) => {
                  if (isTrainingView && updatedMessages) {
                    setMessages(updatedMessages);
                  } else if (data) {
                    setMessages(data);
                  }
                });
            }
          }
        }, retrasoRespuesta);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== tempMessageId)
        );
      } finally {
        setMessagesLoading(false);
      }
    }
  };

  const updateChatbotStatus = () => {
    
    const timezone = rex_user_auth.timezone;
    const currentDay = dayjs().tz(timezone).format('dddd').toLowerCase();
    const currentTime = dayjs().tz(timezone).format('HH:mm');
    const daySchedule = selectedChatbot?.horariosActividad?.find((schedule: any) => schedule.dia === currentDay);
    if (daySchedule) {
      const today = new Date().toISOString().split('T')[0];
      const [startTime, endTime] = daySchedule.horario.split(' - ');
      const current = new Date(`${today}T${currentTime}:00`);
      const start = new Date(`${today}T${startTime}:00`);
      const end = new Date(`${today}T${endTime}:00`);

      if (current >= start && current <= end) {
        setIsChatbotActive(true);
      } else {
        setIsChatbotActive(false);
      }
    } else {
      setIsChatbotActive(false);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const hasImage = logoPreview || logoChat;

  const chatBot = tourData.Chat;
  const { currentView } = useTour();

  return (
    <>
      {currentView === 'chatbot' && <SpotlightTour steps={chatBot} view="chatbot" />}
      <div
        style={{
          height: '500px',
          borderRadius: '25px',
          position: 'relative',
          border: '1px solid #C4C4C4',
          boxShadow: ' 0 0px 8px rgba(12, 12, 12, 0.2)'
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
            background: coloresStyle.colorCabecera,
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            marginBottom: '10px',
            color: 'white',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: '20px',
              background: 'transparent'
            }}
          >
            <Avatar
              size={40}
              src={logoPreview || (logoChat && logoChat !== "http://url" ? logoChat : AvatarRobot)}
              icon={logoChat === "http://url" ? <UserOutlined /> : undefined}
              style={{
                background: '#E6F4FF',
                color: "black",
                borderRadius: '100%',
                marginRight: '10px',
                width: '40px',
                height: '40px',
                alignContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            />

          </div>
          <div
            style={{
              background: 'transparent'
            }}
          >
            <div
              className="chat-title-display"
              style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '1', background: 'transparent', color: coloresStyle.colorTitulo }}
            >
              {nombreChat}
            </div>
            <div
              style={{
                background: 'transparent',
                marginTop: '4px',
                color: ' #0C5257'
              }}
              className='chat-status'
            >

              <CheckCircleTwoTone
                twoToneColor={isChatbotActive ?  coloresStyle.colorEstadoActivo : coloresStyle.colorEstadoInactivo}
              />
              <span></span>
              <span>
                <span
                  style={{
                    marginLeft: '5px',
                    fontWeight: 'bold',
                    color:
                      isChatbotActive ? coloresStyle.colorEstadoActivo : coloresStyle.colorEstadoInactivo
                  }}
                >
                  {isChatbotActive ? 'Activo' : 'Inactivo'}
                </span>
              </span>
            </div>
          </div>
          {
            resetChat ? (
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  background: 'transparent',
                  color: ' #0C5257'
                }}
              >
                <Tooltip
                  title="Nuevo Chat"
                  className='reload-button'
                >
                  <ReloadOutlined
                    style={{
                      fontSize: '20px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (supportChat) {
                        dispatch(ResetConversationSupportReducer())
                      } else {
                        localStorage.removeItem(rex_token_chat)
                      }
                      dispatch(SetConversacionSeleccionadaHome("0"))
                      dispatch(ResetConversationReducer())
                      setMessages([])
                    }}
                  />
                </Tooltip>
              </div>
            ) : null
          }
        </div>

        {/* BODY */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '400px',
            maxHeight: '400px',
            overflowY: 'auto',
            paddingBottom: '60px',
            margin: '0 2px',
            boxSizing: 'border-box',
          }}
          ref={messagesEndRef}
        >
          {/* Renderiza todos los mensajes */}
          {messages.map((message, index) => (
            <div
              key={message.id}
              style={{
                display: 'flex',
                justifyContent: message.sender === 'emisor' ? 'flex-end' : 'flex-start',
                marginBottom: '10px',
              }}
            >
              <ChatBubble
                key={message.id}
                idConversation={parseInt(idConversation)}
                idMessage={message.id}
                message={message.text}
                sender={message.sender}
                fontSize={fontSize}
                fontFamily={fontFamily}
                editBubble={editBubble}
                colorTextoEmisor={coloresStyle.colorTextoEmisor}
                colorTextoReceptor={coloresStyle.colorTextoReceptor}
                colorFondoTextoEmisor={coloresStyle.colorFondoTextoEmisor}
                colorFondoTextoReceptor={coloresStyle.colorFondoTextoReceptor}
                onDeleteMessage={handleDeleteMessage}
              />
            </div>
          ))}

          {/* Efecto de tres puntos escribiendo */}
          {isTyping && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: '10px',
                marginLeft: '10px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '8px 12px',
                maxWidth: '150px',
              }}
            >
              <span className="dot" />
              <span className="dot" style={{ animationDelay: '0.2s' }} />
              <span className="dot" style={{ animationDelay: '0.4s' }} />
              <style>
                {`
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 2px;
            background-color: #999;
            border-radius: 50%;
            animation: bounce 1.5s infinite ease-in-out;
          }

          @keyframes bounce {
            0%, 80%, 100% {
              transform: scale(0);
            }
            40% {
              transform: scale(1);
            }
          }
        `}
              </style>
            </div>
          )}

          {displayedText && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '10px',
              }}
            >
              <ChatBubble
                idConversation={parseInt(idConversation)}
                idMessage={0}
                message={displayedText} // El texto mostrado letra por letra
                sender="receptor"
                fontSize={fontSize}
                fontFamily={fontFamily}
                editBubble={editBubble}
                colorFondoTextoEmisor={coloresStyle.colorFondoTextoEmisor}
                colorFondoTextoReceptor={coloresStyle.colorFondoTextoReceptor}
                colorTextoEmisor={coloresStyle.colorTextoEmisor}
                colorTextoReceptor={coloresStyle.colorTextoReceptor}
                onDeleteMessage={() => { }}
              />
            </div>
          )}
        </div>


        <div
          style={{
            position: 'absolute',
            bottom: '0',
            background: 'white',
            width: '100%',
            height: '60px',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            borderBottomLeftRadius: '25px',
            borderBottomRightRadius: '25px',
          }}
        >
          {modeBot ? (
            <div
              style={{
                background: '#E6F4FF',
                borderRadius: '100%',
                marginRight: '0px',
                marginLeft: '10px',
                width: '40px',
                height: '40px',
                alignContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSender(sender === 'emisor' ? 'receptor' : 'emisor');
              }}
            >
              {sender == 'emisor' ? <UserOutlined /> : <RobotOutlined />}
            </div>
          ) : null}

          <Input
            style={{
              borderRadius: '20px',
              height: '40px',
              marginLeft: '10px',
              marginRight: '20px'
            }}
            className='message-input'
            type="text"
            value={newMessage}
            onChange={(e) => { setNewMessage(e.target.value); }}
            placeholder={inputPlaceholder}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (iaActivate) {
                  handleSendMessage(newMessage)
                } else {
                  handleSendMessageTest(newMessage)
                }
              }
            }}
            suffix={
              <div
                style={{
                  borderRadius: '100%',
                  cursor: 'pointer',
                  display:'flex'
                }}
                onClick={() => {
                  if (iaActivate) {
                    handleSendMessage(newMessage)
                  } else {
                    handleSendMessageTest(newMessage)
                  }
                }}
              >
                {iconoPreview ? (
                  <img
                    src={iconoPreview}
                    alt={iconoPreview}
                    style={{
                      marginTop: "8px",
                      background: "green",
                      borderColor: "black",
                      borderWidth: "2px",
                      width: '30px',
                      height: '30px',
                      borderStyle: "solid",
                      borderRadius: "8px",
                      objectFit: 'cover',

                    }}
                  />
                ) : (iconoEnviarChat == "http://url" ? (<SendOutlined />) : (
                  <img
                    src={iconoEnviarChat || ""}
                    alt={iconoEnviarChat || ""}
                    style={{
                      marginTop: iconoEnviarChat == "http://url" ? "8px" : "0px",
                      background: "green",
                      borderColor: "black",
                      borderWidth: "2px",
                      width: '30px',
                      height: '30px',
                      borderStyle: "solid",
                      borderRadius: "8px",
                      objectFit: 'cover',
                    }}
                  />
                ))}
              </div>
            }
            disabled={disabledInput}
          />
        </div>
      </div >
    </>
  );
};

export default ChatComponent;