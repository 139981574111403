//const API_BASE_URL =  "https://f5ba-2a09-bac5-4f6d-791-00-c1-6f.ngrok-free.app/"

// const API_BASE_URL = 'http://localhost:3005/'

const API_BASE_URL = 'https://dev-back.revolutia.ai/'

export default {
    API_URL : API_BASE_URL,
    API_URL_FRONT : 'http://localhost:3000/',
    API_PUBLIC: API_BASE_URL+'public/',
    API: API_BASE_URL+'protected/',
    basename: '/', 
    produccion : false,
    API_KEY_STRIPE:"pk_test_51Q5qv2K7xHa4ykgCxnJ7oZTWGbpYoX9JsIQPAtS9afLUmbIsdbPBAIrp76xOUmgPS2WPL74897tPzvCcGh6WiCy000iZ3ppFnT"
};