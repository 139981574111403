import React, { useEffect, useState } from 'react';
import { Button, Card, Input, message, Steps, theme } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import StepOne from '../../../../assets/img/integrations/step_one.png'
import StepTwo from '../../../../assets/img/integrations/step_two.png'
import StepThree from '../../../../assets/img/integrations/step_three.png'
import StepFourth from '../../../../assets/img/integrations/step_fourth.png'
import StepFive from '../../../../assets/img/integrations/step_five.png'

import StepSix from '../../../../assets/img/integrations/whatsapp/step_six.png';
import StepSeven from '../../../../assets/img/integrations/whatsapp/step_seven.png';
import StepEch from '../../../../assets/img/integrations/whatsapp/step_ech.png';
import StepNine from '../../../../assets/img/integrations/whatsapp/step_nine.png';
import StepTen from '../../../../assets/img/integrations/whatsapp/step_ten.png';
import StepOnce from '../../../../assets/img/integrations/whatsapp/step_once.png';
import StepDoce from '../../../../assets/img/integrations/whatsapp/step_doce.png';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { GetIntegrationWhatsAppReducer, SendTokenAppWhatsappReducer } from '../../../../redux/actions/integrations/WhatsappIntegration';
// import StepSix from '../../../../assets/img/integrations/whatsapp/step_six.png';
import config from '../../../../config';

const API_URL = config.API_URL;
const API_URL_FRONT = config.API_URL_FRONT;

const WebIntegration = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [datosConexion, setDatosConexion] = useState({
    token: "",
    url: "",
    integrationId: 0
  });
  const [tokenApp, setTokenApp] = useState("")
  const [stepCompleted, setStepCompleted] = useState(0);
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [txtEmbebido, setTxtEmbebido] = useState("");
  const { rex_token_chat, rex_chatsbots } = useSelector((state: RootState) => state.home);

  const generateCodeEmbedded = () => {
    if(!rex_token_chat){
      message.warning('Por favor seleccione un chat primero');
      return;
    }
    const subToken = rex_token_chat.substring(0, 8)
    setTxtEmbebido(
`------------- Código en Framework React -------------\n//Antes del return del componente\nconst [open${subToken},setOpen${subToken}] = useState(false);
\n//Dentro del componente
<div 
  style={{
  width: open${subToken}?'487px':'56px',
  height: open${subToken}?'546px':'56px',
  position: 'fixed',
  zIndex: '10000000000 !important',
  bottom:'40px',
  right:'40px',
}}
>
  <iframe
    src={'${API_URL_FRONT}embedded/chatbot/${rex_token_chat}'}
    width={open${subToken}?'487px':'56px'}
    height={open${subToken}?'546px':'56px'}
    style={{
      right: '40px',
      bottom:'40px',
      border:'none'
    }}
    scrolling='no'
  >
  </iframe>
  <div 
    onClick={()=> setOpen${subToken}(!open${subToken})}
    style={{
      width: '52px',
      height: '52px',
      position: 'fixed',
      borderRadius:'50%',
      bottom: '40px',
      right: '40px',
      cursor:'pointer'
    }}>
  </div>
</div>
------------- Código en JavaScript -------------\n
//Codigo JS
let open${subToken} = false;
const chatbotContainer = document.getElementById('chatbot-container');
const chatbotIframe = document.getElementById('chatbot-iframe');
const chatbotToggle = document.getElementById('chatbot-toggle');

chatbotToggle.addEventListener('click', function() {
  open${subToken} = !open${subToken};
  chatbotContainer.style.width = open${subToken} ? '487px' : '56px';
  chatbotContainer.style.height = open${subToken} ? '546px' : '56px';
  chatbotIframe.width = open${subToken} ? '487px' : '56px';
  chatbotIframe.height = open${subToken} ? '546px' : '56px';
})

//Codigo HTML
<div id="chatbot-container" style="position: fixed; z-index: 10000000000; bottom: 40px; right: 40px; width: 56px; height: 56px;">
  <iframe id="chatbot-iframe" 
    src="${API_URL_FRONT}embedded/chatbot/${rex_token_chat}"
    width="56px"
    height="56px"
    style="border: none; position: fixed; bottom: 40px; right: 40px;"
    scrolling="no">
  </iframe>
  <div id="chatbot-toggle" 
    style="width: 52px; height: 52px; position: fixed; border-radius: 50%; bottom: 42px; right: 40px; cursor: pointer;">
  </div>
</div>
  `)
  }

  useEffect(() => {
    console.log(rex_token_chat, " ESTE ES EL TOKEN ACTUAL ((((((((--->")

  }, [])


  const steps = [
    {
      title: 'Paso 1: Generar Embebido',
      content: <>
        <div>
          <div>
            1) Generar Embebido y pegar en la pagina web a utilizar<br />
            <Button
              onClick={() => generateCodeEmbedded()}
            >Generar Embebido</Button>
          </div><br />
          <div>
            <div
              style={{
                overflowY: 'auto',
                minHeight:'200px',
                maxHeight: '600px',
                width: "100%",
                background: '#f4f4f4',
                border: '1px solid gray',
                borderRadius: '15px'
              }}
            >
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '10px'
                }}
              >
                <pre><b>{txtEmbebido}</b></pre>
              </div>
            </div>
            {/* <Input.TextArea 
              disabled={true}
              rows={8}
              value={txtEmbebido}
            /> */}
          </div>
        </div>
      </>,
    },
    // {
    //   title: 'Paso 2: Configurar el Whatsapp',
    //   content: <>
    //     <div>
    //       1) Pasamos a configurar la aplicación, para ello marcamos la opción de "Otro" cuando nos pregunte que es lo que queremos hacer con nuestra app
    //     </div>
    //     <img src={StepSix} style={{ width: '650px' }} />
    //     <div>
    //       2) Seleccionamos el tipo de App de "Negocios"
    //     </div>
    //     <img src={StepSeven} style={{ width: '650px' }} />
    //     <div>
    //       3) Ingresamos un nombre a la aplicación
    //     </div>
    //     <img src={StepEch} style={{ width: '650px' }} />
    //     <div>
    //       4) Agregamos el producto de Whatsapp a nuestra aplicación
    //     </div>
    //     <img src={StepNine} />
    //     <div>
    //       5) Finalmente confirmamos que se realice la configuración con este
    //     </div>
    //     <img src={StepTen} />
    //   </>,
    // },
    // {
    //   title: 'Paso 3: Conexión con la aplicación',
    //   content: <>
    //     <div>
    //       1) En la parte izquierda del panel de Meta se nos habilita un sector para Whatsapp, vamos a la opción de "Configuración"
    //     </div>
    //     <img src={StepOnce} />
    //     <div>
    //       2) Generamos un token y una url especifica para nuestra aplicación
    //       <Button
    //         onClick={async () => {
    //           const rpta: {
    //             response: boolean;
    //             data: {
    //               tokenVerificacion: string;
    //               endpoint: string;
    //               id: number
    //             }
    //           } = await dispatch(GetIntegrationWhatsAppReducer());

    //           setDatosConexion({
    //             ...datosConexion,
    //             token: rpta.data.tokenVerificacion,
    //             url: API_URL + rpta.data.endpoint,
    //             integrationId: rpta.data.id
    //           })

    //         }}
    //       >Generar Token</Button>
    //     </div>
    //     <div><b>Token:</b> {datosConexion.token}</div>
    //     <div><b>URL de devolución de llamada:</b> {datosConexion.url}</div>
    //     <div>
    //       3) Generar un token de acceso desde la apliación e ingresarlo en la Web
    //     </div>
    //     <img src={StepDoce} />
    //     <div>
    //       4) Ingresar el token generado aquí:
    //     </div>
    //     <Input onChange={(e) => setTokenApp(e.target.value)} value={tokenApp} />
    //     <Button
    //       onClick={() => {
    //         dispatch(SendTokenAppWhatsappReducer(datosConexion.integrationId, tokenApp))
    //       }}
    //       style={{ marginTop: '10px' }}
    //     >Guardar Token</Button>
    //   </>,
    // },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Steps current={current} items={items} />
      <Card style={{ marginTop: 24 }}>
        <div style={{ marginTop: 24 }}>
          {
            steps[current].content
          }
        </div>
      </Card>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Siguiente
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Finalizar
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Anterior
          </Button>
        )}
      </div>

    </>
  )
}

export default WebIntegration