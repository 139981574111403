import React from 'react';
import { Modal, Typography, Steps, Divider } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import Img1 from '../../../../assets/img/airTable/Img1.png';
import Img2 from '../../../../assets/img/airTable/Img2.png';
import Img3 from '../../../../assets/img/airTable/Img3.png';
import Img4 from '../../../../assets/img/airTable/Img4.png';
import Img5 from '../../../../assets/img/airTable/Img5.png';
import Img6 from '../../../../assets/img/airTable/Img6.png';
import Img7 from '../../../../assets/img/airTable/Img7.png';

const { Title, Paragraph, Text } = Typography;
const { Step } = Steps;

interface ModalTutorialAirtableProps {
  visible: boolean;
  onClose: () => void;
}

const ModalTutorialAirtable: React.FC<ModalTutorialAirtableProps> = ({
  visible,
  onClose
}) => {
  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <BookOutlined />
          <span>Tutorial de Configuración Airtable</span>
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <div style={{ maxHeight: '70vh', overflow: 'auto', padding: '0 16px' }}>
        <Title level={4}>Configuración de Airtable en tu Chatbot</Title>
        <Paragraph>
          Sigue estos pasos para configurar correctamente la integración con Airtable:
        </Paragraph>

        <Steps direction="vertical" current={-1}>
          <Step 
            title="Obtener API Key de Airtable"
            description={
              <div>
                <Paragraph>
                    1. Inicia sesión en tu cuenta de Airtable.
                </Paragraph>
                <Paragraph>
                    2. Ve a <a href="https://airtable.com/create/tokens" target="_blank" rel="noopener noreferrer"><Text strong>https://airtable.com/create/tokens</Text></a>
                </Paragraph>
                <Paragraph>
                  3. Genera una nueva API key si no tienes una, dandole a Crear token.
                  <img src={Img1} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
                <Paragraph>
                  4. Agregale un nombre al nuevo API, despues agregamos el Ámbito de aplicación y el acceso. Y le damos a Crear token. <br/>
                  <img src={Img2} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
                <Paragraph>
                  4. Copia la API key generada.<br/> 
                  <img src={Img3} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
              </div>
            }
          />

          <Step 
            title="Obtener Base ID"
            description={
              <div>
                <Paragraph>
                  1. Abre la base de Airtable que deseas conectar. Ve a "Ayuda" → "API Documentation" <br />
                  <img src={Img4} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
                <Paragraph>
                  2. En la URL, encontrarás el Base ID después de /base/
                  <br />
                  <Text type="secondary">
                    Ejemplo: <Text code>appXXXXXXXXXXXXXX</Text>
                  </Text>  <br />
                  <img src={Img5} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
                <Paragraph>
                  3. Copia el Base ID (comienza con 'app').
                </Paragraph>
              </div>
            }
          />

          <Step 
            title="Obtener Table ID"
            description={
              <div>
                <Paragraph>
                  1. Abre la tabla específica que deseas usar. Ve a "Ayuda" → "API Documentation" <br />
                  <img src={Img6} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
                <Paragraph>
                  3. En la documentación, encontrarás el Table ID
                  <br />
                  <Text type="secondary">
                    Ejemplo: <Text code>tblXXXXXXXXXXXXXX</Text>
                  </Text><br />
                  <img src={Img7} alt="Imagen 1" style={{ width: '100%', maxWidth: '400px', marginTop: '8px' }} />
                </Paragraph>
              </div>
            }
          />

          <Step 
            title="Configurar en el Chatbot"
            description={
              <div>
                <Paragraph>
                  1. Haz clic en el botón de la llave para abrir la configuración.
                </Paragraph>
                <Paragraph>
                  2. Ingresa la API Key que obtuviste.
                </Paragraph>
                <Paragraph>
                  3. Al agregar una nueva base, usa el Base ID correspondiente.
                </Paragraph>
              </div>
            }
          />
        </Steps>

        <Divider />

        <Title level={5}>Notas Importantes:</Title>
        <ul>
          <li>Asegúrate de agregar tu API Key correcta y si lo vuelves a generar ingresas la nueva API key.</li>
          <li>Verifica que la tabla tenga los permisos necesarios.</li>
          <li>Es recomendable tener una estructura de datos consistente en tus tablas.</li>
        </ul>
      </div>
    </Modal>
  );
};

export default ModalTutorialAirtable;