import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, CreditCardOutlined, LogoutOutlined, QuestionOutlined } from '@ant-design/icons';
import { useTour } from '../../components/pages/ShadowComponent/TourContext';
import './styled.css';

interface NavbarProps {
  colorBgContainer: string;
}

const Navbar: React.FC<NavbarProps> = ({ colorBgContainer }) => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const { setTourActive, setCurrentSubView } = useTour();

  const handleLogout = () => {
    localStorage.clear()
    navigate('/login');
  };

  const handleStartTour = () => {
    setCurrentSubView(''); // Reset subview
    setTourActive(true);
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => navigate('/profile')}>
        <UserOutlined /> Ver Perfil
      </Menu.Item>
      <Menu.Item key="settings" onClick={() => navigate('/settings')}>
        <CreditCardOutlined /> Mi Suscripción
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  const helpMenu = (
    <Menu>
      <Menu.Item key="tour" onClick={handleStartTour}>
        <QuestionOutlined /> Iniciar Tour
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ background: "white" }} color='white'>
      <div style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
      }}>
        <Dropdown overlay={helpMenu} trigger={['click']}>
          <Avatar 
            size="large" 
            icon={<QuestionOutlined />} 
            style={{ cursor: 'pointer', marginRight: '15px' }} 
          />
        </Dropdown>
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Avatar 
            size="large" 
            icon={<UserOutlined />} 
            style={{ cursor: 'pointer' }} 
          />
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;