import {
  FETCH_MARKET_CHATBOTS_REQUEST,
  FETCH_MARKET_CHATBOTS_SUCCESS,
  FETCH_MARKET_CHATBOTS_FAILURE,
  ADD_CHATBOT_TO_MARKET_REQUEST,
  ADD_CHATBOT_TO_MARKET_SUCCESS,
  ADD_CHATBOT_TO_MARKET_FAILURE,
  OurChatbotsActionTypes,
  DELETE_FROM_MARKET_FAILURE,
  DELETE_FROM_MARKET_SUCCESS,
  DELETE_FROM_MARKET_REQUEST,
  DUPLICATE_CHATBOT_FAILURE,
  DUPLICATE_CHATBOT_SUCCESS,
  UPDATE_BLOCK_STATUS_FAILURE,
  UPDATE_BLOCK_STATUS_SUCCESS,
  DUPLICATE_CHATBOT_REQUEST,
  UPDATE_BLOCK_STATUS_REQUEST
} from '../../../../constantes/chatBots/OurChatbots/OurChatbots';

interface OurChatbotsState {
  rex_loading: boolean;
  rex_market_chatbots: any[];
  rex_error: string | null;
  rex_pagination: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  } | null;
}

const INIT_STATE: OurChatbotsState = {
  rex_loading: false,
  rex_market_chatbots: [],
  rex_error: null,
  rex_pagination: null
};

export default (state = INIT_STATE, action: OurChatbotsActionTypes): OurChatbotsState => {
  switch (action.type) {
    case FETCH_MARKET_CHATBOTS_REQUEST:
    case ADD_CHATBOT_TO_MARKET_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };

    case FETCH_MARKET_CHATBOTS_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_market_chatbots: action.payload.data,
        rex_pagination: action.payload.pagination,
        rex_error: null
      };

    case ADD_CHATBOT_TO_MARKET_SUCCESS:
      return {
        ...state,
        rex_loading: false,
        rex_market_chatbots: action.payload.success ?
          [...state.rex_market_chatbots, ...action.payload.data] :
          state.rex_market_chatbots,
        rex_error: null
      };

    case FETCH_MARKET_CHATBOTS_FAILURE:
    case ADD_CHATBOT_TO_MARKET_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };

    case DELETE_FROM_MARKET_REQUEST:
      return {
        ...state,
        rex_loading: true,
        rex_error: null
      };
      case DELETE_FROM_MARKET_SUCCESS:
        return {
            ...state,
            rex_loading: false,
            rex_market_chatbots: state.rex_market_chatbots.filter(
                chatbot => chatbot.id !== action.payload
            ),
            rex_error: null
        };
    case DELETE_FROM_MARKET_FAILURE:
      return {
        ...state,
        rex_loading: false,
        rex_error: action.payload
      };
      case UPDATE_BLOCK_STATUS_REQUEST:
        case DUPLICATE_CHATBOT_REQUEST:
          return {
            ...state,
            rex_loading: true,
            rex_error: null
          };
    
          case UPDATE_BLOCK_STATUS_SUCCESS:
            return {
              ...state,
              rex_loading: false,
              rex_market_chatbots: state.rex_market_chatbots.map(chatbot =>
                chatbot.id === action.payload.data[0].id
                  ? { ...chatbot, bloqueado: action.payload.data[0].bloqueado }
                  : chatbot
              ),
              rex_error: null
            };
    
        case DUPLICATE_CHATBOT_SUCCESS:
          return {
            ...state,
            rex_loading: false,
            rex_market_chatbots: [...state.rex_market_chatbots, action.payload.data[0]],
            rex_error: null
          };
    
        case UPDATE_BLOCK_STATUS_FAILURE:
        case DUPLICATE_CHATBOT_FAILURE:
          return {
            ...state,
            rex_loading: false,
            rex_error: action.payload
          };
    default:
      return state;
  }
};