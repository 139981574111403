import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Modal, Dropdown, Menu, Row, Col, Skeleton} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import AvatarRobot from '../../../assets/img/avatars/robot.avif';
import {GetChatbotByToken, GetDataChatsBotsHomeReducer, SelectBotReducer, SetConversacionSeleccionadaHome, SetIdChatSeleccionadoHome, SetTokenChatSeleccionadoHome, UpdateChatSeccionadoHome, deleteChatbotReducer, duplicateChatbotReducer } from '../../../redux/actions/home/Home';
import { AppDispatch, RootState } from '../../../redux/store/store';
import '../../../styles/pages/home/Home.css';
import { useNavigate, useParams } from 'react-router-dom';
import ChatComponent from '../../chat/ChatComponent';
import { GetOneDesingChatReducer } from '../../../redux/actions/chatBots/Chat/ChatDesing';
import SpotlightTour from '../../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../../components/pages/ShadowComponent/TourContext';
import { ResetConversationReducer } from '../../../redux/actions/chatBots/Chat/Chat';

const { confirm } = Modal;
interface FormValues {
  fontSize: string;
  fontFamily: string;
  nombreChat: string;
  inputPlaceholder: string;
  logo: File | null;
  icono: File | null;
  iconoRuta: string;
  logoRuta: string;
  colorHeader: string;
  colorTitulo: string;
  colorFondoEmisor: string;
  colorFondoReceptor: string;
  retrasoRespuesta: number;
  colorEmisor: string;
  colorReceptor: string;
  estado: boolean;
  colorEstadoActivo: string;
  colorEstadoInactivo: string;
  estadoHorario: boolean;
}
interface PropsCardBot {
  chatWithBot: boolean;
}

const CardBot: React.FC<PropsCardBot> = ({ chatWithBot = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ chatToken: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChatbot, setSelectedChatbot] = useState<any>(null);
  const { rex_chatsbots, rex_token_chat, rex_loading, rex_error, rex_chat_selecccionado, rex_chatbot_seleccionado } = useSelector(({ home }: RootState) => home);
  const { rex_user_auth } = useSelector(({ auth }: RootState) => auth);
  const { rex_design_chat, rex_design_status, rex_styles } = useSelector((state: RootState) => state.design);
  const [displayedChatbots, setDisplayedChatbots] = useState<any[]>([]);

  const id_conversation = rex_chat_selecccionado
  let selectedChatId = rex_chat_selecccionado;

  const showModal = async (chatbot: any) => {
    setSelectedChatbot(chatbot);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMenuClick = (chatbot: any) => ({ key }: { key: string }) => {

    let id_usuario = 0;
    if (rex_user_auth) id_usuario = rex_user_auth.id;

    if (key === 'editar') {
      navigate('/chats');
    } else if (key === 'duplicar') {
      confirm({
        title: '¿Estás seguro de duplicar este bot?',
        content: 'Esta opción duplicara todo el entrenamiento que se le haya realizado al bot',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          const usuarioId = id_usuario;
          dispatch(duplicateChatbotReducer(usuarioId, chatbot.id));
        },
        onCancel() { },
      });
    } else if (key === 'eliminar') {
      confirm({
        title: '¿Estás seguro de eliminar este chatbot?',
        content: 'Una vez eliminado, no podrás recuperar este chatbot.',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          dispatch(deleteChatbotReducer(chatbot.id));
        },
        onCancel() {
        },
      });
    }
  };

  const menu = (chatbot: any) => (
    <Menu onClick={handleMenuClick(chatbot)}>
      <Menu.Item key="editar">Editar</Menu.Item>
      <Menu.Item key="duplicar">Duplicar</Menu.Item>
      <Menu.Item key="eliminar">Eliminar</Menu.Item>
    </Menu>
  );

  const [initialValues, setInitialValues] = useState<FormValues>({
    fontSize: '',
    fontFamily: '',
    nombreChat: '',
    inputPlaceholder: '',
    logo: null,
    icono: null,
    iconoRuta: '',
    logoRuta: '',
    colorHeader: '#1677ff',
    colorTitulo: '#1677ff',
    colorFondoEmisor: '#0084ff',
    colorFondoReceptor: '#e5e5ea',
    colorEmisor: '#1677ff',
    retrasoRespuesta: 50,
    colorReceptor: '#1677ff',
    estado: false,
    colorEstadoActivo: '#0BF732',
    colorEstadoInactivo: '#ea3765',
    estadoHorario: false
  });

  useEffect(() => {
    dispatch(GetOneDesingChatReducer());
  }, [rex_styles]);

  useEffect(() => {
    if (rex_design_chat) {
      setInitialValues({
        fontSize: rex_design_chat.tamanoLetra || '',
        fontFamily: rex_design_chat.fuente || '10',
        nombreChat: rex_design_chat.nombre || '',
        inputPlaceholder: rex_design_chat.placeholder || '',
        logo: null,
        icono: null,
        iconoRuta: rex_design_chat.iconoEnvio || '',
        logoRuta: rex_design_chat.logo || '',
        colorHeader: rex_design_chat.colorCabecera || '#1677ff',
        colorTitulo: rex_design_chat.colorTitulo || '#1677ff',
        colorFondoEmisor: rex_design_chat.colorFondoTextoEmisor || '#0084ff',
        colorFondoReceptor: rex_design_chat.colorFondoTextoReceptor || '#e5e5ea',
        colorEmisor: rex_design_chat.colorTextoEmisor || '#1677ff',
        colorReceptor: rex_design_chat.colorTextoReceptor || '#1677ff',
        estado: rex_design_chat.estado || false,
        colorEstadoActivo: rex_design_chat.colorEstadoActivo || '#0BF732',
        colorEstadoInactivo: rex_design_chat.colorEstadoInactivo || '#ea3765',
        estadoHorario: rex_design_chat.estadoHorario || false,
        retrasoRespuesta: rex_design_chat.retrasoRespuesta || 50,
      });
    }
  }, [rex_design_chat, rex_styles]);

  const homeSteps = tourData.Home;
  const { currentView } = useTour();

  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(GetDataChatsBotsHomeReducer());
      setIsDataFetched(true);
    }
  }, [isDataFetched]);
  

  useEffect(() => {
    if (params.chatToken) {
      const isUserChatbot = rex_chatsbots.some(bot => bot.token === params.chatToken);
      if (isUserChatbot) {
        setDisplayedChatbots(rex_chatsbots);
      } else {
        const selectedBot = rex_chatsbots.find((bot: any) => bot.token === params.chatToken);  
        if (selectedBot) {
          setDisplayedChatbots([selectedBot]);
        } else if (rex_chatbot_seleccionado) {
          setDisplayedChatbots([rex_chatbot_seleccionado]);
        } else {
          dispatch(GetChatbotByToken(params.chatToken));
        }
      }
    } else {
      setDisplayedChatbots(rex_chatsbots);
    }
  }, [params.chatToken, rex_chatsbots, rex_chatbot_seleccionado]); 
  
  
  return (
    <>
    {currentView === 'home' && <SpotlightTour steps={homeSteps} view="home" />}
      {rex_loading ? (
        <Row gutter={[16, 16]}>
          {Array.from({ length: 8 }).map((_, index) => (
            <Col key={index} xl={6} md={12} sm={24}>
              <Card
                className="custom-card"
                style={{
                  height: "260px",
                  overflow: 'hidden',
                  marginBottom: '16px',
                }}
              >
                <Skeleton loading={rex_loading} avatar paragraph={{ rows: 2 }} />
              </Card>
            </Col>
          ))}
        </Row>
      ) : rex_error ? (
        <p style={{ color: 'red' }}>Error: {rex_error}</p>
      ) : (
        displayedChatbots.length > 0 && (
          <Row gutter={[16, 16]}>
            {displayedChatbots.slice(0, 10).map((chatbot: any, index: number) => (
              <Col key={index} xl={6} md={12} sm={24}>
                <Card
                  className={
                    selectedChatId === chatbot.id
                      ? "custom-card-select"
                      : "custom-card"
                  }
                  style={{ height: "260px", overflow: 'hidden', marginBottom: '16px' }}
                  actions={
                    chatWithBot 
                    ? [
                        <Button 
                          type="link" 
                          onClick={(e) => {
                            dispatch(SetIdChatSeleccionadoHome(chatbot.id))
                            dispatch(SetTokenChatSeleccionadoHome(chatbot.token))
                            e.stopPropagation(); // Evita que el evento de clic se propague al Card
                            showModal(chatbot); // Solo muestra el modal, no ejecuta otras acciones
                          }} 
                          className="chat-action-button"
                        >
                          Conversar con el Chat
                        </Button>
                  
                      ] 
                    : []
                  }
                  onClick={() => {
                    dispatch(SelectBotReducer(index, !chatbot.select));
                    if(chatbot.token != rex_token_chat){
                      dispatch(ResetConversationReducer())
                    }
                    // Evita que estas acciones se ejecuten si solo se va a abrir el modal
                    if (!isModalVisible) {
                      dispatch(UpdateChatSeccionadoHome(chatbot.id));
                      if (chatbot.conversacionId) {
                        dispatch(SetConversacionSeleccionadaHome(chatbot.conversacionId));
                      } else {
                        dispatch(SetConversacionSeleccionadaHome("0"));
                      }
                    }
                  }}
                  
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img
                      src={chatbot.logo == "http://url" ? AvatarRobot : chatbot.logo}
                      style={{
                        borderRadius: '100%',
                        width: '60px',
                        height: '60px',
                        marginRight: '10px',
                        background: chatbot.logo ? '#E6F4FF' : '#ccc',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                    <span>{chatbot.nombre || "Nombre no disponible"}</span>
                    <div className={`home-menu-button ${currentView === 'chat' ? 'chat-menu-button' : ''}`}>
                    <Dropdown
                      overlay={menu(chatbot)}
                      trigger={['click']}
                    >
                      <MoreOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                    </Dropdown>
                    </div>
                  </div>
                  <div className="card-description" style={{ marginTop: '10px' }}>
                    {chatbot.descripcion || "Descripción no disponible"}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )
      )}
      <Modal
        title="InfoBot"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={500}
      >
        <ChatComponent
          idConversation={
            id_conversation
              ? id_conversation.toString() // Convertir a string
              : '0'
          }

          editBubble={false}
          nombreChat={selectedChatbot?.nombre || ''}
          resetChat={true}
          fontSize={selectedChatbot?.tamanoLetra || ''}
          fontFamily={selectedChatbot?.fuente}
          inputPlaceholder={selectedChatbot?.placeholder}
          iconoEnviarChat={selectedChatbot?.iconoEnvio}
          logoChat={selectedChatbot?.logo}
          estadoChat={selectedChatbot?.estado}
          retrasoRespuesta={selectedChatbot?.retrasoRespuesta}
          coloresStyle={
            {
              colorCabecera: selectedChatbot?.colorCabecera || '#1677ff',
              colorTextoEmisor: selectedChatbot?.colorTextoEmisor || '#1677ff',
              colorTextoReceptor: selectedChatbot?.colorTextoReceptor || '#1677ff',
              colorFondoTextoEmisor: selectedChatbot?.colorFondoTextoEmisor || '#0084ff',
              colorFondoTextoReceptor: selectedChatbot?.colorFondoTextoReceptor || '#e5e5ea',
              colorTitulo: selectedChatbot?.colorTitulo || '#1677ff',
              colorEstadoActivo: selectedChatbot?.colorEstadoActivo || '#0BF732',
              colorEstadoInactivo: selectedChatbot?.colorEstadoInactivo || '#ea3765',
            }
          }
          estadoHorario={selectedChatbot?.estadoHorario}
        />
      </Modal>
    </>
  );
};
export default CardBot;