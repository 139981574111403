import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
    FETCH_LECTURA_REQUEST,
    FETCH_LECTURA_SUCCESS,
    FETCH_LECTURA_FAILURE,
    UPDATE_LECTURA_SUCCESS,
    UPDATE_LECTURA_FAILURE,
    DELETE_LECTURA_FAILURE,
    DELETE_LECTURA_SUCCESS,
    UPDATE_LECTURA_REQUEST,
    FETCH_GET_LECTURAS_FAILURE,
    FETCH_GET_LECTURAS_SUCCESS,
    DELETE_LECTURA_REQUEST,
    FETCH_GET_LECTURAS_REQUEST,
    SET_CURRENT_PAGE,
    UPDATE_LECTURA_CONTENT_REQUEST,
    UPDATE_LECTURA_CONTENT_SUCCESS,
    UPDATE_LECTURA_CONTENT_FAILURE,
    UPDATE_SUB_URL_CONTENT_REQUEST,
    UPDATE_SUB_URL_CONTENT_SUCCESS,
    UPDATE_SUB_URL_CONTENT_FAILURE,
    DELETE_SUBURL_REQUEST,
    DELETE_SUBURL_SUCCESS,
    DELETE_SUBURL_FAILURE,
    UPDATE_SUBURL_MANUAL_CONTENT_REQUEST,
    UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS,
    UPDATE_LECTURA_MANUAL_CONTENT_FAILURE,
    UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS,
    UPDATE_LECTURA_MANUAL_CONTENT_REQUEST,
    UPDATE_SUBURL_MANUAL_CONTENT_FAILURE,
    FETCH_AIRTABLE_REQUEST,
    FETCH_AIRTABLE_FAILURE,
    FETCH_AIRTABLE_SUCCESS,
    FETCH_AIRTABLE_CONFIGS_REQUEST,
    FETCH_AIRTABLE_CONFIGS_SUCCESS,
    FETCH_AIRTABLE_CONFIGS_FAILURE,
    SET_CURRENT_AIRTABLE_PAGE,
    ADD_SUBURL_REQUEST,
    ADD_SUBURL_SUCCESS,
    ADD_SUBURL_FAILURE,
    UPDATE_USER_AIRTABLES_REQUEST,
    UPDATE_USER_AIRTABLES_SUCCESS,
    UPDATE_USER_AIRTABLES_FAILURE,
    DELETE_AIRTABLE_TABLE_REQUEST,
    DELETE_AIRTABLE_TABLE_SUCCESS,
    DELETE_AIRTABLE_TABLE_FAILURE,
    UPDATE_AIRTABLE_BASE_REQUEST,
    UPDATE_AIRTABLE_BASE_SUCCESS,
    UPDATE_AIRTABLE_BASE_FAILURE,
    UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST,
    UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS,
    UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE,
    EDIT_AIRTABLE_TABLE_CONTENT_REQUEST,
    EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS,
    EDIT_AIRTABLE_TABLE_CONTENT_FAILURE,
    UPDATE_FULL_AIRTABLE_BASE_REQUEST,
    UPDATE_FULL_AIRTABLE_BASE_SUCCESS,
    UPDATE_FULL_AIRTABLE_BASE_FAILURE,
    DELETE_AIRTABLE_BASE_SUCCESS,
    DELETE_AIRTABLE_BASE_FAILURE,
    DELETE_AIRTABLE_BASE_REQUEST,
    ADD_AIRTABLE_TABLE_SUCCESS,
    ADD_AIRTABLE_TABLE_FAILURE,
    ADD_AIRTABLE_TABLE_REQUEST
} from '../../../constantes/lecturas/LecturaWeb';
import fetchWithIP from '../utils/fetchHeaders';
import { RootState } from '../../store/store';
import { selectTokenChatSeleccionado } from '../../reducers/selectors/selectors';
import { PayloadAction } from '@reduxjs/toolkit';

export const FechCreateLecturaWebReducer = (
    url: string,
    nombre: string,
    origen_lectura: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: FETCH_LECTURA_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}`, 
            {
                method: 'POST',
            },
            {
                url: url.trim(),
                nombre: nombre.trim(),
                origen_lectura
            }
        );

        const data = await response.json();
        console.log('Action - Respuesta completa:', data);

        dispatch({ type: FETCH_LECTURA_SUCCESS, payload: data });
        return data;

    } catch (error: any) {
        dispatch({
            type: FETCH_LECTURA_FAILURE,
            payload: error?.message || 'Error al crear lectura web'
        });
        throw error;
    }
};

export const GetLecturasWebReducer = (
    page: number = 1,
    limit: number = 5,
    filters?: {
        nombre?: string;
        url?: string;
    }
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => 
    async (dispatch, getState) => {
    dispatch({ type: FETCH_GET_LECTURAS_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        
        // Construir query params
        const queryParams = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            ...(filters?.nombre && { nombre: filters.nombre }),
            ...(filters?.url && { url: filters.url })
        });

        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas?${queryParams}`,
            {
                method: 'GET'
            }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al obtener lecturas');
        }
        
        dispatch({ type: FETCH_GET_LECTURAS_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: FETCH_GET_LECTURAS_FAILURE, 
            payload: error?.message || 'Error al obtener lecturas' 
        });
        throw error;
    }
};

export const DeleteLecturaWebReducer = (
    id: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (
    dispatch, 
    getState) => {
    dispatch({ type: DELETE_LECTURA_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        await fetchWithIP(`lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${id}`, {
            method: 'DELETE'
        });
        dispatch({ type: DELETE_LECTURA_SUCCESS, payload: id });
    } catch (error: any) {
        dispatch({ type: DELETE_LECTURA_FAILURE, payload: error?.message || 'Error al eliminar lectura' });
    }
};

export const UpdateSubUrlContentReducer = (
    lecturaId: number,
    subUrlId: number
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (
    dispatch, 
    getState
) => {
    dispatch({ type: UPDATE_SUB_URL_CONTENT_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${lecturaId}/subUrls/${subUrlId}/update-content`,
            {
                method: 'PUT'
            }
        );
        const data = await response.json();

        dispatch({ type: UPDATE_SUB_URL_CONTENT_SUCCESS, payload: data });
        return data; 
    } catch (error: any) {
        dispatch({ type: UPDATE_SUB_URL_CONTENT_FAILURE, payload: error?.message || 'Error al actualizar sub URL' });
        throw error;
    }
};

export const DeleteSubUrlReducer = (
    lecturaId: number,
    subUrlId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (
    dispatch,
    getState
) => {
    dispatch({ type: DELETE_SUBURL_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${lecturaId}/subUrls/${subUrlId}`,
            {
                method: 'DELETE'
            }
        );
        dispatch({ 
            type: DELETE_SUBURL_SUCCESS, 
            payload: { lecturaId, subUrlId }
        });
    } catch (error: any) {
        dispatch({ 
            type: DELETE_SUBURL_FAILURE, 
            payload: error?.message || 'Error al eliminar sub URL' 
        });
        throw error;
    }
};

export const UpdateLecturaWebReducer = (
    id: number,
    nombre?: string, 
    url?: string
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (
    dispatch,
    getState
) => {
    dispatch({ type: UPDATE_LECTURA_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const data: { nombre?: string, url?: string } = {};
        if (nombre) data.nombre = nombre;
        if (url) data.url = url;

        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${id}`,
            { method: 'PUT'},
            data
        );
        const responseData = await response.json();
        const updatedData = responseData.data
        
        dispatch({
            type: UPDATE_LECTURA_SUCCESS,
            payload: updatedData
        });
        await dispatch(GetLecturasWebReducer());

    } catch (error: any) {
        console.error('Error en actualización:', error);
        dispatch({
            type: UPDATE_LECTURA_FAILURE,
            payload: error?.message || 'Error al actualizar lectura'
        });
    }
};

export const UpdateLecturaContentReducer = (
    id: number
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch({ type: UPDATE_LECTURA_CONTENT_REQUEST });
    try {
      const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
      const response = await fetchWithIP(
        `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${id}/update-content`,
        {
          method: 'PUT'
        }
      );
      const data = await response.json();
      
      if (!data.respuesta) {
        throw new Error(data.mensaje || 'Error al actualizar el contenido');
      }
      
      dispatch({ type: UPDATE_LECTURA_CONTENT_SUCCESS, payload: data });
      return data;
    } catch (error: any) {
      dispatch({ 
        type: UPDATE_LECTURA_CONTENT_FAILURE, 
        payload: error?.message || 'Error al actualizar el contenido' 
      });
      throw error;
    }
};

export const UpdateLecturaManualContentReducer = (
    id: number,
    contenido: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: UPDATE_LECTURA_MANUAL_CONTENT_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${id}/contenido`,
            {
                method: 'PUT'
            },
            { contenido }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al actualizar el contenido');
        }
        
        dispatch({ 
            type: UPDATE_LECTURA_MANUAL_CONTENT_SUCCESS, 
            payload: { 
                data: data.data,
                id 
            } 
        });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: UPDATE_LECTURA_MANUAL_CONTENT_FAILURE, 
            payload: error?.message || 'Error al actualizar el contenido manualmente' 
        });
        throw error;
    }
};

export const UpdateSubUrlManualContentReducer = (
    lecturaId: number,
    subUrlId: number,
    contenido: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: UPDATE_SUBURL_MANUAL_CONTENT_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${lecturaId}/subUrls/${subUrlId}/contenido`,
            {
                method: 'PUT'
            },
            { contenido }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al actualizar el contenido');
        }
        
        dispatch({ 
            type: UPDATE_SUBURL_MANUAL_CONTENT_SUCCESS, 
            payload: {
                data: data.data,
                lecturaId,
                subUrlId
            }
        });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: UPDATE_SUBURL_MANUAL_CONTENT_FAILURE, 
            payload: error?.message || 'Error al actualizar el contenido manualmente' 
        });
        throw error;
    }
};

export const AddSubUrlReducer = (
    lecturaId: number,
    sub_url: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: ADD_SUBURL_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/chatbots/${tokenChatSeleccionado}/lecturas/${lecturaId}/subUrls`,
            {
                method: 'POST'
            },
            { sub_url }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje_dev || data.mensaje || 'Error al agregar la sub URL');
        }
        
        dispatch({ 
            type: ADD_SUBURL_SUCCESS, 
            payload: {
                lecturaId,
                subUrl: data.data
            }
        });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: ADD_SUBURL_FAILURE, 
            payload: error.message
        });
        throw error;
    }
};

export const setCurrentPage = (page: number): PayloadAction<number> => ({
    type: SET_CURRENT_PAGE,
    payload: page
});

export const setCurrentAirtablePage = (page: number): PayloadAction<number> => ({
    type: SET_CURRENT_AIRTABLE_PAGE,
    payload: page
});

export const FetchAirtableDataReducer = (
    baseId: string,
    baseName: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: FETCH_AIRTABLE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}`,
            {
                method: 'POST'
            },
            {
                base_id: baseId.trim(),
                base_nombre: baseName.trim()
            }
        );

        const data = await response.json();
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al procesar datos de Airtable');
        }

        dispatch({ type: FETCH_AIRTABLE_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({
            type: FETCH_AIRTABLE_FAILURE,
            payload: error?.message || 'Error al procesar datos de Airtable'
        });
        throw error;
    }
};

export const GetAirtableConfigsReducer = (
    page: number = 1,
    limit: number = 5,
    filters?: {
        base_nombre?: string;
        base_id?: string;
    },
    sortOrder: 'asc' | 'desc' = 'desc',
    sortColumn: string = 'created_at'
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => 
    async (dispatch, getState) => {
    dispatch({ type: FETCH_AIRTABLE_CONFIGS_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        
        const queryParams = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            sortOrder,
            sortColumn,
            ...(filters?.base_nombre && { base_nombre: filters.base_nombre }),
            ...(filters?.base_id && { base_id: filters.base_id })
        });

        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/configs?${queryParams}`,
            {
                method: 'GET'
            }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al obtener bases de Airtable');
        }
        
        dispatch({ type: FETCH_AIRTABLE_CONFIGS_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: FETCH_AIRTABLE_CONFIGS_FAILURE, 
            payload: error?.message || 'Error al obtener bases de Airtable' 
        });
        throw error;
    }
};

export const DeleteAirtableBaseReducer = (
    baseId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: DELETE_AIRTABLE_BASE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}`,
            {
                method: 'DELETE'
            }
        );
        dispatch({ type: DELETE_AIRTABLE_BASE_SUCCESS, payload: baseId });
    } catch (error: any) {
        dispatch({ 
            type: DELETE_AIRTABLE_BASE_FAILURE, 
            payload: error?.message || 'Error al eliminar la base' 
        });
        throw error;
    }
};

export const DeleteAirtableTableReducer = (
    baseId: number,
    tableId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: DELETE_AIRTABLE_TABLE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}/table/${tableId}`,
            {
                method: 'DELETE'
            }
        );
        dispatch({ type: DELETE_AIRTABLE_TABLE_SUCCESS, payload: { baseId, tableId } });
    } catch (error: any) {
        dispatch({ 
            type: DELETE_AIRTABLE_TABLE_FAILURE, 
            payload: error?.message || 'Error al eliminar la tabla' 
        });
        throw error;
    }
};

export const UpdateAirtableBaseReducer = (
    baseId: number,
    updateDto: { base_id?: string; base_nombre?: string }
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: UPDATE_AIRTABLE_BASE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}`,
            {
                method: 'PUT'
            },
            updateDto
        );
        
        const data = await response.json();
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al actualizar la base');
        }

        dispatch({ type: UPDATE_AIRTABLE_BASE_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: UPDATE_AIRTABLE_BASE_FAILURE, 
            payload: error?.message || 'Error al actualizar la base' 
        });
        throw error;
    }
};

export const UpdateAirtableTableContentReducer = (
    baseId: number,
    tableId: number
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: UPDATE_AIRTABLE_TABLE_CONTENT_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}/table/${tableId}/update-content`,
            {
                method: 'PUT'
            }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al actualizar el contenido de la tabla');
        }
        
        dispatch({ type: UPDATE_AIRTABLE_TABLE_CONTENT_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: UPDATE_AIRTABLE_TABLE_CONTENT_FAILURE, 
            payload: error?.message || 'Error al actualizar el contenido de la tabla' 
        });
        throw error;
    }
};

export const EditAirtableContentReducer = (
    baseId: number,
    tableId: number,
    contenido: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: EDIT_AIRTABLE_TABLE_CONTENT_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}/table/${tableId}/edit-content`,
            {
                method: 'PUT'
            },
            { contenido }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al editar el contenido de la tabla');
        }
        
        dispatch({ 
            type: EDIT_AIRTABLE_TABLE_CONTENT_SUCCESS, 
            payload: { 
                data: data.data,
                baseId,
                tableId
            } 
        });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: EDIT_AIRTABLE_TABLE_CONTENT_FAILURE, 
            payload: error?.message || 'Error al editar el contenido de la tabla manualmente' 
        });
        throw error;
    }
};

export const UpdateUserAirtablesReducer = (
    apiKey: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (
    dispatch,
    getState
) => {
    dispatch({ type: UPDATE_USER_AIRTABLES_REQUEST });
    try {
        const { rex_user_auth } = getState().auth;
        const userToken = rex_user_auth?.token;

        const response = await fetchWithIP(
            `lecturaWebs/api/user/${userToken}`,
            {
                method: 'PUT'
            },
            {
                api_key_airtables: apiKey.trim()
            }
        );

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.mensaje || 'Error al actualizar API key de Airtable');
        }

        dispatch({ type: UPDATE_USER_AIRTABLES_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({
            type: UPDATE_USER_AIRTABLES_FAILURE,
            payload: error?.message || 'Error al actualizar API key de Airtable'
        });
        throw error;
    }
};

export const UpdateFullAirtableBaseReducer = (
    baseId: number
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> =>
async (dispatch, getState) => {
    dispatch({ type: UPDATE_FULL_AIRTABLE_BASE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}/update-full`,
            {
                method: 'PUT'
            }
        );
        const data = await response.json();
        
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al actualizar la base completa');
        }
        
        dispatch({ type: UPDATE_FULL_AIRTABLE_BASE_SUCCESS, payload: data });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: UPDATE_FULL_AIRTABLE_BASE_FAILURE, 
            payload: error?.message || 'Error al actualizar la base completa' 
        });
        throw error;
    }
};


export const AddAirtableTableReducer = (
    baseId: number,
    tableId: string
): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch({ type: ADD_AIRTABLE_TABLE_REQUEST });
    try {
        const tokenChatSeleccionado = selectTokenChatSeleccionado(getState());
        const response = await fetchWithIP(
            `lecturaWebs/airtable/chatbots/${tokenChatSeleccionado}/base/${baseId}/table`,
            {
                method: 'POST'
            },
            {
                table_id: tableId.trim()
            }
        );

        const data = await response.json();
        if (!data.respuesta) {
            throw new Error(data.mensaje || 'Error al agregar la tabla');
        }

        dispatch({ 
            type: ADD_AIRTABLE_TABLE_SUCCESS, 
            payload: { 
                data: data.data,
                baseId
            } 
        });
        return data;
    } catch (error: any) {
        dispatch({ 
            type: ADD_AIRTABLE_TABLE_FAILURE, 
            payload: error?.message || 'Error al agregar la tabla' 
        });
        throw error;
    }
};