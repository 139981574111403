import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import fetchWithIP from '../../utils/fetchHeaders';
import {
  FETCH_MARKET_CHATBOTS_REQUEST,
  FETCH_MARKET_CHATBOTS_SUCCESS,
  FETCH_MARKET_CHATBOTS_FAILURE,
  ADD_CHATBOT_TO_MARKET_REQUEST,
  ADD_CHATBOT_TO_MARKET_SUCCESS,
  ADD_CHATBOT_TO_MARKET_FAILURE,
  OurChatbotsActionTypes,
  DELETE_FROM_MARKET_REQUEST,
  DELETE_FROM_MARKET_SUCCESS,
  DELETE_FROM_MARKET_FAILURE,
  DUPLICATE_CHATBOT_FAILURE,
  DUPLICATE_CHATBOT_REQUEST,
  DUPLICATE_CHATBOT_SUCCESS,
  UPDATE_BLOCK_STATUS_FAILURE,
  UPDATE_BLOCK_STATUS_SUCCESS,
  UPDATE_BLOCK_STATUS_REQUEST
} from '../../../../constantes/chatBots/OurChatbots/OurChatbots';

interface AddChatbotToMarketParams {
  token: string;
  bloqueado?: boolean;
}

export const fetchMarketChatbotsRequestReducer = (): OurChatbotsActionTypes => ({
  type: FETCH_MARKET_CHATBOTS_REQUEST
});

export const fetchMarketChatbotsSuccessReducer = (data: any): OurChatbotsActionTypes => ({
  type: FETCH_MARKET_CHATBOTS_SUCCESS,
  payload: data
});

export const fetchMarketChatbotsFailureReducer = (error: string): OurChatbotsActionTypes => ({
  type: FETCH_MARKET_CHATBOTS_FAILURE,
  payload: error
});

export const addChatbotToMarketRequestReducer = (): OurChatbotsActionTypes => ({
  type: ADD_CHATBOT_TO_MARKET_REQUEST
});

export const addChatbotToMarketSuccessReducer = (data: any): OurChatbotsActionTypes => ({
  type: ADD_CHATBOT_TO_MARKET_SUCCESS,
  payload: data
});

export const addChatbotToMarketFailureReducer = (error: string): OurChatbotsActionTypes => ({
  type: ADD_CHATBOT_TO_MARKET_FAILURE,
  payload: error
});

export const deleteFromMarketRequestReducer = (): OurChatbotsActionTypes => ({
  type: DELETE_FROM_MARKET_REQUEST
});

export const deleteFromMarketSuccessReducer = (data: any): OurChatbotsActionTypes => ({
  type: DELETE_FROM_MARKET_SUCCESS,
  payload: data
});

export const deleteFromMarketFailureReducer = (error: string): OurChatbotsActionTypes => ({
  type: DELETE_FROM_MARKET_FAILURE,
  payload: error
});

export const updateBlockStatusRequestReducer = (): OurChatbotsActionTypes => ({
  type: UPDATE_BLOCK_STATUS_REQUEST
});

export const updateBlockStatusSuccessReducer = (data: any): OurChatbotsActionTypes => ({
  type: UPDATE_BLOCK_STATUS_SUCCESS,
  payload: data
});

export const updateBlockStatusFailureReducer = (error: string): OurChatbotsActionTypes => ({
  type: UPDATE_BLOCK_STATUS_FAILURE,
  payload: error
});

export const duplicateChatbotRequestReducer = (): OurChatbotsActionTypes => ({
  type: DUPLICATE_CHATBOT_REQUEST
});

export const duplicateChatbotSuccessReducer = (data: any): OurChatbotsActionTypes => ({
  type: DUPLICATE_CHATBOT_SUCCESS,
  payload: data
});

export const duplicateChatbotFailureReducer = (error: string): OurChatbotsActionTypes => ({
  type: DUPLICATE_CHATBOT_FAILURE,
  payload: error
});

export const GetMarketChatbotsReducer = (
  query?: {
    page?: number;
    limit?: number;
    sortOrder?: 'asc' | 'desc';
    nombre?: string;
    descripcion?: string;
  }
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(fetchMarketChatbotsRequestReducer());
  
  try {
    const params = new URLSearchParams();
    if (query) {
      if (query.page) params.append('page', query.page.toString());
      if (query.limit) params.append('limit', query.limit.toString());
      if (query.sortOrder) params.append('sortOrder', query.sortOrder);
      if (query.nombre) params.append('nombre', query.nombre);
      if (query.descripcion) params.append('descripcion', query.descripcion);
    }

    const queryString = params.toString() ? `?${params.toString()}` : '';
    const response = await fetchWithIP(`our-chatbots${queryString}`, {
      method: 'GET'
    });

    const data = await response.json();
    dispatch(fetchMarketChatbotsSuccessReducer(data));
  } catch (error) {
    dispatch(fetchMarketChatbotsFailureReducer('Failed to fetch market chatbots'));
  }
};


export const AddChatbotToMarketReducer = ({
  token,
  bloqueado = false
}: AddChatbotToMarketParams): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(addChatbotToMarketRequestReducer());
  try {
    const response = await fetchWithIP(
      `our-chatbots/chatbots/${token}`,
      {
        method: 'POST'
      },
      { bloqueado }
    );

    const data = await response.json();
    
    if (!data.success) {
      dispatch(addChatbotToMarketFailureReducer(data.message || 'Failed to add chatbot to market'));
      return;
    }

    dispatch(addChatbotToMarketSuccessReducer(data));
  } catch (error) {
    dispatch(addChatbotToMarketFailureReducer('Failed to add chatbot to market'));
  }
};

export const DeleteFromMarketReducer = (
  marketId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({ type: DELETE_FROM_MARKET_REQUEST });
  try {
    const response = await fetchWithIP(`our-chatbots/chatbots/${marketId}`, {
      method: 'DELETE'
    });
    const data = await response.json();
    if (data.respuesta) {
      dispatch({ type: DELETE_FROM_MARKET_SUCCESS, payload: marketId });
      dispatch(GetMarketChatbotsReducer());
    } else {
      throw new Error(data.mensaje || 'Error al eliminar del marketplace');
    }
  } catch (error: any) {
    dispatch({ 
      type: DELETE_FROM_MARKET_FAILURE, 
      payload: error.message || 'Error al eliminar del marketplace'
    });
    throw error;
  }
};

export const UpdateBlockStatusReducer = (
  marketId: number,
  bloqueado: boolean
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(updateBlockStatusRequestReducer());
  try {
    const response = await fetchWithIP(
      `our-chatbots/chatbots/${marketId}/block`,
      { method: 'PUT' },
      { bloqueado }
    );
    const responseData = await response.json();
    if (!responseData.success) {
      dispatch(updateBlockStatusFailureReducer(responseData.message || 'Failed to update block status'));
      return;
    }
    dispatch(updateBlockStatusSuccessReducer(responseData));
    await dispatch(GetMarketChatbotsReducer());
  } catch (error: any) {
    console.error('Error en actualización:', error);
    dispatch(updateBlockStatusFailureReducer(error?.message || 'Failed to update block status'));
  }
};

export const DuplicateChatbotReducer = (
  marketId: number
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(duplicateChatbotRequestReducer());
  try {
    const response = await fetchWithIP(`our-chatbots/chatbots/${marketId}/duplicate`, {
      method: 'POST'
    });
    const data = await response.json();
    if (!data.success) {
      dispatch(duplicateChatbotFailureReducer(data.message || 'Failed to duplicate chatbot'));
      return;
    }
    dispatch(duplicateChatbotSuccessReducer(data));
    dispatch(GetMarketChatbotsReducer());
  } catch (error) {
    dispatch(duplicateChatbotFailureReducer('Failed to duplicate chatbot'));
  }
};
