import React, { useState, useEffect } from 'react';
import { Button, Modal, Space, Table, Input } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import IconClosed from "../../../../assets/IconClosed.svg";

interface ModalEditContentProps {
  visible: boolean;
  onClose: () => void;
  content: string;
  onSave?: (newContent: string) => void;
  origenLectura?: string;
  isAirtable?: boolean;
}
interface DataItem {
  [key: string]: string | number;
  rowId: number;
}

const ModalEditContent: React.FC<ModalEditContentProps> = ({
  visible,
  onClose,
  content,
  onSave,
  origenLectura = 'web',
  isAirtable = false
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [tableData, setTableData] = useState<DataItem[]>([]);
  const { TextArea } = Input;

  useEffect(() => {
    if (!visible) {
      setIsEditing(false);
      setEditedContent(content);
    }
  }, [visible, content]);

  useEffect(() => {
    setEditedContent(content);
  }, [content]);

  useEffect(() => {
    setEditedContent(content);
    if (content && origenLectura === 'google_sheets') {
      const parsedData = parseGoogleSheetsContent(content);
      setTableData(parsedData);
    }
  }, [content, origenLectura]);

  const parseGoogleSheetsContent = (content: string) => {
    const lines = content.trim().split('\n')
      .filter(line => !line.includes('Hoja:'));
    const headers = lines[0].split(/\s+/).filter(Boolean);
    const rows = lines.slice(1).map((line, index) => {
      const values = line.split(/\s+/).filter(Boolean);
      const row: DataItem = { rowId: index };
      headers.forEach((header, colIndex) => {
        row[header] = values[colIndex] || '';
      });
      return row;
    });

    return rows;
  };

  const renderGoogleSheetsContent = () => {
    try {
      if (tableData.length === 0) {
        return <div>No hay datos disponibles</div>;
      }
      const headers = content.trim().split('\n')
        .filter(line => !line.includes('Hoja:'))[0]
        .split(/\s+/)
        .filter(Boolean);
      const columns = headers.map((key, headerIndex) => ({
        title: isEditing ? (
          <Input
            defaultValue={key}
            onChange={(e) => {
              const newHeaders = [...headers];
              newHeaders[headerIndex] = e.target.value;
              const newData = tableData.map(row => {
                const oldValue = row[key];
                const newRow = {
                  ...row,
                  [e.target.value]: oldValue
                };
                delete newRow[key];
                return newRow;
              });

              setTableData(newData);
              const newContent = 'Hoja: Sheet1\n' +
                newHeaders.join('\t') + '\n' +
                newData.map(row =>
                  newHeaders.map(h => row[h] || '').join('\t')
                ).join('\n');
              setEditedContent(newContent);
            }}
            onClick={(e) => e.stopPropagation()}
            onPressEnter={(e) => e.preventDefault()}
            style={{
              width: '100%',
              padding: '4px 8px',
              border: '1px solid #d9d9d9',
              borderRadius: '2px'
            }}
          />
        ) : key,
        dataIndex: key,
        key: key,
        width: 100,
        ellipsis: true,
        render: (text: string, record: any, index: number) => {
          if (isEditing) {
            return (
              <Input
                defaultValue={text}
                onChange={(e) => {
                  const newData = [...tableData];
                  const dataIndex = newData.findIndex(item => item.rowId === record.rowId);
                  if (dataIndex > -1) {
                    newData[dataIndex] = {
                      ...newData[dataIndex],
                      [key]: e.target.value
                    };
                    setTableData(newData);
                    const newContent = 'Hoja: Sheet1\n' +
                      headers.join('\t') + '\n' +
                      newData.map(row =>
                        headers.map(h => row[h] || '').join('\t')
                      ).join('\n');
                    setEditedContent(newContent);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                onPressEnter={(e) => e.preventDefault()}
                style={{
                  width: '100%',
                  padding: '4px 8px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px'
                }}
              />
            );
          }
          return text;
        }
      }));

      return (
        <Table
          dataSource={tableData.map(item => ({
            ...item,
            key: item.rowId
          }))}
          columns={columns}
          scroll={{ x: 'max-content', y: 400 }}
          size="small"
          pagination={{
            pageSize: 10,
            total: tableData.length,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} registros`
          }}
        />
      );
    } catch (error) {
      console.error('Error al parsear el contenido JSON:', error);
      console.error('Contenido problemático:', content);
      return (
        <div style={{ color: 'red', padding: '20px' }}>
          Error al cargar los datos de Google Sheets.
          <br />
          Detalles: {error instanceof Error ? error.message : 'Error desconocido'}
        </div>
      );
    }
  };

  const parseAirtableContent = (content: string) => {
    try {
      if (!content) {
        return { headers: [], rows: [] };
      }
      const lines = content.trim().split('\n')
        .filter(line => line.trim() !== '');
      if (lines.length === 0) {
        return { headers: [], rows: [] };
      }
      let headers: string[] = [];
      let dataRows: string[] = [];
      if (content.includes('\t')) {
        const allLines = content.trim().split('\n');
        headers = allLines[0].split('\t').map(h => h.trim());
        dataRows = allLines.slice(1);

        const rows = dataRows.map((line, index) => {
          const values = line.split('\t');
          const row: DataItem = { rowId: index };
          headers.forEach((header, i) => {
            row[header] = values[i] || '';
          });
          return row;
        });

        return { headers, rows };
      }

      const headerLine = lines.find(line => line.startsWith('Headers:'));
      if (headerLine) {
        headers = headerLine.replace('Headers:', '').trim().split(/\s+/);
        const dataStartIndex = lines.findIndex(line => line.startsWith('Headers:')) + 1;

        const rows = lines.slice(dataStartIndex)
          .filter(line => !line.startsWith('Table:') && !line.startsWith('Headers:'))
          .map((line, index) => {
            const values = line.trim().split(/\s+/);
            const row: DataItem = { rowId: index };
            headers.forEach((header, i) => {
              row[header] = values[i] || '';
            });
            return row;
          });

        return { headers, rows };
      }

      return {
        headers: ['Contenido'],
        rows: [{ rowId: 0, Contenido: content }]
      };
    } catch (error) {
      console.error('Error parsing Airtable content:', error);
      return { headers: [], rows: [] };
    }
  };

  const renderAirtableContent = (content: string, isEditing: boolean, handleContentChange: (newContent: string) => void) => {
    try {
      const { headers, rows } = parseAirtableContent(content);

      if (!headers.length) {
        return <div>No hay datos disponibles</div>;
      }
      const columns = headers.map(key => ({
        title: key,
        dataIndex: key,
        key: key,
        width: 150,
        ellipsis: true,
        render: (text: string, record: any) => {
          if (isEditing) {
            return (
              <Input
                defaultValue={text}
                onChange={(e) => {
                  const newRows = [...rows];
                  const dataIndex = newRows.findIndex(item => item.rowId === record.rowId);
                  if (dataIndex > -1) {
                    newRows[dataIndex] = {
                      ...newRows[dataIndex],
                      [key]: e.target.value
                    };
                    let newContent = '';
                    if (content.includes('\t')) {
                      newContent = headers.join('\t') + '\n' +
                        newRows.map(row =>
                          headers.map(h => row[h] || '').join('\t')
                        ).join('\n');
                    } else {
                      newContent = 'Headers: ' + headers.join(' ') + '\n' +
                        newRows.map(row =>
                          headers.map(h => row[h] || '').join(' ')
                        ).join('\n');
                    }

                    handleContentChange(newContent);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                onPressEnter={(e) => e.preventDefault()}
                style={{
                  width: '100%',
                  padding: '4px 8px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px'
                }}
              />
            );
          }
          return text;
        }
      }));

      return (
        <Table
          dataSource={rows.map(item => ({
            ...item,
            key: item.rowId
          }))}
          columns={columns}
          scroll={{ x: 'max-content', y: 400 }}
          size="small"
          pagination={{
            pageSize: 10,
            total: rows.length,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} registros`
          }}
        />
      );
    } catch (error) {
      console.error('Error al renderizar la tabla:', error);
      return (
        <div style={{ color: 'red', padding: '20px' }}>
          Error al cargar los datos de Airtable.
          <br />
          Detalles: {error instanceof Error ? error.message : 'Error desconocido'}
        </div>
      );
    }
  };

  const handleSave = () => {
    onSave?.(editedContent);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedContent(content);
    setTableData(parseGoogleSheetsContent(content));
    setIsEditing(false);
  };

  const handleClose = () => {
    setIsEditing(false);
    setEditedContent(content);
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      footer={null}
      width="80%"
      title={null}
      closable={false}
      bodyStyle={{ padding: '35px' }}
    >
      <div style={{ position: 'relative', minHeight: '60vh' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <h3 style={{ margin: 0, fontSize: '24px' }}>Contenido</h3>
          <Space size="middle">
            {!isEditing ? (
              <EditOutlined
                onClick={() => setIsEditing(true)}
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                  color: '#1890ff'
                }}
              />
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  danger
                  size="small"
                  style={{
                    marginRight: '2px',
                    color: '#ff4d4f',
                    borderColor: '#ff4d4f',
                    backgroundColor: 'white',
                    opacity: 1,
                    display: 'inline-block'
                  }}
                >
                  <span style={{ color: '#ff4d4f' }}>Cancelar</span>
                </Button>
                <SaveOutlined
                  onClick={handleSave}
                  style={{
                    fontSize: '24px',
                    cursor: 'pointer',
                    color: '#52c41a'
                  }}
                />
              </>
            )}
            <img
              src={IconClosed}
              alt="Cerrar"
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                width: '24px',
                height: '24px'
              }}
            />
          </Space>
        </div>

        <div style={{
          position: 'relative',
          border: '1px solid #e8e8e8',
          borderRadius: '8px',
          padding: '20px',
          minHeight: '400px',
          backgroundColor: '#fafafa',
        }}>
          {origenLectura === 'google_sheets' ? (
            renderGoogleSheetsContent()
          ) : isAirtable ? (
            renderAirtableContent(editedContent, isEditing, setEditedContent)
          ) : (
            isEditing ? (
              <TextArea
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                style={{
                  height: '400px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  resize: 'none',
                  padding: '0'
                }}
              />
            ) : (
              <div style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowY: 'auto',
                maxHeight: '400px'
              }}>
                {content}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditContent;