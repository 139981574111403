import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { WechatOutlined, WechatWorkOutlined, SlidersOutlined, FormatPainterOutlined, InteractionOutlined, PieChartOutlined, OrderedListOutlined, UsbTwoTone, RobotOutlined } from '@ant-design/icons';
import '../../styles/pages/chat/Chat.css';
import TabChat from './tabs/chat/TabChat';
import TabCreateEdit from './tabs/createEdit/TabCreateEdit';
import TabTrain from './tabs/train/TabTrain';
import TabConversations from './tabs/conversations/TabConversations';
import TabDesign from './tabs/design/TabDesign';
import TabAnalytics from './tabs/analytics/TabAnalytics';
import TabIntegration from './tabs/integrations/TabIntegration';
import TabKnowledgeBase from './tabs/knowledgeBase/TabKnowledgeBase';
import { message, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SelectChatBot from './tabs/selectChatBot/SelectChatBot';
import { AppDispatch, RootState } from '../../redux/store/store';
import SpotlightTour from '../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../components/pages/ShadowComponent/TourContext';
import { GetChatbotByToken, SelectBotReducer, UpdateChatSeccionadoHome } from '../../redux/actions/home/Home';
import { ResetConversationReducer } from '../../redux/actions/chatBots/Chat/Chat';

const Chat: React.FC = () => {
  const params = useParams<{ chatToken: string }>();
  const { rex_user_auth } = useSelector(({ auth }: any) => auth);
  const { rex_chatsbots, rex_chat_selecccionado, rex_token_chat } = useSelector(({ home }: RootState) => home);
  const { rex_chatbot_seleccionado } = useSelector((state: RootState) => state.home);

  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const [disabledTabs, setDisabledTabs] = useState<string[]>([]);
  const { setCurrentView, setCurrentSubView } = useTour();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  // Mapeo de rutas a keys de tabs
  const PATH_TO_TAB_KEY: { [key: string]: string } = {
    '': '1',
    'chatbots': '2',
    'edit': '3',
    'train': '4',
    'knowledge-base': '5',
    'conversations': '6',
    'design': '7',
    'analytics': '8',
    'integrations': '9'
  };

  const listTabs = [
    {
      key: '1',
      icon: RobotOutlined,
      title: 'CHATBOTS',
      component: <SelectChatBot />,
      path: '',
      slug: 'show.module.chats.select-chat',
      className: 'chatbots-button'
    },
    {
      key: '2',
      icon: WechatOutlined,
      title: 'CHAT',
      component: <TabChat />,
      path: '/chatbots', slug: 'show.module.chats.chat', 
      className: 'chat-button'
    },
    { 
      key: '3', 
      icon: WechatWorkOutlined, 
      title: 'EDITAR', 
      component: <TabCreateEdit />, 
      path: '/edit', 
      slug: 'show.module.chats.edit', 
      className: 'edits-button' 
    },
    { 
      key: '4', 
      icon: SlidersOutlined, 
      title: 'ENTRENAR', 
      component: <TabTrain />, 
      path: '/train', 
      slug: 'show.module.chats.train', 
      className: 'train-button' 
    },
    { 
      key: '5', 
      icon: UsbTwoTone, 
      title: 'BASE DE CONOCIMIENTOS', 
      component: <TabKnowledgeBase />, 
      path: '/knowledge-base', 
      slug: 'show.module.chats.knowledge-base', 
      className: 'knowledge-button' 
    },
    { 
      key: '6', 
      icon: OrderedListOutlined, 
      title: 'CONVERSACIONES', 
      component: <TabConversations />, 
      path: '/conversations', 
      slug: 'show.module.chats.conversations', 
      className: 'conversations-button' 
    },
    { 
      key: '7', 
      icon: FormatPainterOutlined, 
      title: 'DISEÑO', 
      component: <TabDesign />, 
      path: '/design', 
      slug: 'show.module.chats.design', 
      className: 'design-button' 
    },
    { 
      key: '8', 
      icon: PieChartOutlined, 
      title: 'ANALYTICS', 
      component: <TabAnalytics />, 
      path: '/analytics', 
      slug: 'show.module.chats.analytics', 
      className: 'analytics-button'
    },
    { 
      key: '9', 
      icon: InteractionOutlined, 
      title: 'INTEGRACIONES', 
      component: <TabIntegration />, 
      path: '/integrations', 
      slug: 'show.module.chats.integrations', 
      className: 'integrations-button'
    },
  ];

  // Efecto para manejar la inicialización y persistencia del estado
  // En Chat.tsx, modifica el useEffect que maneja la inicialización

  useEffect(() => {
    if (params.chatToken) {
      const selectedBot = rex_chatsbots.find((bot: any) => bot.token === params.chatToken);
      const pathSegments = location.pathname.split('/');
      const lastSegment = pathSegments[pathSegments.length - 1];
      const tabKey = PATH_TO_TAB_KEY[lastSegment] || '1';
      if (selectedBot) {
        // Si el bot está en la lista del usuario actual
        if (rex_token_chat !== selectedBot.token) {
          dispatch(SelectBotReducer(rex_chatsbots.indexOf(selectedBot), true));       
          dispatch(UpdateChatSeccionadoHome(selectedBot.token));
        }
      } else {
        // Si el bot no está en la lista, obtenerlo usando GetChatbotByToken
        dispatch(GetChatbotByToken(params.chatToken)).then(() => {
          // El estado se actualizará automáticamente a través del reducer
          setActiveTabKey(tabKey);
        });
      }

      setActiveTabKey(tabKey);
      setDisabledTabs([]);
    }
  }, [params.chatToken, location.pathname, rex_chatsbots]);

  // También necesitamos modificar el efecto que maneja la sincronización de URL
  useEffect(() => {
    const currentPath = location.pathname.split('/').slice(3).join('/');

    if (rex_chat_selecccionado || rex_token_chat) {
      const token = rex_token_chat || params.chatToken;
      if (token && params.chatToken !== token) {
        navigate(`/chats/${token}/${currentPath || ''}`, { replace: true });
      }
    }
  }, [rex_chat_selecccionado, rex_token_chat]);

  useEffect(() => {
    if (!params.chatToken && rex_chatbot_seleccionado && !rex_chatsbots.find(bot => bot.token === rex_chatbot_seleccionado.token)) {
      setDisabledTabs(['4', '5', '6', '8', '9']);
    } else {
      setDisabledTabs([]);
    }
  }, [rex_chatbot_seleccionado, rex_chatsbots, params.chatToken]);


  useEffect(() => {
    if (rex_token_chat && params.chatToken && rex_token_chat !== params.chatToken) {
      console.warn('Token mismatch detected:', {
        reduxToken: rex_token_chat,
        urlToken: params.chatToken
      });
    }
  }, [rex_token_chat, params.chatToken]);

  useEffect(() => {
    setCurrentView('chat');
    return () => setCurrentView('');
  }, []);

  const getPath = (basePath: string) => {
    return rex_token_chat ? `/chats/${rex_token_chat}${basePath}` : '/chats';
  };

  const handleTabChange = (key: string) => {
    const tab = listTabs.find((tab) => tab.key === key);

    if (!tab) return;

    dispatch(ResetConversationReducer())
    // Allow access to chatbots (key '1') regardless of selection
    if (key !== '1' && !rex_token_chat) {
      message.warning('Por favor seleccione un chat primero');
      return;
    }

    setActiveTabKey(key);
    setCurrentSubView(tab.title.toLowerCase());
    navigate(getPath(tab.path));
  };

  useEffect(() => {
    // Disable all tabs except chatbots (1) when no chat is selected
    if (!rex_token_chat) {
      setDisabledTabs(['2', '3', '4', '5', '6', '7', '8', '9']);
      if (activeTabKey !== '1') {
        handleTabChange('1');
      }
    } else {
      setDisabledTabs([]);
    }
  }, [rex_token_chat]);

  return (
    <div className="tabs-container">
      <SpotlightTour steps={tourData.Chats} view="chat" />
      <div className="tabs-list">
        {listTabs.map((tab) => {
          const accessTab = rex_user_auth.permisos.some((permiso: any) => permiso.slug === tab.slug);
          return (
            <div
              key={tab.key}
              className={`tab-item ${tab.className} ${activeTabKey === tab.key ? 'active' : ''}`}
              onClick={() => accessTab && !disabledTabs.includes(tab.key) && handleTabChange(tab.key)}
            >
              <tab.icon className="tab-icon" />
              {accessTab ? tab.title : <Tooltip title="No access">{tab.title}</Tooltip>}
            </div>
          );
        })}
      </div>
      <div className="tab-content">
        <div className="bost-d">
          {listTabs.find((tab) => tab.key === activeTabKey)?.component}
        </div>
      </div>
    </div>
  );
};

export default Chat;