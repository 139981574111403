import React, { useEffect, useState } from 'react';
import { Button, Card, Input, message, Steps, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import config from '../../../../config';

const API_URL = config.API_URL;

const ApiIntegration = () => {

  const [current, setCurrent] = useState(0);
  const [txtEmbebido, setTxtEmbebido] = useState("");

  const { rex_token_chat, rex_chatsbots } = useSelector((state: RootState) => state.home);
  const { rex_user_auth } = useSelector((state: RootState) => state.auth);

    const generateCodeEmbedded = () => {
      if(!rex_token_chat){
        message.warning('Por favor seleccione un chat primero');
        return;
      }

      if(!rex_user_auth){
        message.warning('Ha ocurrido un error, por favor recargue la página');
        return
      }else{

        setTxtEmbebido(
`------------- Código en javascript -------------\n
//Crear conversacion con chatbot
async function createConversation() {
  const url = "${API_URL}chatbots/${rex_token_chat}/conversaciones/user/${rex_user_auth.token_integracion}";
  try {
    const response = await fetch(url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Ha ocurrido un error al llamar a la API');
    }
    const json = await response.json();
    const rpta = json.data[0]

    //localStorage.setItem('${rex_token_chat}', rpta.identificador);
    console.log("Id conversacion: " + rpta.identificador)
  } catch (error) {
    console.error(error.message);
  }
}\n
//Conversar con chatbot
async function sendMessageChatbot(message) {

  //const conversationId = localStorage.getItem('${rex_token_chat}');
  const conversationId = 'Aqui el id de la conversacion';

  const url = "${API_URL}chatbots/${rex_token_chat}/conversaciones/" + conversationId + "/mensajes/user/${rex_user_auth.token_integracion}";
  try {
    const response = await fetch(url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contenido: message,
          emisor: 'USUARIO',
          entrenamiento: false
        })
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Ha ocurrido un error al llamar a la API');
    }
    const response_chatbot = await response.json();
    console.log("response_chatbot: ", response_chatbot.contenido)
    return response_chatbot
  } catch (error) {
    console.error(error.message);
  }
}
------------- Código en PHP -------------\n
//Crear conversacion con chatbot
function createConversation() {
  $url = "${API_URL}chatbots/${rex_token_chat}/conversaciones/user/${rex_user_auth.token_integracion}";
  $ch = curl_init($url);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_POST, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, [
    'Content-Type: application/json',
  ]);

  $response = curl_exec($ch);

  if (curl_errno($ch)) {
    echo "Error: " . curl_error($ch);
  } else {
    $httpStatusCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
    if ($httpStatusCode >= 200 && $httpStatusCode < 300) {
      $data = json_decode($response, true);
      $conversationId = $data['data'][0]['identificador'];
      echo 'Id conversacion: '.$conversationId;
    } else {
      echo "Error en la solicitud. Código de estado: " . $httpStatusCode;
    }
  }
  curl_close($ch);
}
//Conversar con chatbot
function sendMessageChatbot($message) {

  $conversationId = 'Aqui el id de la conversacion';

  $url = "${API_URL}chatbots/${rex_token_chat}/conversaciones/" . $conversationId . "/mensajes/user/${rex_user_auth.token_integracion}";

  $data = [
    'contenido' => $message,
    'emisor' => 'USUARIO',
    'entrenamiento' => false
  ];

  $ch = curl_init();

  curl_setopt($ch, CURLOPT_URL, $url);
  curl_setopt($ch, CURLOPT_POST, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, [
    'Content-Type: application/json',
  ]);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

  $response = curl_exec($ch);

  if (curl_errno($ch)) {
    echo "Error en cURL: " . curl_error($ch);
    curl_close($ch);
    return null;
  }

  $httpStatusCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
  curl_close($ch);
  if ($httpStatusCode >= 200 && $httpStatusCode < 300) {
    $data = json_decode($response, true); 
    echo $data['contenido'];
    return $data;
  } else {
    echo "Error en la solicitud. Código de estado: $httpStatusCode";
    return null;
  }
}
`)
      }
    }
  

  const steps = [
    {
      title: 'Paso 1: Generar codigo API',
      content: <>
        <div>
          <div>
            1) Generar codigo y pegar en la integracion<br />
            <Button
              onClick={() => generateCodeEmbedded()}
            >Generar Codigo</Button>
          </div><br />
          <div>
            <div
              style={{
                overflowY: 'auto',
                minHeight: '200px',
                maxHeight: '600px',
                width: "100%",
                background: '#f4f4f4',
                border: '1px solid gray',
                borderRadius: '15px'
              }}
            >
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '10px'
                }}
              >
                <pre><b>{txtEmbebido}</b></pre>
              </div>
            </div>
          </div>
        </div>
      </>,
    },
  ]

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Steps current={current} items={items} />
      <Card style={{ marginTop: 24 }}>
        <div style={{ marginTop: 24 }}>
          {
            steps[current].content
          }
        </div>
      </Card>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Siguiente
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Finalizar
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Anterior
          </Button>
        )}
      </div>

    </>
  )
}

export default ApiIntegration