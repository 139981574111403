import React, { useEffect, useState, useContext } from 'react';
import { Tabs, Card, Input, Button, Typography, Space, Upload, Row, Checkbox, message, Col, Skeleton, Modal, Pagination, Select, Tooltip } from 'antd';
import { InfoCircleFilled, DownOutlined, UploadOutlined, EditOutlined, DeleteOutlined, SyncOutlined, CopyOutlined, GlobalOutlined, ContainerOutlined, KeyOutlined, BookOutlined } from '@ant-design/icons';
import { submitFormDataEntrenamiento, UploadTrainingData } from '../../../../redux/actions/chatBots/Chat/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { RcFile } from 'antd/es/upload';
import { updateChatbotBehavior } from '../../../../redux/actions/home/homeActions';
import ModalText from './ModalText';
import SpotlightTour from '../../../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../../../components/pages/ShadowComponent/TourContext';
import {
  FechCreateLecturaWebReducer, GetLecturasWebReducer, DeleteLecturaWebReducer, UpdateLecturaWebReducer, setCurrentPage,
  UpdateLecturaContentReducer, UpdateSubUrlContentReducer, DeleteSubUrlReducer, UpdateLecturaManualContentReducer,
  UpdateSubUrlManualContentReducer, GetAirtableConfigsReducer, FetchAirtableDataReducer, UpdateAirtableBaseReducer,
  DeleteAirtableBaseReducer, UpdateAirtableTableContentReducer, EditAirtableContentReducer, DeleteAirtableTableReducer,
  setCurrentAirtablePage, AddSubUrlReducer, UpdateUserAirtablesReducer, UpdateFullAirtableBaseReducer,
  AddAirtableTableReducer
} from '../../../../redux/actions/lecturas/LecturaWeb'
import CustomButton from '../../../../components/pages/chat/CustomButon';
import ModalSubUrls from './ModalSubUrls';
import ModalEditContent from './ModalConstent';
import ModalTutorialAirtable from './ModalTutorialAirtable';
import ModalAirtableTables from './ModalAirtableTables';
import GoogleDoc from '../../../../assets/img/airTable/google-docs.png';
import GoogleSheet from '../../../../assets/img/airTable/google-sheets.png';
import GoogleSlider from '../../../../assets/img/airTable/google-slides.png';
import Google from '../../../../assets/img/airTable/google.png';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

export interface SubUrl {
  id: number;
  sub_url: string;
  leyo: boolean;
  contenido: string;
}

interface AirtableTable {
  id: number;
  table_id: string;
  table_nombre: string;
  leyo: boolean;
  contenido: string;
  created_at: string;
  updated_at: string;
}

interface AirtableBase {
  id: number;
  base_id: string;
  base_nombre: string;
  tables: AirtableTable[];
  created_at: string;
  updated_at: string;
}

const TabKnowledgeBase: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [overwrite, setOverwrite] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [textInput, setTextInput] = useState('Eres un vendedor de componentes de computo, mouse, teclado, cable de red, parlantes, etc.');
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalText, setModalText] = useState(textInput);
  const { setCurrentView } = useTour();
  const [nombreLectura, setNombreLectura] = useState('');
  const [urlLectura, setUrlLectura] = useState('');
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [editNombre, setEditNombre] = useState('');
  const [editUrl, setEditUrl] = useState('');
  const [editId, setEditId] = useState<number | null>(null);
  const [modalSubUrlsVisible, setModalSubUrlsVisible] = useState(false);
  const [selectedLecturaSubUrls, setSelectedLecturaSubUrls] = useState<any[]>([]);
  const [selectedLecturaId, setSelectedLecturaId] = useState<number | null>(null);
  const [selectedLectura, setSelectedLectura] = useState<any>(null);
  const [searchNombre, setSearchNombre] = useState('');
  const [searchUrl, setSearchUrl] = useState('');
  const [isContentModalVisible, setIsContentModalVisible] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [isSubUrlContentModalVisible, setIsSubUrlContentModalVisible] = useState(false);
  const [selectedSubUrlContent, setSelectedSubUrlContent] = useState("");
  const [selectedSubUrlId, setSelectedSubUrlId] = useState<number | null>(null);
  const [origenLectura, setOrigenLectura] = useState('web');
  const [airtableConfigs, setAirtableConfigs] = useState<any[]>([]);
  const [nombreBase, setNombreBase] = useState('');
  const [baseId, setBaseId] = useState('');
  const [isAirtableTablesModalVisible, setIsAirtableTablesModalVisible] = useState(false);
  const [selectedAirtableBase, setSelectedAirtableBase] = useState<AirtableBase | null>(null);
  const [selectedTableBaseId, setSelectedTableBaseId] = useState<number | null>(null);
  const [airtablePage, setAirtablePage] = useState(1);
  const pageSize = 5;
  const totalCharacters = textInput.length;
  const knowledgeBaseTourSteps = tourData.KnowledgeBase;
  const [isAirtableContentModalVisible, setIsAirtableContentModalVisible] = useState(false);
  const [selectedAirtableContent, setSelectedAirtableContent] = useState("");
  const [selectedAirtableConfigId, setSelectedAirtableConfigId] = useState<number | null>(null);
  const [isAddSubUrlModalVisible, setIsAddSubUrlModalVisible] = useState(false);
  const [newSubUrl, setNewSubUrl] = useState('');
  const { rex_chatbot_seleccionado } = useSelector(({ home }: any) => home);
  const { rex_lecturas, rex_loading, airtable_loading, rex_total = 1, rex_page = 1, airtable_bases, airtable_total = 1, airtable_page = 1 } = useSelector((state: RootState) => state.lectura);
  const [isApiConfigModalVisible, setIsApiConfigModalVisible] = useState(false);
  const { rex_user_auth } = useSelector(({ auth }: any) => auth);
  const [apiConfig, setApiConfig] = useState({
    api_key_airtables: rex_user_auth?.api_key_airtables || '',
    base_id_airtables: rex_user_auth?.base_id_airtables || ''
  });
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false);
  const [isAddTableModalVisible, setIsAddTableModalVisible] = useState(false);
  const [newTableId, setNewTableId] = useState('');


  const handlePageChange = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(GetLecturasWebReducer(page, pageSize));
  };

  const [editAirtableModalVisible, setEditAirtableModalVisible] = useState(false);
  const [editAirtableConfig, setEditAirtableConfig] = useState({
    id: null,
    base_nombre: '',
    base_id: ''
  });

  useEffect(() => {
    if (rex_chatbot_seleccionado) {
      setTextInput(rex_chatbot_seleccionado.comportamiento || '');
    }
  }, [rex_chatbot_seleccionado]);

  useEffect(() => {
    setCurrentView('knowledgeBase');
    return () => setCurrentView('');
  }, [setCurrentView]);

  useEffect(() => {
    dispatch(GetLecturasWebReducer(1, pageSize));
    dispatch(setCurrentPage(1));
  }, [pageSize]);

  const handleAirtablePageChange = (page: number) => {
    dispatch(setCurrentAirtablePage(page));
    dispatch(GetAirtableConfigsReducer(page, pageSize));
  };

  useEffect(() => {
    dispatch(GetAirtableConfigsReducer());
    setAirtablePage(1);
    dispatch(setCurrentAirtablePage(1));
  }, []);

  useEffect(() => {
    if (rex_user_auth) {
      setApiConfig({
        api_key_airtables: rex_user_auth.api_key_airtables || '',
        base_id_airtables: rex_user_auth.base_id_airtables || ''
      });
    }
  }, [rex_user_auth]);

  const handleAddLectura = async () => {
    if (!nombreLectura || !urlLectura) {
      message.error('Complete todos los campos');
      return;
    }
    try {
      let urlFormateada = urlLectura;
      if (!/^https?:\/\//i.test(urlLectura)) {
        urlFormateada = `https://${urlLectura}`;
      }
      urlFormateada = urlFormateada.replace(/\/$/, '');
  
      // URL validation based on type
      if (origenLectura === 'google_sheets' && !urlFormateada.includes('docs.google.com/spreadsheets')) {
        throw new Error('La URL debe ser de Google Sheets');
      }
      if (origenLectura === 'google_docs' && !urlFormateada.includes('docs.google.com/document')) {
        throw new Error('La URL debe ser de Google Docs');
      }
      if (origenLectura === 'google_slides' && !urlFormateada.includes('docs.google.com/presentation')) {
        throw new Error('La URL debe ser de Google Slides');
      }
  
      const result = await dispatch(FechCreateLecturaWebReducer(
        urlFormateada,
        nombreLectura,
        origenLectura
      ));
  
      if (!result.respuesta) {
        throw new Error(result.mensaje_dev || result.mensaje || 'Error al procesar la URL');
      }
  
      message.success('Lectura creada exitosamente');
      setNombreLectura('');
      setUrlLectura('');
      await dispatch(GetLecturasWebReducer(rex_page, pageSize));
    } catch (error: any) {
      console.error('Error:', error);
      message.error(error.message || 'Error al crear la lectura');
    }
  };

  const handleInputClick = () => {
    setModalText(textInput);
    setVisibleModal(true);
  };

  const handleModalCancel = () => {
    setVisibleModal(false);
  };

  const handleSearch = () => {
    dispatch(GetLecturasWebReducer(1, pageSize, {
      nombre: searchNombre,
      url: searchUrl
    }));
  };

  const handleModalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setModalText(e.target.value);
    setTextInput(e.target.value);
  };

  const handleFileChange = (info: any) => {
    const { file } = info;
    if (file.status === 'removed') {
      setFile(null);
    } else {
      setFile(file);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      console.error('Solo se permiten archivos CSV');
    }
    return false;
  };

  const handleCheckboxChange = (e: any) => {
    setOverwrite(e.target.checked);
  };

  const handleSubmit = async () => {
    if (!file) {
      message.error('Por favor, seleccione un archivo CSV antes de subir.');
      return;
    }
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('sobrescribir', overwrite.toString());
      formData.append('archivo', file);

      const formDataObject: Record<string, any> = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      const data = await dispatch(submitFormDataEntrenamiento(formData));
      const response = await dispatch(UploadTrainingData(formData));
      if (response) {
        message.success('Archivo subido exitosamente');
      } else {
        message.error('Error al subir el archivo');
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      message.error('Ocurrió un error al subir el archivo');
    } finally {
      setIsUploading(false);
    }
  };

  const handleEditLectura = (lectura: any) => {
    console.log("Editando lectura:", lectura);
    setEditId(lectura.id);
    setEditNombre(lectura.nombre);
    setEditUrl(lectura.url);
    setModalEditVisible(true);
  };

  const handleEditConfirm = async () => {
    try {
      if (!editId || !editNombre || !editUrl) {
        message.error('Todos los campos son requeridos');
        return;
      }
      await dispatch(UpdateLecturaWebReducer(editId, editNombre.trim(), editUrl.trim()));
      setModalEditVisible(false);
      setEditId(null);
      setEditNombre('');
      setEditUrl('');
      message.success('Lectura actualizada exitosamente');
      await dispatch(GetLecturasWebReducer(rex_page, pageSize));
    } catch (error) {
      console.error('Error al actualizar:', error);
      message.error('Error al actualizar la lectura');
    }
  };

  const handleDeleteLectura = async (id: number) => {
    Modal.confirm({
      title: '¿Está seguro de eliminar esta lectura?',
      okText: 'Sí',
      cancelText: 'No',
      onOk: async () => {
        await dispatch(DeleteLecturaWebReducer(id));
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      }
    });
  };

  const handleUpdateContent = async (lectura: any) => {
    try {
      const result = await dispatch(UpdateLecturaContentReducer(lectura.id));
      if (result.respuesta) {
        message.success('Contenido actualizado exitosamente');
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar el contenido');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar el contenido');
    }
  };

  const handleRetrainChatbot = async () => {
    if (!rex_chatbot_seleccionado || !rex_chatbot_seleccionado.id) {
      message.error('No hay chatbot seleccionado para actualizar.');
      return;
    }
    const success = await dispatch(updateChatbotBehavior(textInput, rex_chatbot_seleccionado.id));
    if (success) {
      message.success('El comportamiento del chatbot ha sido actualizado.');
    } else {
      message.error('Error al actualizar el comportamiento del chatbot.');
    }
  };

  const handleDownloadExampleCSV = () => {
    const fileUrl = '/uploads/Lista.xlsx';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Lista.xlsx';
    link.click();
  };

  const handleLecturaClick = (lectura: any) => {
    setSelectedLecturaId(lectura.id);
    setSelectedLectura(lectura);
    setSelectedLecturaSubUrls(lectura.sub_urls || []);
    setModalSubUrlsVisible(true);
  };

  const handleCopyUrl = (url: string) => {
    navigator.clipboard.writeText(url).then(() => {
      message.success('URL copiada al portapapeles');
    }).catch(() => {
      message.error('Error al copiar la URL');
    });
  };

  const handleOpenUrl = (url: string) => {
    window.open(url, '_blank');
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-PE');
  };

  const handleUpdateSubUrl = async (subUrl: SubUrl) => {
    try {
      if (!selectedLecturaId) return;
      const result = await dispatch(UpdateSubUrlContentReducer(selectedLecturaId, subUrl.id));
      if (result.respuesta) {
        message.success('Sub URL actualizada exitosamente');
        setSelectedLecturaSubUrls(prevSubUrls =>
          prevSubUrls.map(url =>
            url.id === subUrl.id
              ? { ...url, ...result.data }
              : url
          )
        );
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar la sub URL');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar la sub URL');
    }
  };

  const handleDeleteSubUrl = async (subUrl: SubUrl) => {
    if (!selectedLecturaId) return;

    Modal.confirm({
      title: '¿Está seguro de eliminar esta sub URL?',
      okText: 'Sí',
      cancelText: 'No',
      onOk: async () => {
        try {
          await dispatch(DeleteSubUrlReducer(selectedLecturaId, subUrl.id));
          message.success('Sub URL eliminada exitosamente');
          setSelectedLecturaSubUrls(prevSubUrls =>
            prevSubUrls.filter(url => url.id !== subUrl.id)
          );
          await dispatch(GetLecturasWebReducer(rex_page, pageSize));
        } catch (error: any) {
          message.error(error.message || 'Error al eliminar la sub URL');
        }
      }
    });
  };

  const handleShowContent = (lectura: any) => {
    setSelectedContent(lectura.contenido || "No hay contenido disponible");
    setSelectedLecturaId(lectura.id);
    setSelectedLectura(lectura);
    setIsContentModalVisible(true);
  };

  const handleSaveContent = async (newContent: string) => {
    try {
      if (!selectedLecturaId) return;
      const result = await dispatch(UpdateLecturaManualContentReducer(selectedLecturaId, newContent));
      if (result.respuesta) {
        message.success('Contenido actualizado exitosamente');
        setSelectedContent(newContent);
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar el contenido');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar el contenido');
    }
  };

  const handleShowSubUrlContent = (subUrl: SubUrl) => {
    setSelectedSubUrlContent(subUrl.contenido || "No hay contenido disponible");
    setSelectedSubUrlId(subUrl.id);
    setIsSubUrlContentModalVisible(true);
  };

  const handleSaveSubUrlContent = async (newContent: string) => {
    try {
      if (!selectedLecturaId || !selectedSubUrlId) return;

      const result = await dispatch(UpdateSubUrlManualContentReducer(
        selectedLecturaId,
        selectedSubUrlId,
        newContent
      ));

      if (result.respuesta) {
        message.success('Contenido de sub URL actualizado exitosamente');
        setSelectedSubUrlContent(newContent);
        setSelectedLecturaSubUrls(prevSubUrls =>
          prevSubUrls.map(url =>
            url.id === selectedSubUrlId
              ? { ...url, contenido: newContent, leyo: true }
              : url
          )
        );
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar el contenido');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar el contenido');
    }
  };

  const handleAddAirtableConfig = async () => {
    if (!nombreBase || !baseId) {
      message.error('Complete todos los campos');
      return;
    }
    try {
      const result = await dispatch(FetchAirtableDataReducer(
        baseId.trim(),
        nombreBase.trim()
      ));

      if (!result.respuesta) {
        throw new Error(result.mensaje || 'Error al procesar la base de Airtable');
      }

      message.success('Base de Airtable añadida exitosamente');
      setNombreBase('');
      setBaseId('');
      dispatch(GetAirtableConfigsReducer());
    } catch (error: any) {
      console.error('Error:', error);
      message.error(error.message || 'Error al añadir la base de Airtable');
    }
    dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
  };

  const handleEditAirtableModal = (config: any) => {
    setEditAirtableConfig({
      id: config.id,
      base_nombre: config.base_nombre,
      base_id: config.base_id   
    });
    setEditAirtableModalVisible(true);
  };

  const handleSaveAirtableEdit = async () => {
    try {
      if (!editAirtableConfig.id || !editAirtableConfig.base_nombre || !editAirtableConfig.base_id) {
        message.error('Todos los campos son requeridos');
        return;
      }

      await dispatch(UpdateAirtableBaseReducer(
        editAirtableConfig.id,
        {
          base_id: editAirtableConfig.base_id.trim(),
          base_nombre: editAirtableConfig.base_nombre.trim()
        }
      ));

      setEditAirtableModalVisible(false);
      message.success('Configuración actualizada exitosamente');
      dispatch(GetAirtableConfigsReducer());
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar la configuración');
    }
  };

  const handleDeleteAirtableConfig = async (id: number) => {
    Modal.confirm({
      title: '¿Está seguro de eliminar esta configuración?',
      okText: 'Sí',
      cancelText: 'No',
      onOk: async () => {
        try {
          await dispatch(DeleteAirtableBaseReducer(id));
          message.success('Configuración eliminada exitosamente');
          dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
        } catch (error: any) {
          message.error(error.message || 'Error al eliminar la configuración');
        }
      }
    });
  };

  const handleUpdateAirtableContent = async (config: any) => {
    try {
      const result = await dispatch(UpdateFullAirtableBaseReducer(config.id));
      if (result.respuesta) {
        message.success('Base actualizada exitosamente');
        await dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar la base');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar la base');
    }
  };

  const handleSaveAirtableContent = async (newContent: string) => {
    try {
      if (!selectedTableBaseId || !selectedAirtableConfigId) {
        message.error('No se puede identificar la tabla a actualizar');
        return;
      }
      const result = await dispatch(EditAirtableContentReducer(
        selectedTableBaseId,
        selectedAirtableConfigId,
        newContent
      ));

      if (result.respuesta) {
        message.success('Contenido de Airtable actualizado exitosamente');
        setSelectedAirtableContent(newContent);
        if (selectedAirtableBase) {
          const updatedTables = selectedAirtableBase.tables.map(table =>
            table.id === selectedAirtableConfigId
              ? { ...table, contenido: newContent }
              : table
          );

          setSelectedAirtableBase({
            ...selectedAirtableBase,
            tables: updatedTables
          });
        }
        await dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar el contenido de Airtable');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar el contenido de Airtable');
    }
  };

  const handleAddNewSubUrl = async () => {
    try {
      if (!selectedLecturaId || !newSubUrl) {
        message.error('La URL es requerida');
        return;
      }
      const result = await dispatch(AddSubUrlReducer(selectedLecturaId, newSubUrl.trim()));
      if (result.respuesta) {
        message.success('Sub URL agregada exitosamente');
        setSelectedLecturaSubUrls(prevSubUrls => [
          ...prevSubUrls,
          result.data
        ]);
        setNewSubUrl('');
        setIsAddSubUrlModalVisible(false);
        await dispatch(GetLecturasWebReducer(rex_page, pageSize));
      }
    } catch (error: any) {
      const errorMessage = error.message === 'Error al agregar la sub URL'
        ? 'La sub URL debe pertenecer al mismo dominio que la URL principal'
        : error.message;

      message.error(errorMessage);
    }
  };

  const handleSaveApiConfig = async () => {
    try {
      await dispatch(UpdateUserAirtablesReducer(
        apiConfig.api_key_airtables
      ));
      message.success('Configuración de API actualizada exitosamente');
      setIsApiConfigModalVisible(false);
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar la configuración de API');
    }
  };

  const handleAirtableBaseClick = (base: any) => {
    setSelectedAirtableBase(base);
    setIsAirtableTablesModalVisible(true);
  };

  const handleUpdateAirtableTable = async (table: AirtableTable) => {
    try {
      if (!selectedAirtableBase) return;

      const result = await dispatch(UpdateAirtableTableContentReducer(
        selectedAirtableBase.id,
        table.id
      ));

      if (result.respuesta) {
        message.success('Contenido de tabla actualizado exitosamente');

        const updatedTables = selectedAirtableBase.tables.map((t: AirtableTable) =>
          t.id === table.id ? { ...t, ...result.data } : t
        );

        setSelectedAirtableBase({
          ...selectedAirtableBase,
          tables: updatedTables
        });

        if (selectedAirtableConfigId === table.id) {
          const updatedTable = result.data;
          setSelectedAirtableContent(updatedTable.contenido || "No hay contenido disponible");
        }

        await dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
      } else {
        message.error(result.mensaje || 'Error al actualizar el contenido de la tabla');
      }
    } catch (error: any) {
      message.error(error.message || 'Error al actualizar el contenido de la tabla');
    }
  };

  const handleDeleteAirtableTable = async (table: AirtableTable) => {
    if (!selectedAirtableBase) return;

    Modal.confirm({
      title: '¿Está seguro de eliminar esta tabla?',
      okText: 'Sí',
      cancelText: 'No',
      onOk: async () => {
        try {
          await dispatch(DeleteAirtableTableReducer(
            selectedAirtableBase.id,
            table.id
          ));

          if (selectedAirtableBase) {
            const updatedTables = selectedAirtableBase.tables.filter(
              (t: AirtableTable) => t.id !== table.id
            );
            setSelectedAirtableBase({
              ...selectedAirtableBase,
              tables: updatedTables
            });
          }

          message.success('Tabla eliminada exitosamente');
          await dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
        } catch (error: any) {
          message.error(error.message || 'Error al eliminar la tabla');
        }
      }
    });
  };

  const handleShowAirtableTableContent = (table: AirtableTable) => {
    if (!selectedAirtableBase) return;

    const currentTable = selectedAirtableBase.tables.find((t: AirtableTable) => t.id === table.id);
    setSelectedAirtableContent(currentTable?.contenido || "No hay contenido disponible");
    setSelectedAirtableConfigId(table.id);
    setSelectedTableBaseId(selectedAirtableBase.id);
    setIsAirtableContentModalVisible(true);
  };

  const handleAddAirtableTable = async () => {
    try {
      if (!selectedAirtableBase || !newTableId) {
        message.error('El ID de la tabla es requerido');
        return;
      }

      const result = await dispatch(AddAirtableTableReducer(
        selectedAirtableBase.id,
        newTableId.trim()
      ));

      if (result.respuesta) {
        if (selectedAirtableBase && result.data.table) {
          setSelectedAirtableBase({
            ...selectedAirtableBase,
            tables: [...selectedAirtableBase.tables, result.data.table]
          });
        }

        message.success('Tabla agregada exitosamente');
        setNewTableId('');
        setIsAddTableModalVisible(false);
        await dispatch(GetAirtableConfigsReducer(airtablePage, pageSize));
      }
    } catch (error: any) {
      message.error(error.message || 'Error al agregar la tabla');
    }
  };

  return (
    <>
      <SpotlightTour steps={knowledgeBaseTourSteps} view="knowledgeBase" />
      <Card>
        <Title style={{ marginTop: '-5px' }} level={4}>Base de Conocimientos (Fuentes de Datos)</Title>
        <Tabs defaultActiveKey="1" >
          <TabPane tab="Texto" key="1" >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Space size="middle">
                <Title level={5} style={{ color: '#aaa', marginTop: '-5px', marginBottom: '-10px' }}>
                  Datos de Texto <InfoCircleFilled style={{ color: '#aaa' }} />
                </Title>
              </Space>
              <Input.TextArea
                value={textInput}
                onChange={handleInputClick}
                rows={15}
                style={{ fontSize: '16px', color: 'black', marginTop: '-20px' }}
                className="ant-input-textarea"
              />
              <Space size="middle" align="center">
                <Text style={{ color: '#aaa' }}>
                  Numero total de caracteres (todas las fuentes de datos): {totalCharacters}
                </Text>
              </Space>
              <Button
                type="primary"
                style={{ borderRadius: '6px', backgroundColor: '#1a81d7', borderColor: '#1a81d7', height: '40px' }}
                onClick={handleRetrainChatbot}
                className='ant-btn-primary'
              >
                Re-entrenar Chatbot
              </Button>
            </Space>
          </TabPane>
          <TabPane tab="Preguntas/Respuestas" key="2">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Card style={{ borderColor: '#d9d9d9', borderRadius: 0 }}>
                <Title level={5} style={{ color: '#aaa', marginTop: '-10px' }}>
                  Upload Q/A CSV File <InfoCircleFilled style={{ color: '#aaa' }} />
                </Title>
                <Row style={{ backgroundColor: '#fff', border: 'none', borderRadius: 4 }}>
                  <Upload
                    beforeUpload={beforeUpload}
                    onChange={handleFileChange}
                    accept=".csv"

                  >
                    <Button icon={<UploadOutlined className='ant-upload' />}>Seleccionar archivo</Button>
                  </Upload>
                </Row>
                <Checkbox
                  style={{ marginTop: '16px' }}
                  onChange={handleCheckboxChange}
                  checked={overwrite}
                  className='ant-checkbox-wrapper'
                >Sobrescribir Datos</Checkbox>
                <Space size="middle" style={{ width: '100%', marginTop: '16px', justifyContent: 'space-between' }}>
                  <Button
                    onClick={handleSubmit}
                    loading={isUploading}
                    type="primary" style={{ backgroundColor: '#1a81d7', borderColor: '#1a81d7' }}>
                    Upload
                  </Button>
                  <Button type="link" style={{ color: '#1890ff' }} className='downloand' onClick={handleDownloadExampleCSV}>
                    Download Example CSV
                  </Button>
                </Space>
              </Card>
            </Space>
          </TabPane>
          <TabPane tab="Lecturas" key="3">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Card style={{ borderColor: '#d9d9d9', borderRadius: 0 }}
              >
                <Title level={5} style={{ color: '#aaa', marginTop: '-10px' }}>
                  Agregar Lectura Web
                </Title>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={12} md={6}>
                    <Input
                      placeholder="Nombre de la lectura"
                      value={nombreLectura}
                      onChange={(e) => setNombreLectura(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Input
                      placeholder="URL (ej: https://ejemplo.com)"
                      value={urlLectura}
                      onChange={(e) => setUrlLectura(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={4}>
                    <Select
                      value={origenLectura}
                      onChange={(value) => setOrigenLectura(value)}
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="web">Página Web</Select.Option>
                      <Select.Option value="google_sheets">Google Sheets</Select.Option>
                      <Select.Option value="google_docs">Google Docs</Select.Option>
                      <Select.Option value="google_slides">Google Slides</Select.Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Button
                      type="dashed"
                      onClick={handleAddLectura}
                      style={{
                        background: '#308446',
                        color: 'white',
                        width: '100%',
                      }}
                    >
                      Añadir Lectura +
                    </Button>
                  </Col>
                </Row>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '30px',
                  marginBottom: '15px'
                }}>
                  <span>Lista de lecturas:</span>
                  <Space>
                    <Input
                      placeholder="Buscar por nombre o URL"
                      value={searchNombre || searchUrl}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSearchNombre(value); // Asigna el valor al estado de búsqueda
                        setSearchUrl(value); // También lo asigna al estado de URL
                        if (!value) {
                          handleSearch();
                        }
                      }}
                      onPressEnter={handleSearch}  // Dispara la búsqueda al presionar Enter
                      style={{ width: 200 }}
                      allowClear
                    />
                    <Button
                      type="primary"
                      onClick={handleSearch}  // Botón para iniciar la búsqueda
                      style={{
                        background: '#1a81d7',
                        borderColor: '#1a81d7'
                      }}
                    >
                      Buscar
                    </Button>
                  </Space>
                  <Pagination
                    current={rex_page}
                    total={rex_total}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    className='ant-pagination'
                    size="small"
                  />
                </div>

                <Skeleton active loading={rex_loading}>
                  <div >
                    {rex_lecturas?.map((lectura: any) => (
                      <div key={lectura.id} style={{
                        marginTop: '5px',
                        display: "flex",
                        justifyContent: "flex-start"
                      }}>
                        <CustomButton
                          onClick={() => handleLecturaClick(lectura)}
                          leftContent={
                            lectura.leyo ? (
                              <span style={{ color: 'green', fontSize: '24px' }}>●</span>
                            ) : (
                              <span style={{ color: 'red', fontSize: '24px' }}>●</span>
                            )
                          }
                          
                          rightContent={
                            
                            <Space style={{ justifyContent: 'flex-start' }}>
                              <EditOutlined
                                style={{ color: 'blue', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditLectura(lectura);
                                }}
                              />
                              <SyncOutlined
                                style={{ color: 'green', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUpdateContent(lectura);
                                }}
                              />
                              <DeleteOutlined
                                style={{ color: 'red', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteLectura(lectura.id);
                                }}
                              />
                              <CopyOutlined
                                style={{ color: 'gray', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopyUrl(lectura.url);
                                }}
                              />
                              <GlobalOutlined
                                style={{ color: 'blue', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenUrl(lectura.url)
                                }}
                              />
                              <ContainerOutlined
                                style={{ color: '#ffa500', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleShowContent(lectura);
                                }}
                              />
                            </Space>
                          }
                        >
                          <img 
      src={
        lectura.origen_lectura === 'google_sheets' ? GoogleSheet :
        lectura.origen_lectura === 'google_docs' ? GoogleDoc :
        lectura.origen_lectura === 'google_slides' ? GoogleSlider :
        Google
      } 
      alt="tipo de lectura"
      style={{ width: '24px', height: '24px' }}
    />
                          <div style={{ textAlign: 'left' }}>
                            <div>{lectura.nombre}</div>
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>{lectura.url}</div>
                            <div>{formatDate(lectura.updated_at)}</div>
                          </div>
                        </CustomButton>
                      </div>
                    ))}

                  </div>
                </Skeleton>

                <Modal
                  title="Editar Lectura"
                  open={modalEditVisible}
                  onOk={handleEditConfirm}
                  onCancel={() => setModalEditVisible(false)}
                  okText="Guardar"
                  cancelText="Cancelar"
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Input
                      placeholder="Nombre de la lectura"
                      value={editNombre}
                      onChange={(e) => setEditNombre(e.target.value)}
                    />
                    <Input
                      placeholder="URL"
                      value={editUrl}
                      onChange={(e) => setEditUrl(e.target.value)}
                    />
                  </Space>
                </Modal>
              </Card>
            </Space>
          </TabPane>
          <TabPane tab="AirTable" key="4">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Card style={{ borderColor: '#d9d9d9', borderRadius: 0 }}>
                <Title level={5} style={{ color: '#aaa', marginTop: '-10px' }}>
                  Agregar Tabla de Airtable
                </Title>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={4} md={2}>
                    <Tooltip title="Tutorial de Configuración Airtable">
                      <Button
                        icon={<BookOutlined />}
                        onClick={() => setIsTutorialModalVisible(true)}
                        style={{
                          width: '100%',
                          background: '#1890ff',
                          color: 'white',
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={4} md={2}>
                    <Tooltip title="Configuración de API key Airtable">
                      <Button
                        icon={<KeyOutlined />}
                        onClick={() => setIsApiConfigModalVisible(true)}
                        style={{
                          width: '100%',
                          background: '#1a81d7',
                          color: '#ffa500',
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Input
                      placeholder="Nombre de la Base"
                      value={nombreBase}
                      onChange={(e) => setNombreBase(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Input
                      placeholder="Base ID (ej: appxxxxxxxx)"
                      value={baseId}
                      onChange={(e) => setBaseId(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Button
                      type="dashed"
                      onClick={handleAddAirtableConfig}
                      style={{
                        background: '#308446',
                        color: 'white',
                        width: '100%',
                      }}
                    >
                      Añadir Base
                    </Button>
                  </Col>
                </Row>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '30px',
                  marginBottom: '15px'
                }}>
                  <span>Lista de Bases configuradas:</span>
                  <Space>
                    <Input
                      placeholder="Buscar por nombre o ID"
                      value={searchNombre || searchUrl}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSearchNombre(value);
                        setSearchUrl(value);
                        if (!value) {
                          dispatch(GetAirtableConfigsReducer(1, pageSize));
                        }
                      }}
                      onPressEnter={() => {
                        dispatch(GetAirtableConfigsReducer(1, pageSize, {
                          base_nombre: searchNombre,
                          base_id: searchUrl
                        }));
                      }}
                      style={{ width: 200 }}
                      allowClear
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        dispatch(GetAirtableConfigsReducer(1, pageSize, {
                          base_nombre: searchNombre,
                          base_id: searchUrl
                        }));
                      }}
                      style={{
                        background: '#1a81d7',
                        borderColor: '#1a81d7'
                      }}
                    >
                      Buscar
                    </Button>
                  </Space>
                  <Pagination
                    current={airtable_page}
                    total={airtable_total}
                    pageSize={pageSize}
                    onChange={handleAirtablePageChange}
                    className='ant-pagination'
                    size="small"
                  />
                </div>

                <Skeleton active loading={airtable_loading}>
                  <div>
                    {airtable_bases?.map((config: any) => (
                      <div key={config.id} style={{
                        marginTop: '5px',
                        display: "flex",
                        justifyContent: "flex-start"
                      }}>
                        <CustomButton
                          onClick={() => handleAirtableBaseClick(config)}
                          rightContent={
                            <Space style={{ justifyContent: 'flex-start' }}>
                              <EditOutlined
                                style={{ color: 'blue', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditAirtableModal(config);
                                }}
                              />
                              <SyncOutlined
                                style={{ color: 'green', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUpdateAirtableContent(config);
                                }}
                              />
                              <DeleteOutlined
                                style={{ color: 'red', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteAirtableConfig(config.id);
                                }}
                              />
                              <CopyOutlined
                                style={{ color: 'gray', fontSize: '16px', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopyUrl(config.base_id);
                                }}
                              />
                            </Space>
                          }
                        >
                          <div style={{ textAlign: 'left' }}>
                            <div>{config.base_nombre}</div>
                            <div style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>{config.base_id}</div>
                            <div>{formatDate(config.updated_at)}</div>
                          </div>
                        </CustomButton>
                      </div>
                    ))}
                  </div>
                </Skeleton>
              </Card>
            </Space>
          </TabPane>

        </Tabs>
        <ModalText
          visible={visibleModal}
          onClose={handleModalCancel}
          textInput={modalText}
          onChange={handleModalChange}
        />
        <ModalSubUrls
          visible={modalSubUrlsVisible}
          onClose={() => {
            setModalSubUrlsVisible(false);
            setSelectedLecturaId(null);
            setSelectedLectura(null);
          }}
          title={selectedLectura?.origen_lectura === 'google_sheets'
            ? `Hojas de ${selectedLectura?.nombre || 'la lectura'}`
            : `Sub URLs de ${selectedLectura?.nombre || 'la lectura'}`}
          subUrls={selectedLecturaSubUrls}
          onUpdateSubUrl={handleUpdateSubUrl}
          onDeleteSubUrl={handleDeleteSubUrl}
          onShowContent={handleShowSubUrlContent}
          onAddSubUrl={() => setIsAddSubUrlModalVisible(true)}
          origenLectura={selectedLectura?.origen_lectura} // Agregamos esta prop
        />

        <ModalEditContent
          visible={isContentModalVisible}
          onClose={() => {
            setIsContentModalVisible(false);
            setSelectedLecturaId(null);
            setSelectedContent("");
            setSelectedLectura(null); // Agregamos esto
          }}
          content={selectedContent}
          onSave={handleSaveContent}
          origenLectura={selectedLectura?.origen_lectura} // Agregamos esta prop
        />
        <ModalEditContent
          visible={isSubUrlContentModalVisible}
          onClose={() => {
            setIsSubUrlContentModalVisible(false);
            setSelectedSubUrlId(null);
            setSelectedSubUrlContent("");
          }}
          content={selectedSubUrlContent}
          onSave={handleSaveSubUrlContent}
          origenLectura={selectedLectura?.origen_lectura}
        />

        <ModalEditContent
          visible={isAirtableContentModalVisible}
          onClose={() => {
            setIsAirtableContentModalVisible(false);
            setSelectedAirtableConfigId(null);
            setSelectedTableBaseId(null);
            setSelectedAirtableContent("");
          }}
          content={selectedAirtableContent}
          onSave={handleSaveAirtableContent}
          isAirtable={true}
        />

        <Modal
          title="Editar Configuración de Airtable"
          open={editAirtableModalVisible}
          onOk={handleSaveAirtableEdit}
          onCancel={() => setEditAirtableModalVisible(false)}
          okText="Guardar"
          cancelText="Cancelar"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <label style={{ display: 'block', marginBottom: '8px' }}>Nombre de la Base</label>
              <Input
                placeholder="Nombre de la Base"
                value={editAirtableConfig.base_nombre}
                onChange={(e) => setEditAirtableConfig({
                  ...editAirtableConfig,
                  base_nombre: e.target.value
                })}
              />
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '8px' }}>Base ID</label>
              <Input
                placeholder="Base ID (ej: appxxxxxxxx)"
                value={editAirtableConfig.base_id}
                onChange={(e) => setEditAirtableConfig({
                  ...editAirtableConfig,
                  base_id: e.target.value
                })}
              />
            </div>
          </Space>
        </Modal>
        <Modal
          title="Agregar Sub URL"
          open={isAddSubUrlModalVisible}
          onOk={handleAddNewSubUrl}
          onCancel={() => {
            setIsAddSubUrlModalVisible(false);
            setNewSubUrl('');
          }}
          okText="Guardar"
          cancelText="Cancelar"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input
              placeholder="URL de la sub página"
              value={newSubUrl}
              onChange={(e) => setNewSubUrl(e.target.value)}
            />
          </Space>
        </Modal>
        <Modal
          title="Configuración de API key Airtable"
          open={isApiConfigModalVisible}
          onOk={handleSaveApiConfig}
          onCancel={() => setIsApiConfigModalVisible(false)}
          okText="Guardar"
          cancelText="Cancelar"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <label style={{ display: 'block', marginBottom: '8px' }}>API Key de Airtable</label>
              <Input
                placeholder="API Key de Airtable"
                value={apiConfig.api_key_airtables}
                onChange={(e) => setApiConfig({
                  ...apiConfig,
                  api_key_airtables: e.target.value
                })}
              />
            </div>
          </Space>
        </Modal>
        <ModalTutorialAirtable
          visible={isTutorialModalVisible}
          onClose={() => setIsTutorialModalVisible(false)}
        />
        <ModalAirtableTables
          visible={isAirtableTablesModalVisible}
          onClose={() => {
            setIsAirtableTablesModalVisible(false);
            setSelectedAirtableBase(null);
          }}
          tables={selectedAirtableBase?.tables || []}
          baseName={selectedAirtableBase?.base_nombre}
          onUpdateTable={handleUpdateAirtableTable}
          onDeleteTable={handleDeleteAirtableTable}
          onShowContent={handleShowAirtableTableContent}
          onAddTable={() => setIsAddTableModalVisible(true)}
        />

        <Modal
          title="Agregar Nueva Tabla"
          open={isAddTableModalVisible}
          onOk={handleAddAirtableTable}
          onCancel={() => {
            setIsAddTableModalVisible(false);
            setNewTableId('');
          }}
          okText="Agregar"
          cancelText="Cancelar"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <label style={{ display: 'block', marginBottom: '8px' }}>ID de la Tabla</label>
              <Input
                placeholder="Table ID (ej: tblxxxxxxxx)"
                value={newTableId}
                onChange={(e) => setNewTableId(e.target.value)}
              />
            </div>
          </Space>
        </Modal>
      </Card>
    </>
  );
};

export default TabKnowledgeBase;
