import React from 'react';
import { Modal } from 'antd';
import CustomCard from './CustomCard';

interface ModalCardProps {
    visible: boolean;
    onClose: () => void;
    cardData: {
      icons: string[];
      title: string;
      usage: string;
      url: string;
      dificultad: string;
      situaciones?: string; // Agregamos situaciones
      integraciones?: string; // Agregamos integraciones
      instrucciones?: string; // Agregamos instrucciones
    };
    showMoreDetails?: boolean; // Prop para mostrar más detalles
  }

const ModalCard: React.FC<ModalCardProps> = ({ visible, onClose, cardData, showMoreDetails = true }) => {
  return (
    <Modal
      title=""
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
    <div
      style={{
        marginTop:"40px"
      }}
      >
      <CustomCard
        icons={cardData.icons}
        title={cardData.title}
        usage={cardData.usage}
        url={cardData.url}
        dificultad={cardData.dificultad}
        situaciones={cardData.situaciones}
        integraciones={cardData.integraciones}
        instrucciones={cardData.instrucciones}
        showMoreDetails={showMoreDetails} // Pasamos este prop al CustomCard dentro del modal
      />
    </div>
    </Modal>
  );
};

export default ModalCard;
