import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import config from '../../../config';
import {
  PLANTILLAS_MAKE_REQUEST,
  PLANTILLAS_MAKE_SUCCESS,
  PLANTILLAS_MAKE_FAILURE, 
} from '../../../constantes/plantillas_make/PlantillasMake';
import fetchWithIP from '../utils/fetchHeaders';
import { AppDispatch } from '../../store/store';

export interface PlantillasMakeData {
  id: number
  descripcion_en : string;
  url?: string;
  dificultad?: string;
  situaciones?: string;
  integraciones?: string;
  instrucciones_uso?: string;
  usos?: string;
  created_at?: string;
  updated_at?: string;
}

export const fetchPlantillasMake = (
  filters: any,
  page: number,
  sortColumn?: string,
  order?: string,
) => async (dispatch: Dispatch) => {

  dispatch({ type: PLANTILLAS_MAKE_REQUEST });
  try {
    const response = await fetchWithIP(`plantillas-make?page=${page}&sortColumn=${sortColumn}&sortOrder=${order}`, {
      method: 'POST',
    }, filters);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    console.log('Datos recibidos:', data);  // Verifica los datos recibidos

    if (data) {
      // Extraer las dificultades e integraciones desde `mensaje_dev`
      const { dificultades = [], integraciones = [] } = data.mensaje_dev || {};

      console.log('Dificultades recibidas:', dificultades); // Verifica las dificultades
      console.log('Integraciones recibidas:', integraciones); // Verifica las integraciones

      dispatch({
        type: PLANTILLAS_MAKE_SUCCESS,
        payload: {
          data: data.data,
          meta: data.meta,
          dificultades: dificultades, // Pasamos las dificultades al reducer
          integraciones: integraciones, // Pasamos las integraciones al reducer
        }
      });

    } else {
      dispatch({
        type: PLANTILLAS_MAKE_FAILURE,
        error: 'Error en la respuesta del servidor',
      });
    }
  } catch (error) {
    dispatch({
      type: PLANTILLAS_MAKE_FAILURE,
      error: 'Error de red o en la solicitud',
    });
  }
}

