import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomCard from '../../components/pages/PlantillasMake/CustomCard';
import { fetchPlantillasMake } from '../../redux/actions/plantillas_make/PlantillasMake';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/store';
import ModalCard from '../../components/pages/PlantillasMake/ModalCard';
import SelectBox from '../../components/pages/PlantillasMake/SelectBox';
import CustomTag from '../../components/pages/PlantillasMake/CustomTag';

interface CardData {
  id: string;
  icons: string[];
  title: string;
  usage: string;
  url: string;
  dificultad: string;
  situaciones: string;
  integraciones: string;
  instrucciones: string;
}

const CardsContainer: React.FC = () => {
  const { Header, Content } = Layout;
  const dispatch: AppDispatch = useDispatch();
  const { rex_plantillas_make, rex_dificultades, rex_integraciones, rex_loading, rex_meta } = useSelector(
    ({ plantillasMake }: RootState) => plantillasMake
  );

  const [cardsData, setCardsData] = useState<CardData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadedPages, setLoadedPages] = useState<Set<number>>(new Set());
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedCardData, setSelectedCardData] = useState<CardData | null>(null);
  const [selectedDifficulties, setSelectedDifficulties] = useState<{ id: string; name: string }[]>([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState<{ id: string; name: string }[]>([]);

  const [difficulties, setDifficulties] = useState<string[]>([]);
  const [integrations, setIntegrations] = useState<string[]>([]);

  const loadPage = (page: number) => {
    if (!loadedPages.has(page)) {
      dispatch(fetchPlantillasMake({}, page, 'id', 'asc'));
      setLoadedPages((prev) => new Set(prev).add(page));
    }
  };

  useEffect(() => {
    loadPage(1);
  }, []);

  useEffect(() => {
    if (rex_plantillas_make) {
      const mappedData: CardData[] = rex_plantillas_make.map((item: any) => ({
        id: item.id,
        icons: [],
        title: item.descripcion_en,
        usage: item.usos,
        url: item.url,
        dificultad: item.dificultad,
        situaciones: item.situaciones,
        integraciones: item.integraciones,
        instrucciones: item.instrucciones_uso
      }));
      setCardsData((prevData) => {
        const newData = mappedData.filter(
          (item: CardData) =>
            !prevData.some((prevItem: CardData) => prevItem.title === item.title)
        );
        return [...prevData, ...newData];
      });
    }
  }, [rex_plantillas_make]);

  useEffect(() => {
    if (rex_dificultades.length > 0) {
      setDifficulties(rex_dificultades);
    }
    if (rex_integraciones.length > 0) {
      setIntegrations(rex_integraciones);
    }
  }, [rex_dificultades, rex_integraciones]);

  const fetchMoreData = () => {
    if (rex_meta && currentPage < Math.ceil(rex_meta.total / rex_meta.limit)) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      loadPage(nextPage);
    }
  };

  const handleCardClick = (card: CardData) => {
    setSelectedCardData(card);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedCardData(null);
  };

  const handleDifficultySelect = (value: string) => {
    if (value === "Todos los datos") {
      setSelectedDifficulties([]);
    } else {
      if (!selectedDifficulties.some(diff => diff.name === value)) {
        setSelectedDifficulties(prev => [...prev, { id: value, name: value }]);
      }
    }
  };

  const handleDifficultyRemove = (difficultyId: string) => {
    setSelectedDifficulties(prev => prev.filter(diff => diff.id !== difficultyId));
  };

  const handleIntegrationSelect = (value: string) => {
    if (value === "Todos los datos") {
      setSelectedIntegrations([]);
    } else {
      if (!selectedIntegrations.some(integ => integ.name === value)) {
        setSelectedIntegrations(prev => [...prev, { id: value, name: value }]);
      }
    }
  };

  const handleIntegrationRemove = (integrationId: string) => {
    setSelectedIntegrations(prev => prev.filter(integ => integ.id !== integrationId));
  };

  const filteredCards = cardsData.filter(card =>
    (selectedDifficulties.length === 0 || selectedDifficulties.some(diff => diff.name === card.dificultad)) &&
    (selectedIntegrations.length === 0 || selectedIntegrations.some(integ => integ.name === card.integraciones))
  );

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f5f5f5', overflowX: 'hidden' }}>
      <Header
        style={{
          backgroundColor: '#fff',
          textAlign: 'center',
          padding: '16px',
          fontSize: '20px',
        }}
      >
        Templates
      </Header>
      <Content style={{ padding: '24px', overflowX: 'hidden' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', marginBottom: '20px' }}>
          <SelectBox
            placeholder={selectedDifficulties.length === 0 ? "Filtrar por dificultad" : "Seleccionado"}
            options={[{ value: "Todos los datos", label: "Todos los datos" }, ...difficulties.map(diff => ({
              value: diff,
              label: diff
            }))]}
            onChange={handleDifficultySelect}
            className="Input-Filter-Employment w-full mt-[5px] rounded-[12px] custom-input-company px-1"
          />
          <div className="flex flex-wrap mt-4">
            {selectedDifficulties.map((difficulty) => (
              <CustomTag
                key={difficulty.id}
                text={difficulty.name}
                onClose={() => handleDifficultyRemove(difficulty.id)}
              />
            ))}
          </div>

          <SelectBox
            placeholder={selectedIntegrations.length === 0 ? "Filtrar por integraciones" : "Seleccionado"}
            options={[{ value: "Todos los datos", label: "Todos los datos" }, ...integrations.map(integ => ({
              value: integ,
              label: integ
            }))]}
            onChange={handleIntegrationSelect}
            className="Input-Filter-Employment w-full mt-[5px] rounded-[12px] custom-input-company px-1"
          />
          <div className="flex flex-wrap mt-4">
            {selectedIntegrations.map((integration) => (
              <CustomTag
                key={integration.id}
                text={integration.name}
                onClose={() => handleIntegrationRemove(integration.id)}
              />
            ))}
          </div>
        </div>

        <div style={{ margin: '0 auto' }}>
          {rex_loading && cardsData.length === 0 ? (
            <Row gutter={[24, 24]} justify="center">
              {Array.from({ length: 8 }).map((_, index) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  <Skeleton active style={{ width: '100%' }} />
                </Col>
              ))}
            </Row>
          ) : (
            <InfiniteScroll
              dataLength={cardsData.length}
              next={fetchMoreData}
              hasMore={currentPage < Math.ceil(rex_meta.total / rex_meta.limit)}
              loader={
                <Row gutter={[24, 24]} justify="center">
                  {Array.from({ length: 8 }).map((_, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                      <Skeleton active style={{ width: '100%' }} />
                    </Col>
                  ))}
                </Row>
              }
            >
              <Row gutter={[24, 24]} justify="center">
                {filteredCards.map((card, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <CustomCard
                      icons={card.icons}
                      title={card.title}
                      usage={card.usage}
                      url={card.url}
                      dificultad={card.dificultad}
                      situaciones={card.situaciones}
                      integraciones={card.integraciones}
                      instrucciones={card.instrucciones}
                      onClick={() => handleCardClick(card)}
                    />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}
        </div>
      </Content>
      <ModalCard
        visible={modalVisible}
        onClose={handleModalClose}
        cardData={selectedCardData || { icons: [], title: '', usage: '', url: '', dificultad: '' }}
      />
    </Layout>
  );
};

export default CardsContainer;
