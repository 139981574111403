import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ChatComponent from '../../../../components/chat/ChatComponent';
import NoAccess from '../../../../components/pages/chat/NoAccess';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { GetConversationReducer, ResetConversationReducer } from '../../../../redux/actions/chatBots/Chat/Chat';
import { GetDataChatsBotsHomeReducer } from '../../../../redux/actions/home/Home';
import { GetOneDesingChatReducer } from '../../../../redux/actions/chatBots/Chat/ChatDesing';
import SpotlightTour from '../../../../components/pages/ShadowComponent/ShadowComponent';
import tourData from '../../../../components/pages/ShadowComponent/tourSteps.json';
import { useTour } from '../../../../components/pages/ShadowComponent/TourContext';

interface FormValues {
  fontSize: string;
  fontFamily: string;
  nombreChat: string;
  inputPlaceholder: string;
  logo: File | null;
  icono: File | null;
  iconoRuta: string;
  logoRuta: string;
  colorHeader: string;
  colorTitulo: string;
  colorFondoEmisor: string;
  colorFondoReceptor: string;
  retrasoRespuesta: number;
  colorEmisor: string;
  colorReceptor: string;
  estado: boolean;
  colorEstadoActivo: string;
  colorEstadoInactivo: string;
  estadoHorario: boolean;
}

const getIdentifier = (): string | null => {
  const localIdentifier = localStorage.getItem('identificador');
  return localIdentifier || null;
};

const TabChat: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();
  const { setCurrentView } = useTour();
  const chatBot = tourData.Chat;

  const {
    rex_conversation_chat
  } = useSelector(({ tabChat }: any) => tabChat);
  const { rex_chat_selecccionado, rex_conversacion_seleccionada } = useSelector(({ home }: RootState) => home);
  const [chatData, setChatData] = useState<any>([]);

  const identifier = rex_conversacion_seleccionada?.toString() || getIdentifier();

  useEffect(() => {
    if ((identifier && identifier != "0") && rex_conversacion_seleccionada != "") {
      localStorage.setItem('identificador', identifier); // Guardamos el identificador si no está en el localStorage
      GetConversation();
    }
  }, [rex_conversacion_seleccionada]);

  const GetConversation = async () => {
    await dispatch(GetConversationReducer(identifier)); // Asegúrate de que es un string válido.
  };

  
  useEffect(() => {
    dispatch(GetDataChatsBotsHomeReducer());
  }, []);
  
  useEffect(() => {
    if (rex_conversation_chat?.length > 0) {
      setChatData(rex_conversation_chat); // Actualiza el estado local.
    }
  }, [rex_conversation_chat]);
  const [initialValues, setInitialValues] = useState<FormValues>({
    fontSize: '',
    fontFamily: '',
    nombreChat: '',
    inputPlaceholder: '',
    logo: null,
    icono: null,
    iconoRuta: '',
    logoRuta: '',
    colorHeader: '#1677ff',
    colorTitulo: '#1677ff',
    colorFondoEmisor: '#0084ff',
    colorFondoReceptor: '#e5e5ea',
    colorEmisor: '#1677ff',
    colorReceptor: '#1677ff',
    estado: false,
    colorEstadoActivo: '#0BF732',
    colorEstadoInactivo: '#ea3765',
    estadoHorario: true,
    retrasoRespuesta: 50
  });

  const { rex_design_chat, rex_styles } = useSelector((state: RootState) => state.design);

  useEffect(() => {
    dispatch(GetOneDesingChatReducer());
  }, [dispatch, rex_styles]);
  
  useEffect(() => {
    
    if (rex_design_chat) {
      
      setInitialValues({
        fontSize: rex_design_chat.tamanoLetra || '',
        fontFamily: rex_design_chat.fuente || '',
        nombreChat: rex_design_chat.nombre || '',
        inputPlaceholder: rex_design_chat.placeholder || '',
        logo: null,
        icono: null,
        iconoRuta: rex_design_chat.iconoEnvio || '',
        logoRuta: rex_design_chat.logo || '',
        colorHeader: rex_design_chat.colorCabecera || '#1677ff',
        colorTitulo: rex_design_chat.colorTitulo || '#1677ff',
        colorFondoEmisor: rex_design_chat.colorFondoTextoEmisor || '#0084ff',
        colorFondoReceptor: rex_design_chat.colorFondoTextoReceptor || '#e5e5ea',
        colorEmisor: rex_design_chat.colorTextoEmisor || '#1677ff',
        colorReceptor: rex_design_chat.colorTextoReceptor || '#1677ff',
        estado: rex_design_chat.estado || false,
        colorEstadoActivo: rex_design_chat.colorEstadoActivo || '#0BF732',
        colorEstadoInactivo: rex_design_chat.colorEstadoInactivo || '#ea3765',
        estadoHorario: rex_design_chat.estadoHorario || '#0BF732',
        retrasoRespuesta: rex_design_chat.retrasoRespuesta || 50,
      });
    }
  }, [rex_design_chat, rex_styles]);

  useEffect(() => {
    setCurrentView('chatbot');
    return () => setCurrentView('');
  }, []);

  return (
    <>
      <SpotlightTour steps={chatBot} view="chatbot" />
      {
        rex_chat_selecccionado ? (
          <Card>

            <Row>
              <Col md={12} xl={12}>
                <div className="chat-component">
                  <ChatComponent
                    isTrainingView={false}
                    editBubble={false}
                    idConversation={identifier || '0'}
                    data={chatData}
                    fontSize={initialValues.fontSize}
                    fontFamily={initialValues.fontFamily}
                    nombreChat={initialValues.nombreChat}
                    inputPlaceholder={initialValues.inputPlaceholder}
                    iconoEnviarChat={initialValues.iconoRuta}
                    logoChat={initialValues.logoRuta}
                    estadoChat={initialValues.estado}
                    retrasoRespuesta={initialValues.retrasoRespuesta}
                    coloresStyle={
                      {
                        colorCabecera: initialValues.colorHeader,
                        colorFondoTextoEmisor: initialValues.colorFondoEmisor,
                        colorFondoTextoReceptor: initialValues.colorFondoReceptor,
                        colorTextoEmisor: initialValues.colorEmisor,
                        colorTextoReceptor: initialValues.colorReceptor,
                        colorTitulo: initialValues.colorTitulo,
                        colorEstadoActivo: initialValues.colorEstadoActivo,
                        colorEstadoInactivo: initialValues.colorEstadoInactivo,
                      }
                    }
                  />
                </div>
              </Col>

              <Col md={12} xl={12} className="settings-collapse">
                <Row
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <Col xl={12} md={12} style={{ paddingRight: '10px' }}>

                  </Col>
                  <Col xl={12} md={12} style={{ paddingLeft: '10px' }}>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        ) : (
          <NoAccess />
        )
      }
    </>
  );
};

export default TabChat;
